import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import {
  changeDriverStatusStart,
  getUserByIdStart,
  resetDriverApprovalWaitingStart,
  verifyDvlaCodeStart,
} from "../../redux/admin/selected-user/selectedUser.slice";
import Card from "../UI/card/card.component";
import Spinner from "../UI/spinner/spinner.component";
import ErrorMessage from "../UI/error-message/error-message.component";
import InnerCard from "../UI/inner-card/inner-card.component";
import { getUserVehiclesForAdminStart } from "../../redux/admin/vehicle-list/vehicleList.slice";
import {
  AmberP,
  ButtonColumnContainer,
  ButtonContainer,
  GreenHeader,
  GreenP,
  RedHeader,
  RedP,
  SpreadApartArea,
  TextDivider,
} from "../../global.styles";
import { colors } from "../../style-variables";
import { transformTimestamp } from "../../util-functions";
import ApproveDriverPaperwork from "./forms/approve-driver-paperwork.component";
import Button from "../UI/button/button.component";
import { SpacerHorizontal } from "../UI/spacers/spacers.component";
import SetLicenceNumbersForm from "./forms/set-licence-numbers-form.component";
import Modal from "../UI/modal/modal.component";
import { FaCheck } from "react-icons/fa";

const required = <RedP>Not Provided</RedP>;
const rejected = <RedP>Rejected</RedP>;
const pending = <AmberP>Waiting for approval...</AmberP>;
const approved = <GreenP>Approved</GreenP>;

const UserDetails = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedUserLoading, selectedUserError, selectedUser } = useSelector(
    (state: RootState) => state.selectedUser
  );
  const { vehicleListLoading, vehicleListError, vehicleList } = useSelector(
    (state: RootState) => state.vehicleList
  );
  //modal to confirm vehicle activation or suspension (approve or ban)
  const [openModal, setOpenModal] = useState(false);
  const [approve, setApprove] = useState<boolean | null>(null);
  //modal to reset waiting for approval status
  const [openResetWaitModal, setOpenResetWaitModal] = useState(false);

  //paperwork
  const [showLicenceNumersForm, setShowLicenceNumersForm] = useState(false);
  const [showBadgeForm, setShowBadgeForm] = useState(false);
  const [showPHLicenceForm, setShowPHLicenceForm] = useState(false);
  const [showDvlaLicenceForm, setShowDvlaLicenceForm] = useState(false);
  const [showDvlaCodeForm, setShowDvlaCodeForm] = useState(false);

  useEffect(() => {
    if (userId) {
      dispatch(getUserByIdStart(userId));
      dispatch(getUserVehiclesForAdminStart(userId));
    }
  }, [dispatch, userId]);

  const navigateToVehicleDetailsHandler = (numberPlate: string) => {
    navigate(`/boss/vehicle-details/${userId}/${numberPlate}`);
  };

  //DVLA Check Code handlers
  const dvlaCodeHandler = (isVerified: boolean) => {
    if (userId) {
      dispatch(verifyDvlaCodeStart({ userId, isVerified }));
    }
    setShowDvlaCodeForm(false);
  };

  //reset "waitng for approval as a Driver" boolean
  const resetWaitingHandler = () => {
    setOpenResetWaitModal(true);
  };
  const resetWaitingConfirm = () => {
    if (userId) {
      dispatch(resetDriverApprovalWaitingStart(userId));
    }
  };
  //activate of suspend Driver
  const activationHandler = (activate: boolean) => {
    setApprove(activate);
    setOpenModal(true);
  };
  const activationConfirm = () => {
    if (userId && approve !== null) {
      dispatch(changeDriverStatusStart({ userId, approvedDriver: approve }));
    }
  };

  return (
    <>
      {selectedUserLoading && <Spinner />}
      {selectedUserError && (
        <ErrorMessage slice="selectedUser">{selectedUserError}</ErrorMessage>
      )}
      {selectedUser && (
        <Card>
          {openModal && approve !== null && (
            <Modal
              title={approve ? "Activate" : "Suspend"}
              body={`${
                approve ? "Activate" : "Suspend"
              } this Driver? Are you sure?`}
              confirmButtonText={approve ? "Activate" : "Suspend"}
              setOpenModal={setOpenModal}
              onModalConfirm={() => activationConfirm()}
            />
          )}
          {openResetWaitModal && (
            <Modal
              title="Reset Waiting Status"
              body="Have all paperwork, data and vehicles for this Driver been reviewed? Are you sure?"
              confirmButtonText="Reset"
              setOpenModal={setOpenResetWaitModal}
              onModalConfirm={resetWaitingConfirm}
            />
          )}
          {selectedUser.isBoss && (
            <ButtonColumnContainer>
              <GreenP>Admin</GreenP>
            </ButtonColumnContainer>
          )}

          <GreenHeader>{selectedUser.name}</GreenHeader>

          <TextDivider>
            <GreenP>CONTACTS</GreenP>
          </TextDivider>
          <SpreadApartArea>
            <GreenP>Email:</GreenP>
            <p>{selectedUser.email}</p>
          </SpreadApartArea>
          <SpreadApartArea>
            <GreenP>Phone:</GreenP>
            {selectedUser.phoneNumber ? (
              <p>{selectedUser.phoneNumber}</p>
            ) : (
              <RedP>No phone number</RedP>
            )}
          </SpreadApartArea>

          <TextDivider>
            <GreenP>TIME STAMPS</GreenP>
          </TextDivider>
          <SpreadApartArea>
            <GreenP>Created:</GreenP>{" "}
            <p>{transformTimestamp(selectedUser.createdAt)}</p>
          </SpreadApartArea>
          <SpreadApartArea>
            <GreenP>Last update:</GreenP>
            <p>{transformTimestamp(selectedUser.lastUpdate)}</p>
          </SpreadApartArea>

          {selectedUser && selectedUser.driversData && (
            <>
              <TextDivider>
                <GreenP>DRIVERS DATA</GreenP>
              </TextDivider>

              <SpreadApartArea>
                <GreenP>Licence:</GreenP>
                <p>
                  <strong>{selectedUser.driversData.licensingAuthority}</strong>
                </p>
              </SpreadApartArea>

              <SpreadApartArea>
                <GreenP>Driver Number:</GreenP>
                {selectedUser.driverNumber ? (
                  <p>{selectedUser.driverNumber}</p>
                ) : (
                  <RedP>Not assigned</RedP>
                )}
              </SpreadApartArea>

              <InnerCard
                onClick={() => {
                  setShowLicenceNumersForm(!showLicenceNumersForm);
                }}
              >
                <h3>Licence Numbers</h3>
                {selectedUser.driversData.dvlaLicenceNumber &&
                selectedUser.driversData.badgeNumber ? (
                  <div>
                    <GreenP>
                      {selectedUser.driversData.dvlaLicenceNumber}
                    </GreenP>
                    <GreenP>{selectedUser.driversData.badgeNumber}</GreenP>
                  </div>
                ) : (
                  required
                )}
              </InnerCard>
              {showLicenceNumersForm && userId && (
                <SetLicenceNumbersForm
                  userId={userId}
                  onCancelSet={() => setShowLicenceNumersForm(false)}
                />
              )}

              <InnerCard onClick={() => setShowBadgeForm(!showBadgeForm)}>
                <h3>Private Hire Badge</h3>
                {selectedUser.driversData.PHBadge &&
                selectedUser.driversData.PHBadge.pendingUrl ? (
                  pending
                ) : selectedUser.driversData.PHBadge &&
                  selectedUser.driversData.PHBadge.approved ? (
                  approved
                ) : selectedUser.driversData.PHBadge &&
                  selectedUser.driversData.PHBadge.reasonOfDecline ? (
                  <RedP>
                    {selectedUser.driversData.PHBadge.reasonOfDecline}
                  </RedP>
                ) : (
                  required
                )}
              </InnerCard>
              {showBadgeForm &&
                (selectedUser.driversData.PHBadge?.pendingUrl ||
                  selectedUser.driversData.PHBadge?.downloadUrl ||
                  selectedUser.driversData.PHBadge?.expiringUrl) && (
                  <ApproveDriverPaperwork
                    docTitle="PHBadge"
                    onDocumentReviewed={() => setShowBadgeForm(false)}
                  />
                )}

              <InnerCard
                onClick={() => setShowPHLicenceForm(!showPHLicenceForm)}
              >
                <h3>PH Driver paper licence</h3>
                {selectedUser.driversData.PHDriversLicence &&
                selectedUser.driversData.PHDriversLicence.pendingUrl ? (
                  pending
                ) : selectedUser.driversData.PHDriversLicence &&
                  selectedUser.driversData.PHDriversLicence.approved ? (
                  approved
                ) : selectedUser.driversData.PHDriversLicence &&
                  selectedUser.driversData.PHDriversLicence.reasonOfDecline ? (
                  <RedP>
                    {selectedUser.driversData.PHDriversLicence.reasonOfDecline}
                  </RedP>
                ) : (
                  required
                )}
              </InnerCard>
              {showPHLicenceForm &&
                (selectedUser.driversData.PHDriversLicence?.pendingUrl ||
                  selectedUser.driversData.PHDriversLicence?.downloadUrl ||
                  selectedUser.driversData.PHDriversLicence?.expiringUrl) && (
                  <ApproveDriverPaperwork
                    docTitle="PHDriversLicence"
                    onDocumentReviewed={() => setShowPHLicenceForm(false)}
                  />
                )}

              <InnerCard
                onClick={() => setShowDvlaLicenceForm(!showDvlaLicenceForm)}
              >
                <h3>DVLA licence</h3>
                {selectedUser.driversData.DVLALicence &&
                selectedUser.driversData.DVLALicence.pendingUrl ? (
                  pending
                ) : selectedUser.driversData.DVLALicence &&
                  selectedUser.driversData.DVLALicence.approved ? (
                  approved
                ) : selectedUser.driversData.DVLALicence &&
                  selectedUser.driversData.DVLALicence.reasonOfDecline ? (
                  <RedP>
                    {selectedUser.driversData.DVLALicence.reasonOfDecline}
                  </RedP>
                ) : (
                  required
                )}
              </InnerCard>
              {showDvlaLicenceForm &&
                (selectedUser.driversData.DVLALicence?.pendingUrl ||
                  selectedUser.driversData.DVLALicence?.downloadUrl ||
                  selectedUser.driversData.DVLALicence?.expiringUrl) && (
                  <ApproveDriverPaperwork
                    docTitle="DVLALicence"
                    onDocumentReviewed={() => setShowDvlaLicenceForm(false)}
                  />
                )}

              <InnerCard onClick={() => setShowDvlaCodeForm(!showDvlaCodeForm)}>
                <h3>DVLA Check Code</h3>
                {selectedUser.driversData.DVLACheckCode &&
                selectedUser.driversData.DVLACheckCode.approved
                  ? approved
                  : selectedUser.driversData.DVLACheckCode &&
                    selectedUser.driversData.DVLACheckCode.pending
                  ? pending
                  : selectedUser.driversData.DVLACheckCode &&
                    !selectedUser.driversData.DVLACheckCode.pending
                  ? rejected
                  : required}
              </InnerCard>
              {showDvlaCodeForm &&
                (selectedUser.driversData.DVLACheckCode?.pending ||
                  selectedUser.driversData.DVLACheckCode?.code) && (
                  <ButtonColumnContainer>
                    <GreenHeader>
                      {selectedUser.driversData.DVLACheckCode?.code}
                    </GreenHeader>
                    <ButtonContainer>
                      <Button
                        buttonType="inverted"
                        onClick={dvlaCodeHandler.bind(null, false)}
                      >
                        Reject
                      </Button>
                      <SpacerHorizontal $paddingRem={2} />
                      <Button onClick={dvlaCodeHandler.bind(null, true)}>
                        Approve
                      </Button>
                    </ButtonContainer>
                  </ButtonColumnContainer>
                )}

              <TextDivider>
                <GreenP>VEHICLES</GreenP>
              </TextDivider>
              {vehicleListLoading && <Spinner />}
              {vehicleListError && (
                <ErrorMessage slice="selectedUser">
                  {vehicleListError}
                </ErrorMessage>
              )}
              {vehicleList &&
                vehicleList.length > 0 &&
                vehicleList.map((vehicle) => (
                  <InnerCard
                    key={vehicle.numberPlate}
                    onClick={navigateToVehicleDetailsHandler.bind(
                      null,
                      vehicle.numberPlate
                    )}
                  >
                    <h3>
                      {vehicle.numberPlate}{" "}
                      {vehicle.selected && (
                        <FaCheck size={16} color={colors.light} />
                      )}
                    </h3>
                    {vehicle.waitingApproval
                      ? pending
                      : vehicle.approved
                      ? approved
                      : required}
                  </InnerCard>
                ))}
              {selectedUser?.waitingDriverApproval && (
                <>
                  <TextDivider>
                    <GreenP>WAITING FOR APPROVAL</GreenP>
                  </TextDivider>
                  <ButtonColumnContainer>
                    <Button onClick={resetWaitingHandler}>
                      Remove From Waiting...
                    </Button>
                  </ButtonColumnContainer>
                </>
              )}
              <TextDivider>
                <GreenP>STATUS</GreenP>
              </TextDivider>
              <ButtonColumnContainer>
                {selectedUser.approvedDriver ? (
                  <GreenHeader>Driver is Active</GreenHeader>
                ) : (
                  <RedHeader>Driver is NOT Active</RedHeader>
                )}
              </ButtonColumnContainer>
              <ButtonContainer>
                <Button
                  buttonType="warning"
                  onClick={activationHandler.bind(null, false)}
                >
                  Suspend
                </Button>
                <Button
                  buttonType="green"
                  onClick={activationHandler.bind(null, true)}
                >
                  Activate
                </Button>
              </ButtonContainer>
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default UserDetails;
