import {
  DocumentData,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";

import { firestore } from "./config";

export const addPHVehicle = async (userId: string, numberPlate: string) => {
  const userDocRef = doc(firestore, "users", userId);
  const userVehicleDocRef = doc(
    firestore,
    "users",
    userId,
    "PHVehicles",
    numberPlate
  );
  //if it's a first vehicle in collection, we will mark it as selected
  let isFirstVehicle = false;
  try {
    //check if user already has a vehicle with this numberPlate
    const userVehicleSnapshot = await getDoc(userVehicleDocRef);
    if (userVehicleSnapshot.exists()) {
      throw new Error("Vehicle with this number plate already exists.");
    }
    //check how many vehicles user has
    const userSnapshot = await getDoc(userDocRef);
    if (!userSnapshot.exists()) {
      throw new Error("Problem finding User");
    }
    const userData = userSnapshot.data() as DocumentData;
    if (
      !userData.driversData ||
      !userData.driversData.amountOfVehicles ||
      userData.driversData.amountOfVehicles < 1
    ) {
      isFirstVehicle = true;
      //updateDoc will replace data in nested destination
      //setDoc with {merge: true} will affect only specified fields
      await updateDoc(userDocRef, {
        "driversData.amountOfVehicles": 1,
        lastUpdate: serverTimestamp(),
      });
    } else if (userData!.driversData.amountOfVehicles >= 10) {
      //maximum amount of vehicles is 10
      throw new Error("Maximum amount of registered vehicles is 10");
    } else {
      //add number of vehicles
      await updateDoc(userDocRef, {
        "driversData.amountOfVehicles": increment(1),
        lastUpdate: serverTimestamp(),
      });
    }
    //add vehicle to PHVehicles subcollection
    await setDoc(userVehicleDocRef, {
      userId,
      numberPlate,
      createdAt: serverTimestamp(),
      lastUpdate: serverTimestamp(),
      selected: isFirstVehicle,
      approved: false,
      // licencePlate: "", //these fields are to be added by admin
      // make: "",
      // model: "",
      // color: "",
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error adding a new vehicle!");
    }
  }
};

export const addPHVehicleDoc = async (
  userId: string,
  numberPlate: string,
  docType: string,
  downloadUrl: string
) => {
  const userDocRef = doc(firestore, "users", userId);
  const vehicleDocRef = doc(
    firestore,
    "users",
    userId,
    "PHVehicles",
    numberPlate
  );
  try {
    //updateDoc will replace data in nested destination
    //setDoc with {merge: true} will affect only specified fields
    //we target nested data to keep expiry date if upload is not first
    await updateDoc(vehicleDocRef, {
      [`${docType}.pendingUrl`]: downloadUrl,
      waitingApproval: true,
      lastUpdate: serverTimestamp(),
    });
    //set waitngDriverApproval flag for user doc (needed for admin query)
    await updateDoc(userDocRef, {
      waitingDriverApproval: true,
      lastUpdate: serverTimestamp(),
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error adding a new document!");
    }
  }
};

export const selectPHVehicle = async (userId: string, numberPlate: string) => {
  const userVehicleColRef = collection(
    firestore,
    "users",
    userId,
    "PHVehicles"
  );
  try {
    //set all selected vehicles to unselected in a batch, even if we should only have one selected
    const selectedVehicleQuery = query(
      userVehicleColRef,
      where("selected", "==", true)
    );
    const batch = writeBatch(firestore);
    const querySnapshot = await getDocs(selectedVehicleQuery);
    querySnapshot.forEach((doc) => {
      batch.update(doc.ref, {
        selected: false,
        lastUpdate: serverTimestamp(),
      });
    });
    await batch.commit();
    //update desired vehicle to selected
    const vehicleDocRef = doc(
      firestore,
      "users",
      userId,
      "PHVehicles",
      numberPlate
    );
    await updateDoc(vehicleDocRef, {
      selected: true,
      lastUpdate: serverTimestamp(),
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error selecting a vehicle.");
    }
  }
};

export const deletePHVehicle = async (userId: string, numberPlate: string) => {
  const userDocRef = doc(firestore, "users", userId);
  const userVehicleDocRef = doc(
    firestore,
    "users",
    userId,
    "PHVehicles",
    numberPlate
  );
  try {
    //remove vehicle from PHVehicles subcollection
    await deleteDoc(userVehicleDocRef);
    //decrease number of vehicles
    await updateDoc(userDocRef, {
      "driversData.amountOfVehicles": increment(-1),
      lastUpdate: serverTimestamp(),
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error deleting a vehicle!");
    }
  }
};
