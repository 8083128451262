import { FC, InputHTMLAttributes } from "react";

import {
  CheckboxWrapper,
  StyledCheckbox,
  StyledCheckboxLabel,
} from "./checkbox.styles";

type CheckboxInputProps = {
  label: string;
  checked?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const Checkbox: FC<CheckboxInputProps> = ({
  label,
  checked,
  ...otherProps
}) => {
  // const defaultChecked = checked ? checked : false;

  return (
    <CheckboxWrapper>
      <StyledCheckbox
        type="checkbox"
        id={otherProps.id}
        name={otherProps.name}
        checked={checked}
        // onChange={otherProps.onChange} //not necessary, passed among other props
        {...otherProps}
      />
      <StyledCheckboxLabel htmlFor={otherProps.id}>{label}</StyledCheckboxLabel>
    </CheckboxWrapper>
  );
};
export default Checkbox;
