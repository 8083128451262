import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Card from "../../UI/card/card.component";
import Spinner from "../../UI/spinner/spinner.component";
import { RootState } from "../../../redux/store";
import {
  AmberP,
  BackButtonContainer,
  GreenHeader,
  GreenP,
  PushToLeftColumn,
  PushToRightColumn,
  RedHeader,
} from "../../../global.styles";
import Button from "../../UI/button/button.component";
import InnerCard from "../../UI/inner-card/inner-card.component";
import { defineVehicleType } from "../../../util-functions";
import { FaDog } from "react-icons/fa";
import { SpacerVertical } from "../../UI/spacers/spacers.component";

const MyJobOffers = () => {
  const navigate = useNavigate();

  const { userLoading, userError, currentUser } = useSelector(
    (state: RootState) => state.user
  );
  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);
  const { jobOffers, jobsOffersLoading, jobsOffersError } = useSelector(
    (state: RootState) => state.jobOffers
  );

  if (userLoading || approvedDriverLoading || jobsOffersLoading)
    return <Spinner />;
  return (
    <Card>
      <BackButtonContainer>
        <Link to="/drivers/driver-panel">
          <h3>&larr; Driver Menu</h3>
        </Link>
      </BackButtonContainer>
      {userError && <RedHeader>{userError}</RedHeader>}
      {approvedDriverError && <RedHeader>{approvedDriverError}</RedHeader>}
      {jobsOffersError && <RedHeader>{jobsOffersError}</RedHeader>}
      {currentUser && (!currentUser.readyToDrive || !approvedDriver) && (
        <>
          <GreenHeader>My Job Offers</GreenHeader>
          <AmberP>
            This section is available only for Drivers with approved paperwork.
            Lets stay legal...
          </AmberP>
          <BackButtonContainer>
            <Button onClick={() => navigate("/drivers/application")}>
              Check Driver Profile
            </Button>
          </BackButtonContainer>
        </>
      )}
      {currentUser && currentUser.readyToDrive && approvedDriver && (
        <>
          <GreenHeader>My Job Offers</GreenHeader>
          <GreenP>Personal requests</GreenP>
          {approvedDriver.pausedRequests && (
            <AmberP>
              Your new requests are PAUSED!
              <br />
              Below You can see only requests that were sent before pausing.
            </AmberP>
          )}
          {!jobOffers || jobOffers.length === 0 ? (
            <>
              <SpacerVertical $paddingRem={1}>
                <p>Currently You don't have any new personal requests.</p>
              </SpacerVertical>
              <BackButtonContainer>
                <Button onClick={() => navigate("/drivers/driver-panel")}>
                  OK
                </Button>
              </BackButtonContainer>
            </>
          ) : (
            jobOffers.map((job) => (
              <InnerCard
                key={job.id}
                onClick={() =>
                  navigate("/drivers/job-offer-details", {
                    state: {
                      jobId: job.id,
                    },
                  })
                }
              >
                <PushToLeftColumn>
                  <h3>{job.pickupPostCode}</h3>
                  <p>{job.distanceInMiles} miles trip</p>
                </PushToLeftColumn>
                <PushToRightColumn>
                  <h3>£{job.quotedPrice}</h3>
                  <p>{defineVehicleType(job.vehicleType)}</p>
                  {job.hasPet && (
                    <GreenP>
                      <FaDog /> Pet
                    </GreenP>
                  )}
                </PushToRightColumn>
              </InnerCard>
            ))
          )}
        </>
      )}
    </Card>
  );
};

export default MyJobOffers;
