import { FC } from "react";

import {
  Body,
  CancelButton,
  CloseButtonXContainer,
  ConfirmButton,
  Footer,
  ModalBackground,
  ModalContainer,
  Title,
} from "./modal.styles";
import { GreenHeader } from "../../../global.styles";

type ModalProps = {
  title: string;
  body: string;
  confirmButtonText: string;
  setOpenModal: (modalOpen: boolean) => void;
  onModalConfirm: () => void;
};

const Modal: FC<ModalProps> = ({
  title,
  body,
  confirmButtonText,
  setOpenModal,
  onModalConfirm,
}) => {
  const closeModalHandler = () => {
    setOpenModal(false);
  };
  const confirmHandler = () => {
    onModalConfirm();
    setOpenModal(false);
  };

  return (
    <ModalBackground>
      <ModalContainer>
        <CloseButtonXContainer>
          <button onClick={closeModalHandler}>X</button>
        </CloseButtonXContainer>
        <Title>
          <GreenHeader>{title}</GreenHeader>
        </Title>
        <Body>
          <p>{body}</p>
        </Body>
        <Footer>
          <ConfirmButton onClick={confirmHandler}>{confirmButtonText}</ConfirmButton>
          <CancelButton onClick={closeModalHandler}>Cancel</CancelButton>
        </Footer>
      </ModalContainer>
    </ModalBackground>
  );
};

export default Modal;
