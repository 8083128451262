import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { BookingType } from "../bookings/bookings.types";

// Define a type for the slice state
interface BookingState {
  bookingDetails: BookingType | null;
  bookingDetailsLoading: boolean;
  bookingDetailsError: string | null;
}

const INITIAL_STATE: BookingState = {
  bookingDetails: null,
  bookingDetailsLoading: true,
  bookingDetailsError: null,
};

export const bookingDetailsSlice = createSlice({
  name: "bookingDetails",
  initialState: INITIAL_STATE,
  reducers: {
    //updateUserRealTime
    updateBookingDetailsRealTime(
      state,
      action: PayloadAction<BookingType | null>
    ) {
      state.bookingDetailsLoading = false;
      state.bookingDetails = action.payload;
    },
    errorBookingDetailsRealTime(state, action: PayloadAction<string>) {
      state.bookingDetailsLoading = false;
      state.bookingDetailsError = action.payload;
    },
    loadingBookingDetails(state) {
      state.bookingDetailsLoading = true;
      state.bookingDetails = null;
      state.bookingDetailsError = null;
    },

    //clear bookings data
    clearBookingDetails(state) {
      state.bookingDetails = null;
      state.bookingDetailsError = null;
      state.bookingDetailsLoading = false;
    },

    //clear error. We don't export this as other slices have the same name
    //Components use createAction(`${slice}/clearError`) to fire it
    clearError(state) {
      state.bookingDetailsError = null;
    },
  },
});

export const {
  updateBookingDetailsRealTime,
  errorBookingDetailsRealTime,
  loadingBookingDetails,
  clearBookingDetails,
} = bookingDetailsSlice.actions;

export const bookingDetailsReducer = bookingDetailsSlice.reducer;
