import { takeLatest, all, call, put } from "typed-redux-saga/macro";
import { PayloadAction } from "@reduxjs/toolkit";

import {  CreateBookingFromQuoteType } from "./bookings.types";
import { createBookingFromRecentQuote } from "../../firebase/bookings";
import { createBookingFailed, createBookingSuccess } from "./bookings.slice";



function* createBooking({
  payload: newBooking,
}: PayloadAction<CreateBookingFromQuoteType>) {
  try {
    yield* call(createBookingFromRecentQuote, newBooking);
    yield* put(createBookingSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield* put(createBookingFailed(error.message));
    } else {
      yield* put(createBookingFailed("Creating New Booking Failed..."));
    }
  }
}

//listeners
function* onCreateBookingStart() {
  yield* takeLatest("bookings/createBookingStart", createBooking);
}

export function* bookingsSagas() {
  yield* all([
    call(onCreateBookingStart),
  ]);
}
