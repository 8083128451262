import { ChangeEvent, FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../redux/store";
import {
  ButtonColumnContainer,
  ClickableArea,
  GreenHeader,
  GreenP,
  RedHeader,
  TextDivider,
} from "../../../global.styles";
import Button from "../../UI/button/button.component";
import { SpacerVertical } from "../../UI/spacers/spacers.component";
import FormInput from "../../UI/form-input/form-input.component";
import {
  approveVehiclePaperworkStart,
  rejectVehiclePaperworkStart,
} from "../../../redux/admin/selected-vehicle/selectedVehicle.slice";
import { transformTimestampDateOnly } from "../../../util-functions";
// import {
//   approveDriversPaperworkStart,
//   rejectDriversPaperworkStart,
// } from "../../../redux/admin/selected-user/selectedUser.slice";

type ApproveVehicleDocProps = {
  docTitle:
    | "PHVehicleLicence"
    | "logBook"
    | "MOTCertificate"
    | "insuranceCertificate"
    | "hireContract";
  onDocumentReviewed: () => void;
};

const ApproveVehiclePaperwork: FC<ApproveVehicleDocProps> = ({
  docTitle,
  onDocumentReviewed,
}) => {
  const dispatch = useDispatch();

  const { selectedUser } = useSelector(
    (state: RootState) => state.selectedUser
  );
  const { selectedVehicle } = useSelector(
    (state: RootState) => state.selectedVehicle
  );

  const [expiryDate, setExpiryDate] = useState("");
  const [reasonOfDecline, setReasonOfDecline] = useState("");

  if (
    !selectedUser ||
    !selectedUser.driversData ||
    !selectedVehicle ||
    !selectedVehicle[docTitle]
  ) {
    return <RedHeader>Incomplete data for this document</RedHeader>;
  }

  const pendingUrl = selectedVehicle[docTitle]!.pendingUrl;
  const downloadUrl = selectedVehicle[docTitle]!.downloadUrl;
  const expiringUrl = selectedVehicle[docTitle]!.expiringUrl;
  const currentExpiryDate = transformTimestampDateOnly(
    selectedVehicle[docTitle]!.expires
  );

  //input change handlers
  const dateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setExpiryDate(event.target.value);
  };
  const reasonChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setReasonOfDecline(event.target.value);
  };

  //submithandlers
  const approveHandler = () => {
    const expiryDateStamp = new Date(expiryDate).getTime();
    if (!expiryDateStamp || expiryDateStamp < new Date().getTime()) return;
    dispatch(
      approveVehiclePaperworkStart({
        userId: selectedUser.id,
        numberPlate: selectedVehicle.numberPlate,
        docType: docTitle,
        expiryDate: expiryDateStamp,
      })
    );
    onDocumentReviewed();
  };
  const declineHandler = () => {
    if (!reasonOfDecline || reasonOfDecline === "") return;
    dispatch(
      rejectVehiclePaperworkStart({
        userId: selectedUser.id,
        numberPlate: selectedVehicle.numberPlate,
        docType: docTitle,
        reasonOfDecline,
      })
    );
    onDocumentReviewed();
  };

  return (
    <ButtonColumnContainer>
      <GreenP>
        {selectedUser.name} - {selectedUser.driversData.licensingAuthority}
      </GreenP>
      <SpacerVertical $paddingRem={1} />
      {expiringUrl && (
        <ClickableArea>
          <GreenHeader onClick={() => window.open(expiringUrl, "_blank")}>
            Expiring Document &rarr;
          </GreenHeader>
        </ClickableArea>
      )}
      {downloadUrl && (
        <ClickableArea>
          <GreenHeader onClick={() => window.open(downloadUrl, "_blank")}>
            Current Document &rarr;
          </GreenHeader>
        </ClickableArea>
      )}
      {pendingUrl && (
        <ClickableArea>
          <GreenHeader onClick={() => window.open(pendingUrl, "_blank")}>
            Pending Document &rarr;
          </GreenHeader>
        </ClickableArea>
      )}
      {pendingUrl ? (
        <>
          <FormInput
            label="Set Expiry Date"
            type="date"
            onChange={dateChangeHandler}
            required
          />
          <Button onClick={approveHandler}>Approve</Button>
          <TextDivider>
            <GreenP> OR </GreenP>
          </TextDivider>
        </>
      ) : currentExpiryDate ? (
        <GreenP>
          Expires: <strong>{currentExpiryDate}</strong>
        </GreenP>
      ) : null}
      <FormInput
        label="Reason of Decline"
        onChange={reasonChangeHandler}
        required
      />
      <Button buttonType="warning" onClick={declineHandler}>
        Decline
      </Button>
    </ButtonColumnContainer>
  );
};

export default ApproveVehiclePaperwork;
