//can also import google font here...

//colors
// https://colorhunt.co/palette/f2e3db41644a263a29e86a33
export const colors = {
  dark: "#263a29",
  light: "#41644a",
  lightTransparent: "#41644a88",
  lightTextLow: "#b9b9b9",
  lightTextHigh: "#fff",
  primary: "#e86a33",
  background: "#f2e3db",
  warning: "#e05555",
};
