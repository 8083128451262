import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import {
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "firebase/auth";

import { auth } from "../../firebase/config";
import Card from "../../components/UI/card/card.component";
import FormInput from "../../components/UI/form-input/form-input.component";
import Button from "../../components/UI/button/button.component";
import { GreenHeader, GreenP, RedHeader } from "../../global.styles";
import Spinner from "../../components/UI/spinner/spinner.component";
import { OrContainer, SocialButtonsContainer } from "./authentication.styles";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  facebookSignInStart,
  googleSignInStart,
} from "../../redux/user/user.slice";
import { RootState } from "../../redux/store";

const Authentication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { search } = location;

  const [email, setEmail] = useState("");

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [infoMsg, setInfoMsg] = useState("");

  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState("");

  const [user] = useAuthState(auth);

  const { userError } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user) {
      //user is signed in, navigate to Profile page
      navigate("/");
    } else {
      //user is not logged in, but the link is valid
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt("Please provide your email for confirmation");
        }
        if (typeof email !== "string") {
          alert("Email is not provided. Please try again.");
          return;
        }
        // The client SDK will parse the code from the link for you.
        //Start authentication process
        setAuthLoading(true);
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            window.localStorage.removeItem("emailForSignIn");
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            setAuthLoading(false);
            setAuthError("");
            navigate("/");
          })
          .catch((error) => {
            setAuthLoading(false);
            if (error instanceof Error) {
              if (
                error.message === "Firebase: Error (auth/invalid-action-code)."
              ) {
                setAuthError("Authorization Link Expired");
              } else {
                setAuthError(error.message);
              }
            } else {
              setAuthError("Error logging in! Please try again later.");
            }
          });
      }
    }
  }, [navigate, user, search]);

  const formChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.replaceAll(" ", ""));
  };

  const handleLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoginLoading(true);
    sendSignInLinkToEmail(auth, email, {
      url: `${process.env.REACT_APP_MAIN_DOMAIN}/login`,
      handleCodeInApp: true,
    })
      .then(() => {
        localStorage.setItem("emailForSignIn", email);
        setLoginLoading(false);
        setLoginError("");
        setInfoMsg(
          `We have sent You an email with a single use link to log in. Please check your Inbox of ${email}. If it's not there - check Spam folder.`
        );
      })
      .catch((error) => {
        setLoginLoading(false);
        if (error instanceof Error) {
          if (error.message === "Firebase: Error (auth/invalid-email).") {
            setLoginError("Please enter a valid email");
          } else {
            setLoginError(error.message);
          }
        } else {
          setLoginError("Error sending an Email! Please try again later.");
        }
      });
  };

  //dispatch social media sign-ins
  const googleSigninHandler = () => {
    dispatch(googleSignInStart());
  };
  const facebookSigninHandler = () => {
    dispatch(facebookSignInStart());
  };

  return (
    <>
      {authLoading || user ? (
        <Spinner />
      ) : (
        <Card>
          {infoMsg ? (
            <GreenHeader>{infoMsg}</GreenHeader>
          ) : (
            <>
              {userError && <RedHeader>{userError}</RedHeader>}
              <h2>Log In / Create Account</h2>
              <br />
              <p>
                To log in on this device or create a new account please enter
                Your Email below, and we will send you an Authorization link:
              </p>
              <form onSubmit={handleLogin}>
                <FormInput
                  label="Your Email"
                  onChange={formChangeHandler}
                  id="email"
                  name="email"
                  //type="email" //removed this to get rid of spaces with trim() or .replaceAll(" ","")
                  value={email}
                  required
                />
                {loginLoading ? (
                  <Button disabled buttonType="inverted">
                    Sending...
                  </Button>
                ) : (
                  <Button type="submit">Send login Email</Button>
                )}
              </form>
              <SocialButtonsContainer>
                <h3>...alternatively, you can:</h3>
                <GoogleLoginButton onClick={googleSigninHandler} />
                <OrContainer>
                  <GreenP> - OR - </GreenP>
                </OrContainer>
                <FacebookLoginButton onClick={facebookSigninHandler} />
              </SocialButtonsContainer>
            </>
          )}

          {loginError !== "" && <RedHeader>{loginError}</RedHeader>}
          {authError !== "" && <RedHeader>{authError}</RedHeader>}
        </Card>
      )}
    </>
  );
};

export default Authentication;
