import { styled } from "styled-components";
import { colors } from "../../../style-variables";

export const InnerCardContainer = styled.div`
  margin: 1rem;
  padding: 1rem;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.background};
  /* color: ${colors.lightTextLow}; */
  border-radius: 0.5rem;
  box-shadow: 0.25rem 0.25rem 0.5rem ${colors.dark};
  cursor: pointer;
  &:hover,
  &:active {
    background-color: ${colors.light};
    color: ${colors.lightTextHigh};
    box-shadow: none;
    p {
      color: ${colors.lightTextHigh};
    }
  }
`;
