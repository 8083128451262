import { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { RootState } from "../../../redux/store";
import { DriversDataDocType } from "../../../redux/user/user.types";
import { PHVehicleDocTypes } from "../../../redux/vehicles/vehicles.types";
import { addDriverDocumentStart } from "../../../redux/user/user.slice";
import { GreenHeader } from "../../../global.styles";
import Card from "../../UI/card/card.component";
import FileUploadProgress from "../../UI/file-upload-progeress/file-upload-progeress.component";
import { addPHVehicleDocumentStart } from "../../../redux/vehicles/vehicles.slice";

let basePath: string;
let docTitle: string;
let navigateAfterUpload: string;

const UploadDocument = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { docType, numberPlate } = useParams();
  //check if docType belongs to the list of doctypes and set document title

  const { currentUser } = useSelector((state: RootState) => state.user);
  const { vehicles } = useSelector((state: RootState) => state.vehicles);

  const [documentStatus, setDocumentStatus] = useState("");

  useLayoutEffect(() => {
    if (numberPlate) {
      //set document title
      switch (docType) {
        case "PHVehicleLicence":
          docTitle = "Private Hire Vehicle licence for " + numberPlate;
          break;
        case "logBook":
          docTitle = "V5C logbook for " + numberPlate;
          break;
        case "MOTCertificate":
          docTitle = "MOT Certificate for " + numberPlate;
          break;
        case "insuranceCertificate":
          docTitle = "Insurance Certificate for " + numberPlate;
          break;
        case "hireContract":
          docTitle =
            "Hire Contract for " +
            numberPlate +
            " (only required if vehicle and paperwork are not in your name)";
          break;
        default:
          navigate("/404");
          break;
      }
      basePath = `drivers-paperwork/${
        currentUser!.id
      }/vehicles/${numberPlate}/${docType}`;
      navigateAfterUpload = "/drivers/vehicle-list";
      //check for pending and approved status
      const selectedVehicle = vehicles?.find(
        (vehicle) => vehicle.numberPlate === numberPlate
      );
      //check if numberplate belongs to user
      if (!selectedVehicle) {
        navigate("/404");
      }
      if (
        selectedVehicle &&
        selectedVehicle[docType as keyof PHVehicleDocTypes] &&
        selectedVehicle[docType as keyof PHVehicleDocTypes]?.pendingUrl &&
        selectedVehicle[docType as keyof PHVehicleDocTypes]?.pendingUrl !== ""
      ) {
        setDocumentStatus("pending");
      } else if (
        selectedVehicle &&
        selectedVehicle[docType as keyof PHVehicleDocTypes] &&
        selectedVehicle[docType as keyof PHVehicleDocTypes]?.approved
      ) {
        setDocumentStatus("approved");
      } else {
        setDocumentStatus("new");
      }
    } else {
      basePath = `drivers-paperwork/${currentUser!.id}/${docType}`;
      navigateAfterUpload = "/drivers/application";
      //set document title
      switch (docType) {
        case "PHBadge":
          docTitle = "Private Hire Badge";
          break;
        case "PHDriversLicence":
          docTitle = "Private Hire Driver paper licence";
          break;
        case "DVLALicence":
          docTitle = "DVLA Driving Licence (Front)";
          break;
        default:
          navigate("/404");
          break;
      }
      //check for pending and approved status
      if (
        currentUser?.driversData &&
        currentUser!.driversData[docType as keyof DriversDataDocType] &&
        currentUser!.driversData[docType as keyof DriversDataDocType]
          ?.pendingUrl &&
        currentUser!.driversData[docType as keyof DriversDataDocType]
          ?.pendingUrl !== ""
      ) {
        setDocumentStatus("pending");
      } else if (
        currentUser?.driversData &&
        currentUser!.driversData[docType as keyof DriversDataDocType] &&
        currentUser!.driversData[docType as keyof DriversDataDocType]?.approved
      ) {
        setDocumentStatus("approved");
      } else {
        setDocumentStatus("new");
      }
    }
  }, [currentUser, docType, navigate, numberPlate, vehicles]);

  const uploadedFileUrlHandler = (downloadUrl: string) => {
    if (docType && !numberPlate) {
      dispatch(
        addDriverDocumentStart({ id: currentUser!.id, docType, downloadUrl })
      );
    } else if (docType && numberPlate) {
      dispatch(
        addPHVehicleDocumentStart({
          userId: currentUser!.id,
          numberPlate,
          docType,
          downloadUrl,
        })
      );
    }
  };

  return (
    <Card>
      <GreenHeader>Upload {docTitle}</GreenHeader>
      {documentStatus === "approved" && (
        <p>
          Your document is approved and up to date, but if there is a reason
          (for example, if it's expiring soon etc.) - you can upload a new one.
        </p>
      )}
      <FileUploadProgress
        basePath={basePath}
        navigateAfterUpload={navigateAfterUpload}
        handleUploadFinish={uploadedFileUrlHandler}
        pending={documentStatus === "pending"}
      />
    </Card>
  );
};

export default UploadDocument;
