import { ChangeEvent, FC, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";

import FormInput from "../../UI/form-input/form-input.component";
import { ButtonContainer } from "../../UI/button/button.styles";
import Button from "../../UI/button/button.component";
import { addDVLACodeStart } from "../../../redux/user/user.slice";

type DVLACHeckCodeFormProps = {
  userId: string;
  onCancelEdit: () => void;
};

const DVLACheckCodeForm: FC<DVLACHeckCodeFormProps> = ({
  userId,
  onCancelEdit,
}) => {
  const dispatch = useDispatch();

  const [checkCode, setCheckCode] = useState("");

  const formChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setCheckCode(event.target.value);

  const hadleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const id = userId;
    dispatch(addDVLACodeStart({ id, checkCode }));
    onCancelEdit();
  };

  return (
    <div>
      <p>
        Can be otained from the Government website{" "}
        <a
          href="https://www.gov.uk/view-driving-licence"
          target="_blank"
          rel="noreferrer"
        >
          HERE
        </a>
      </p>
      <p>NOTE: Code is UPPER/lowercase sensitive</p>
      <form onSubmit={hadleFormSubmit}>
        <FormInput
          label="DVLA Check Code"
          onChange={formChangeHandler}
          id="checkCode"
          name="checkCode"
          type="text"
          value={checkCode}
          required
          autoCapitalize="off"
          autoCorrect="off"
        />

        <ButtonContainer>
          <Button type="submit">Submit</Button>
          <Button type="button" buttonType="inverted" onClick={onCancelEdit}>
            Cancel
          </Button>
        </ButtonContainer>
      </form>
    </div>
  );
};

export default DVLACheckCodeForm;
