import { FC, ButtonHTMLAttributes } from "react";

import {
  PrimaryButton,
  ButtonSpinner,
  GreenButton,
  InvertedButton,
  WarningButton,
} from "./button.styles";

export const BUTTON_TYPE_CLASSES = {
  primary: "primary",
  inverted: "inverted",
  green: "green",
  warning: "warning",
} as const;

//setting a type instead of using enum, more flexible
export type ButtonTypeClasses =
  typeof BUTTON_TYPE_CLASSES[keyof typeof BUTTON_TYPE_CLASSES];

// console.log(BUTTON_TYPE_CLASSES.primary === "primary");

const getButton = (buttonType: ButtonTypeClasses = BUTTON_TYPE_CLASSES.primary) =>
  ({
    [BUTTON_TYPE_CLASSES.primary]: PrimaryButton,
    [BUTTON_TYPE_CLASSES.inverted]: InvertedButton,
    [BUTTON_TYPE_CLASSES.green]: GreenButton,
    [BUTTON_TYPE_CLASSES.warning]: WarningButton,
  }[buttonType]);

type ButtonProps = {
  children?: React.ReactNode;
  buttonType?: ButtonTypeClasses;
  isLoading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FC<ButtonProps> = ({
  children,
  buttonType,
  isLoading,
  ...otherProps
}) => {
  const CustomButton = getButton(buttonType);

  return (
    <CustomButton disabled={isLoading} {...otherProps}>
      {isLoading ? <ButtonSpinner /> : children}
    </CustomButton>
  );
};

export default Button;
