import { combineReducers } from "@reduxjs/toolkit";

import { userReducer } from "./user/user.slice";
import { vehiclesReducer } from "./vehicles/vehicles.slice";
import { approvedDriverReducer } from "./approvedDRiver/approved-driver.slice";
import { userListReducer } from "./admin/user-list/userList.slice";
import { selectedUserReducer } from "./admin/selected-user/selectedUser.slice";
import { vehicleListReducer } from "./admin/vehicle-list/vehicleList.slice";
import { selectedVehicleReducer } from "./admin/selected-vehicle/selectedVehicle.slice";
import { bookingsReducer } from "./bookings/bookings.slice";
import { bookingDetailsReducer } from "./booking-details/bookingDetails.slice";
import { jobOffersReducer } from "./jobs/job-offers.slice";
import { jobsReducer } from "./jobs/jobs.slice";

export const rootReducer = combineReducers({
  user: userReducer,
  vehicles: vehiclesReducer,
  bookings: bookingsReducer,
  bookingDetails: bookingDetailsReducer,
  approvedDriver: approvedDriverReducer,
  jobOffers: jobOffersReducer,
  jobs: jobsReducer,
  userList: userListReducer,
  selectedUser: selectedUserReducer,
  vehicleList: vehicleListReducer,
  selectedVehicle: selectedVehicleReducer,
});
