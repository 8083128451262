import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { PHVehicleType } from "../../vehicles/vehicles.types";

// import {
//   AddPHVehicleDocType,
//   ModifyVehicleType,
//   PHVehicleType,
// } from "./vehicles.types";

// Define a type for the slice state
interface VehicleListState {
  vehicleList: PHVehicleType[] | null;
  vehicleListLoading: boolean;
  vehicleListError: string | null;
}

const INITIAL_STATE: VehicleListState = {
  vehicleList: null,
  vehicleListLoading: true,
  vehicleListError: null,
};

export const vehicleListSlice = createSlice({
  name: "vehicleList",
  initialState: INITIAL_STATE,
  reducers: {
    //get list of User's vehicles that need approval
    getUserVehiclesForAdminStart(state, action: PayloadAction<string>) {
      state.vehicleListLoading = true;
    },
    getUserVehiclesForAdminSuccess(
      state,
      action: PayloadAction<PHVehicleType[]>
    ) {
      state.vehicleListLoading = false;
      state.vehicleListError = null;
      state.vehicleList = action.payload;
    },
    getUserVehiclesForAdminFailed(state, action: PayloadAction<string>) {
      state.vehicleListLoading = false;
      state.vehicleListError = action.payload;
    },
    clearVehiclesList(state) {
      state.vehicleList = null;
      state.vehicleListLoading = false;
      state.vehicleListError = null;
    },

    //clear error. We don't export this as other slices have the same name
    //Components use createAction(`${slice}/clearError`) to fire it
    clearError(state) {
      state.vehicleListError = null;
    },
  },
});

export const {
  clearVehiclesList,
  getUserVehiclesForAdminFailed,
  getUserVehiclesForAdminStart,
  getUserVehiclesForAdminSuccess,
} = vehicleListSlice.actions;

export const vehicleListReducer = vehicleListSlice.reducer;
