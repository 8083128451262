import { Navigate, Route, Routes } from "react-router-dom";

import UserProfile from "../../components/user-profile/user-profile.component";

const Profile = () => {
  return (
    <Routes>
      <Route index element={<UserProfile />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default Profile;
