import styled from "styled-components";

import { SpinnerContainer } from "../spinner/spinner.styles";
import { colors } from "../../../style-variables";

export const PrimaryButton = styled.button`
  min-width: 5rem;
  width: auto;
  height: 3rem;
  padding: 0 2rem;
  font-size: 1rem;
  background-color: ${colors.primary};
  color: ${colors.background};
  text-transform: uppercase;
  font-family: inherit;
  font-weight: bolder;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    background-color: ${colors.background};
    color: ${colors.primary};
    border: 2px solid ${colors.primary};
  }
`;

export const InvertedButton = styled(PrimaryButton)`
  background-color: ${colors.background};
  color: ${colors.primary};
  border: 2px solid ${colors.primary};

  &:hover,
  &:focus {
    background-color: ${colors.primary};
    color: ${colors.background};
    border: none;
  }
`;

export const GreenButton = styled(PrimaryButton)`
  background-color: ${colors.dark};
  color: ${colors.lightTextHigh};

  &:hover,
  &:focus {
    background-color: ${colors.light};
    border: 2px solid ${colors.dark};
    color: ${colors.lightTextHigh};
  }
`;

export const WarningButton = styled(PrimaryButton)`
  background-color: ${colors.warning};
  color: ${colors.lightTextHigh};

  &:hover,
  &:focus {
    background-color: ${colors.lightTextHigh};
    border: 2px solid ${colors.warning};
    color: ${colors.warning};
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonSpinner = styled(SpinnerContainer)`
  width: 2rem;
  height: 2rem;
`;
