import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../redux/store";
import {
  AmberHeader,
  AmberP,
  BackButtonContainer,
  GreenHeader,
  GreenP,
  RedHeader,
  RedP,
  TextDivider,
} from "../../../global.styles";
import Card from "../../UI/card/card.component";
import { Link, useNavigate } from "react-router-dom";

import InnerCard from "../../UI/inner-card/inner-card.component";
import UpdatePhoneForm from "../../user-profile/update-phone/update-phone.cpmponent";
import { GreenButton } from "../../UI/button/button.styles";
import DVLACheckCodeForm from "../dvla-check-code/dvla-code-form.component";
import { selectSelectedVehicle } from "../../../redux/vehicles/vehicles.selector";
import { DriverNumber, DriverNumberContainer } from "./application.styles";
import Spinner from "../../UI/spinner/spinner.component";

const required = <RedP>Required</RedP>;
const pending = <AmberP>Pending...</AmberP>;
const expiresSoon = <AmberP>Expires soon</AmberP>;
const approved = <GreenP>Approved</GreenP>;
const updated = <GreenP>Updated</GreenP>;
//vehicle
const upToDate = <GreenP>Up To Date</GreenP>;
const requiresAttention = <RedP>Requires Attention</RedP>;

const Application = () => {
  const navigate = useNavigate();

  //user Driver
  const { currentUser, userLoading, userError } = useSelector(
    (state: RootState) => state.user
  );
  //vehicle
  const currentVehicle = useSelector(selectSelectedVehicle);
  //approvedDriver
  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  //if user has not selected licensing authority, redirect to drivers intro
  const authoritySelected = currentUser?.driversData?.licensingAuthority;
  useEffect(() => {
    if (!authoritySelected) {
      navigate("/drivers", { replace: true });
    }
  }, [authoritySelected, navigate]);

  //pull fresh data from DB to see if paperwork was approved
  // useEffect(() => {
  //   dispatch(checkUserSession());
  //   console.log("Rendered");

  // }, []);

  //states to show/hide updating and uploading drivers data
  //phone number
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const showPhoneEditHandler = () => {
    setIsEditingPhone(true);
  };
  const cancelEditPhoneHandler = () => {
    setIsEditingPhone(false);
  };
  //DVLA Check Code
  const [isAddingCheckCode, setIsAddingCheckCode] = useState(false);
  const showAdddCodeHandler = () => {
    setIsAddingCheckCode(true);
  };
  const cancelAddCodeHandler = () => {
    setIsAddingCheckCode(false);
  };
  const isDVLACodePending = currentUser!.driversData?.DVLACheckCode?.pending;
  const isDVLACodeApproved = currentUser!.driversData?.DVLACheckCode?.approved;

  const navigateToVehicleListHandler = () => {
    navigate("/drivers/vehicle-list");
  };

  if (userLoading || approvedDriverLoading) return <Spinner />;

  return (
    <Card>
      <BackButtonContainer>
        <Link to="/drivers/driver-panel">
          <h3>&larr; Driver Menu</h3>
        </Link>
      </BackButtonContainer>

      {userError && <RedHeader>{userError}</RedHeader>}
      {approvedDriverError && <RedHeader>{approvedDriverError}</RedHeader>}
      {approvedDriver &&
      !approvedDriver.pausedRequests &&
      currentUser!.readyToDrive &&
      currentUser!.driverNumber ? (
        <>
          <GreenHeader>Status: ACTIVE</GreenHeader>
          <h3>
            You are all set and ready to take bookings, <br />
            Your Driver number is:
          </h3>
          <DriverNumberContainer>
            <DriverNumber>{currentUser!.driverNumber}</DriverNumber>
          </DriverNumberContainer>
          <h3>Give it to your customers, so they can book you directly!</h3>
          <TextDivider>
            <GreenP>DETAILS</GreenP>
          </TextDivider>
        </>
      ) : approvedDriver &&
        approvedDriver.pausedRequests &&
        currentUser!.readyToDrive &&
        currentUser!.driverNumber ? (
        <>
          <AmberHeader>Status: NEW REQUESTS STOPPED</AmberHeader>
        </>
      ) : (
        <>
          <RedHeader>Status: NOT ACTIVE</RedHeader>
          <GreenHeader>Paperwork! Don't we just love it?...</GreenHeader>
          <h3>
            ...but it needs to be done! Here we go, please, provide some of your
            details and upload your Driver and Vehicle documents *:
          </h3>
          <br />
          <hr />
          <p>
            * to find out how we deal with your personal data, please refer to
            our
            <br />
            PRIVACY POLICY
          </p>
        </>
      )}
      <GreenHeader>Driver:</GreenHeader>

      <InnerCard onClick={showPhoneEditHandler}>
        <h3>Phone Number</h3>
        {currentUser!.phoneNumber ? updated : required}
      </InnerCard>
      {isEditingPhone && (
        <UpdatePhoneForm
          userId={currentUser!.id}
          currentPhoneNumber={
            currentUser!.phoneNumber ? currentUser!.phoneNumber : ""
          }
          onCancelEdit={cancelEditPhoneHandler}
        />
      )}

      <InnerCard
        onClick={() => {
          navigate("/drivers/upload-doc/PHBadge");
        }}
      >
        <h3>Private Hire Badge</h3>
        {currentUser!.driversData &&
        currentUser!.driversData.PHBadge &&
        currentUser!.driversData.PHBadge.pendingUrl ? (
          pending
        ) : currentUser!.driversData &&
          currentUser!.driversData.PHBadge &&
          currentUser!.driversData.PHBadge.expiresSoon &&
          currentUser!.driversData.PHBadge.approved ? (
          expiresSoon
        ) : currentUser!.driversData &&
          currentUser!.driversData.PHBadge &&
          currentUser!.driversData.PHBadge.approved ? (
          approved
        ) : currentUser!.driversData &&
          currentUser!.driversData.PHBadge &&
          currentUser!.driversData.PHBadge.reasonOfDecline ? (
          <RedP>{currentUser!.driversData.PHBadge.reasonOfDecline}</RedP>
        ) : (
          required
        )}
      </InnerCard>

      <InnerCard
        onClick={() => {
          navigate("/drivers/upload-doc/PHDriversLicence");
        }}
      >
        <h3>PH Driver paper licence</h3>
        {currentUser!.driversData &&
        currentUser!.driversData.PHDriversLicence &&
        currentUser!.driversData.PHDriversLicence.pendingUrl ? (
          pending
        ) : currentUser!.driversData &&
          currentUser!.driversData.PHDriversLicence &&
          currentUser!.driversData.PHDriversLicence.expiresSoon &&
          currentUser!.driversData.PHDriversLicence.approved ? (
          expiresSoon
        ) : currentUser!.driversData &&
          currentUser!.driversData.PHDriversLicence &&
          currentUser!.driversData.PHDriversLicence.approved ? (
          approved
        ) : currentUser!.driversData &&
          currentUser!.driversData.PHDriversLicence &&
          currentUser!.driversData.PHDriversLicence.reasonOfDecline ? (
          <RedP>
            {currentUser!.driversData.PHDriversLicence.reasonOfDecline}
          </RedP>
        ) : (
          required
        )}
      </InnerCard>

      <InnerCard
        onClick={() => {
          navigate("/drivers/upload-doc/DVLALicence");
        }}
      >
        <h3>DVLA licence</h3>
        {currentUser!.driversData &&
        currentUser!.driversData.DVLALicence &&
        currentUser!.driversData.DVLALicence.pendingUrl ? (
          pending
        ) : currentUser!.driversData &&
          currentUser!.driversData.DVLALicence &&
          currentUser!.driversData.DVLALicence.expiresSoon &&
          currentUser!.driversData.DVLALicence.approved ? (
          expiresSoon
        ) : currentUser!.driversData &&
          currentUser!.driversData.DVLALicence &&
          currentUser!.driversData.DVLALicence.approved ? (
          approved
        ) : currentUser!.driversData &&
          currentUser!.driversData.DVLALicence &&
          currentUser!.driversData.DVLALicence.reasonOfDecline ? (
          <RedP>{currentUser!.driversData.DVLALicence.reasonOfDecline}</RedP>
        ) : (
          required
        )}
      </InnerCard>

      <InnerCard onClick={showAdddCodeHandler}>
        <h3>DVLA Check Code</h3>
        {isDVLACodeApproved ? approved : isDVLACodePending ? pending : required}
      </InnerCard>
      {isAddingCheckCode && !isDVLACodePending && !isDVLACodeApproved && (
        <DVLACheckCodeForm
          userId={currentUser!.id}
          onCancelEdit={cancelAddCodeHandler}
        />
      )}

      <br />
      <GreenHeader>Vehicle</GreenHeader>

      {currentVehicle ? (
        <InnerCard
          onClick={() => {
            navigate("/drivers/vehicle-list");
          }}
        >
          <div>
            <h3>{currentVehicle.numberPlate}</h3>
            {currentVehicle.color &&
              currentVehicle.make &&
              currentVehicle.model && (
                <GreenP>
                  {currentVehicle.color} {currentVehicle.make}{" "}
                  {currentVehicle.model}
                </GreenP>
              )}
          </div>
          {currentVehicle.waitingApproval
            ? pending
            : currentVehicle.approved
            ? upToDate
            : requiresAttention}
        </InnerCard>
      ) : (
        <>
          <GreenP>You don't have any vehilcles added or selected...</GreenP>
          <br />
          <BackButtonContainer>
            <GreenButton onClick={navigateToVehicleListHandler}>
              Go to Vehicle Section
            </GreenButton>
          </BackButtonContainer>
        </>
      )}
    </Card>
  );
};

export default Application;
