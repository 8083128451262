import { FC, InputHTMLAttributes } from "react";

import {
  RadioWrapper,
  StyledRadio,
  StyledRadioLabel,
} from "./radio-input.styles";

type RadioInputProps = {
  label: string;
} & InputHTMLAttributes<HTMLInputElement>;

const RadioButton: FC<RadioInputProps> = ({
  label,
  ...otherProps
}) => {
  return (
    <RadioWrapper>
      <StyledRadio
        type="radio"
        id={otherProps.id}
        name={otherProps.name}
        value={otherProps.value}
        defaultChecked={otherProps.defaultChecked}
        onChange={otherProps.onChange}
      />
      <StyledRadioLabel htmlFor={otherProps.id}>{label}</StyledRadioLabel>
    </RadioWrapper>
  );
};

export default RadioButton;
