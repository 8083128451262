import { ChangeEvent, FormEvent, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  BackButtonContainer,
  ClickableArea,
  FlexGrowItem,
  GreenHeader,
  GreenP,
  GreenX,
  PushToRightArea,
  RedHeader,
  SpaceAroundArea,
  SpreadApartArea,
} from "../../global.styles";
import Card from "../UI/card/card.component";
import FormInput from "../UI/form-input/form-input.component";
import { GreenButton } from "../UI/button/button.styles";
import Button from "../UI/button/button.component";
import { SpacerHorizontal } from "../UI/spacers/spacers.component";
import RadioButton from "../UI/radio-buttons/radio-button";
import {
  VehcileTypeArray,
  VehicleType,
} from "../../redux/vehicles/vehicles.types";
import { getPriceQuote } from "../../firebase/bookings";
import Spinner from "../UI/spinner/spinner.component";
import Checkbox from "../UI/checkbox/checkbox.component";

const QuoteForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialQuoteRequest = location.state
    ? location.state.quoteRequest
    : null;
  const initialDate = initialQuoteRequest ? initialQuoteRequest.date : "";
  const initialTime = initialQuoteRequest ? initialQuoteRequest.time : "";
  const initialPickup = initialQuoteRequest ? initialQuoteRequest.pickup : "";
  const initialHasPet = initialQuoteRequest
    ? initialQuoteRequest.hasPet
    : false;
  const initialDestinations = initialQuoteRequest
    ? (initialQuoteRequest.destinations as string[])
    : [""];
  const initialVehicleType = initialQuoteRequest
    ? initialQuoteRequest.vehicleType
    : "4Seater";
  //states for quote response
  const [loadingQuote, setLoadingQuote] = useState(false);
  const [quoteError, setQuoteError] = useState("");
  //form states
  const [date, setDate] = useState(initialDate);
  const [time, setTime] = useState(initialTime);
  const [pickup, setPickup] = useState(initialPickup);
  //variable amount of destinations (max 5)
  const [destinations, setDestinations] = useState(initialDestinations);
  //vehicle type
  const [vehicleType, setVehicleType] =
    useState<VehicleType>(initialVehicleType);
  //does Passender have a pet
  const [hasPet, setHasPet] = useState(initialHasPet);

  //form input handlers
  const dateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };
  const timeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
  };
  const pickupChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPickup(event.target.value);
  };
  const destinationChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    //things we do to keep TypeScript happy :)
    const passedIndex = event.target.dataset.index;
    if (passedIndex && typeof Number(passedIndex) === "number") {
      const updatedDestinations = [...destinations];
      updatedDestinations[Number(passedIndex)] = event.target.value;
      setDestinations(updatedDestinations);
    }
  };
  const addExtraDestination = () => {
    const updatedDestinations = [...destinations, ""];
    setDestinations(updatedDestinations);
  };
  const removeExtraDestination = (index: number) => {
    // const updatedDestinations = destinations.filter((_, i) => i !== index); //OR
    // const updatedDestinations = destinations.toSpliced(index, 1); //TypeScript or ES versions too old
    const updatedDestinations = [...destinations];
    updatedDestinations.splice(index, 1);
    setDestinations(updatedDestinations);
  };
  const vehicleTypeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (VehcileTypeArray.includes(event.target.value as VehicleType)) {
      setVehicleType(event.target.value as VehicleType);
    }
  };
  const hasPetHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setHasPet(!hasPet);
  };
  //submit
  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (new Date(`${date} ${time}`).getTime() < Date.now()) {
      setQuoteError(
        "Can't book a trip for past time! Time travel is not invented yet :)"
      );
      return;
    }
    setLoadingQuote(true);
    const quoteRequest = {
      date,
      time,
      pickup,
      destinations,
      vehicleType,
      hasPet,
    };
    try {
      const quote = await getPriceQuote(quoteRequest);
      setLoadingQuote(false);
      setQuoteError("");
      navigate("/bookings/booking-form", { state: { quoteRequest, quote } });
    } catch (error) {
      if (error instanceof Error) {
        setQuoteError(error.message);
      } else {
        setQuoteError("Error processing the quote. Please, try again later");
      }
      setLoadingQuote(false);
    }
  };

  if (loadingQuote) return <Spinner />;

  return (
    <Card>
      <BackButtonContainer>
        <Link to="/">
          <h3>Go Back</h3>
        </Link>
      </BackButtonContainer>
      <GreenHeader>Get a Quote:</GreenHeader>
      {quoteError && <RedHeader>{quoteError}</RedHeader>}
      <form onSubmit={submitHandler}>
        <FormInput
          label="Pickup address"
          id="pickup_address"
          type="text"
          onChange={pickupChangeHandler}
          value={pickup}
          required
        />
        {destinations.map((destination, index) => {
          return (
            <SpreadApartArea key={`destination-${index}`}>
              <FlexGrowItem>
                <FormInput
                  label={`Destination address ${
                    destinations.length > 1 ? (index + 1).toString() : ""
                  }`}
                  id={`destination-address${destination.length > 1 && index}`}
                  type="text"
                  data-index={index}
                  value={destinations[index]}
                  onChange={destinationChangeHandler}
                  required
                />
              </FlexGrowItem>
              {destinations.length > 1 && (
                <ClickableArea
                  onClick={removeExtraDestination.bind(null, index)}
                >
                  <GreenX>X</GreenX>
                </ClickableArea>
              )}
            </SpreadApartArea>
          );
        })}
        {destinations.length < 5 && (
          <PushToRightArea>
            <GreenP>Add Extra Destination</GreenP>
            <SpacerHorizontal $paddingRem={2} />
            <GreenButton type="button" onClick={addExtraDestination}>
              Add
            </GreenButton>
          </PushToRightArea>
        )}
        <SpaceAroundArea>
          <GreenP>Date and time:</GreenP>
          <FormInput
            label=""
            id="date"
            type="date"
            onChange={dateChangeHandler}
            value={date}
            required
          />
          <FormInput
            label=""
            id="time"
            type="time"
            onChange={timeChangeHandler}
            value={time}
            required
          />
        </SpaceAroundArea>
        <GreenP>Please select vehicle type:</GreenP>
        <RadioButton
          label="4 Seater"
          id="4Seater"
          name="vehicleType"
          value="4Seater"
          defaultChecked={vehicleType === "4Seater"}
          // checked={vehicleType === "4Seater"}
          onChange={vehicleTypeHandler}
        />
        <RadioButton
          label="4 Seater Estate"
          id="4SeaterEstate"
          name="vehicleType"
          value="4SeaterEstate"
          defaultChecked={vehicleType === "4SeaterEstate"}
          onChange={vehicleTypeHandler}
        />
        <RadioButton
          label="6 Seater"
          id="6Seater"
          name="vehicleType"
          value="6Seater"
          defaultChecked={vehicleType === "6Seater"}
          onChange={vehicleTypeHandler}
        />
        <RadioButton
          label="Minibus (7 or 8 seats)"
          id="minibus"
          name="vehicleType"
          value="minibus"
          defaultChecked={vehicleType === "minibus"}
          onChange={vehicleTypeHandler}
        />
        <RadioButton
          label="Executive"
          id="executive"
          name="vehicleType"
          value="executive"
          defaultChecked={vehicleType === "executive"}
          onChange={vehicleTypeHandler}
        />
        <GreenP>Some of our Drivers will happily take Your Pet onboard:</GreenP>
        <Checkbox
          label="Include a Pet (£8 extra)"
          id="pet"
          name="pet"
          checked={hasPet}
          onChange={hasPetHandler}
        />
        {quoteError && <RedHeader>{quoteError}</RedHeader>}
        <BackButtonContainer>
          <Button type="submit">Get a Quote</Button>
        </BackButtonContainer>
      </form>
    </Card>
  );
};

export default QuoteForm;
