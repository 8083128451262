import Card from "../../components/UI/card/card.component";
import { BackButtonContainer, GreenHeader, GreenP } from "../../global.styles";

const PrivacyPolicy = () => {
  return (
    <Card>
      <GreenHeader> Privacy Policy and Use of Cookies</GreenHeader>
      <BackButtonContainer>
        <GreenP>
          Effective Date: <strong>5th of June 2024</strong>
        </GreenP>
      </BackButtonContainer>
      <h3>1. Introduction</h3>
      <p>
        Welcome to Rufus Taxi Service of Waway LTD (further referred to as
        "Company", "we", "our", "us"). We are committed to protecting Your
        privacy. This Privacy and Cookies Policy explains how we collect, use,
        disclose, and safeguard Your information when You visit our website{" "}
        <strong>https://rufus.taxi/</strong>, use our mobile application, or
        book a taxi through our service. Please read this policy carefully. If
        You do not agree with the terms of this policy, please do not access the
        site or use our services.
      </p>
      <h3>2. Information We Collect</h3>
      <h4>a. Personal Information</h4>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>
          Payment information (credit/debit card details), if You are using paid
          service
        </li>
        <li>
          Drivers are required to upload licensing and insurance paperwork. We
          don't share it with anyone, unless it's required by law.
        </li>
      </ul>
      <h4>b. Booking Details</h4>
      <ul>
        <li>Trip origin and destination</li>
        <li>Date and time of booking</li>
        <li>
          Passenger phone number will be shared with the Driver and vice versa
        </li>
        <li>Driver and vehicle details</li>
      </ul>
      <h4>c. Technical Information</h4>
      <ul>
        <li>IP address</li>
        <li>Browser type and version</li>
        <li>Operating system</li>
        <li>Device information</li>
      </ul>
      <h4>d. Usage Data</h4>
      <ul>
        <li>Pages visited</li>
        <li>Time spent on the website</li>
        <li>Clickstream data</li>
      </ul>
      <h4>e. Location Data</h4>
      <ul>
        <li>Real-time GPS location (with Your consent)</li>
      </ul>
      <h3>3. How We Use Your Information</h3>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>To provide, operate, and maintain our services</li>
        <li>To process Your bookings and payments</li>
        <li>
          To communicate with You, including sending confirmation emails,
          receipts, and updates
        </li>
        <li>To personalize Your experience and provide tailored services</li>
        <li>To improve our website, app, and services</li>
        <li>To ensure the safety and security of our services</li>
        <li>To comply with legal obligations</li>
      </ul>
      <p>
        For more detailed explanation and to see how we use Your personal data
        when logging in via email, Facebook, or Google, please see paragraph 10
        below.
      </p>
      <h3>4. Sharing Your Information</h3>
      <p>We may share Your information with:</p>
      <ul>
        <li>
          <strong>Service Providers:</strong> Third-party companies that provide
          services on our behalf, such as payment processing, partnering taxi
          companies, data analysis, email delivery, and customer service.
        </li>
        <li>
          <strong>Drivers:</strong> Your name, contact details, and trip
          information will be shared with Drivers to facilitate Your booking.
        </li>
        <li>
          <strong>Legal and Regulatory Authorities:</strong> When required by
          law or to protect our rights and interests.
        </li>
      </ul>
      <h3>5. Data Security</h3>
      <p>
        We use administrative, technical, and physical security measures to
        protect Your personal information. However, no security system is
        impenetrable, and we cannot guarantee the security of our systems 100%.
      </p>
      <h3>6. Your Rights</h3>
      <p>
        Depending on Your location, You may have the following rights regarding
        Your personal data:
      </p>
      <ul>
        <li>
          <strong>Access:</strong> You have the right to request a copy of Your
          personal information.
        </li>
        <li>
          <strong>Rectification:</strong> You have the right to request
          correction of any inaccurate or incomplete data.
        </li>
        <li>
          <strong>Deletion:</strong> You have the right to request deletion of
          Your personal information, subject to certain exceptions. For more
          info, please see paragraph 11 below.
        </li>
        <li>
          <strong>Objection:</strong> You have the right to object to the
          processing of Your data for specific purposes.
        </li>
        <li>
          <strong>Portability:</strong> You have the right to request transfer
          of Your data to another service provider.
        </li>
      </ul>
      <h3>7. Cookies and Tracking Technologies</h3>
      <p>
        We use cookies and similar tracking technologies to track activity on
        our service and hold certain information. Cookies are small data files
        that are placed on Your device or computer and often include an
        anonymous unique identifier. We use cookies to:
      </p>
      <ul>
        <li>Keep You logged in to our service</li>
        <li>Remember Your preferences and settings</li>
        <li>Understand how You use our website</li>
        <li>Improve our services and website performance</li>
      </ul>
      <p>
        You can instruct Your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if You do not accept cookies, You may
        not be able to use some portions of our service.
      </p>
      <h3>8. Third-Party Links</h3>
      <p>
        Our website may contain links to third-party websites that are not
        operated by us. If You click on a third-party link, You will be directed
        to that third party's site. We strongly advise You to review the privacy
        policy of every site You visit.
      </p>
      <h3>9. Children's Privacy</h3>
      <p>
        Our services are not intended for use by children under the age of 13.
        We do not knowingly collect personal information from children under 13.
        If You become aware that a child has provided us with personal data,
        please contact us so that we can delete such information.
      </p>
      <h3>
        10. Use of Personal Data When Logging in via Email, Facebook, or Google
      </h3>
      <h4>a. Information We Collect</h4>
      <p>
        When You choose to log in to our services using Your email, Facebook, or
        Google account, we may collect the following information:
      </p>
      <ul>
        <li>
          <strong>Email:</strong> Your email address (we use passwordless
          technology). Once email is approved, we will ask for Your name.
        </li>
        <li>
          <strong>Facebook:</strong> Although Facebook policy can allow us to
          use any information You have made public on Your Facebook profile, we
          only use Your email address and Your name as identifier for use of our
          services. We DO NOT have any access to Your Facebook private data or
          password.
        </li>
        <li>
          <strong>Google:</strong> Google provides basic profile information
          (e.g., name, profile picture, age, gender, language, and country),
          email address, and any other information You have made public on Your
          Google profile, but we only use Your email address and Your name as
          identifier for use of our services. We DO NOT have any access to Your
          Google private data or password.
        </li>
      </ul>
      <h4>b. How We Use the Information (Purposes Of Use)</h4>
      <p>
        We use the information obtained from Your email, Facebook, or Google
        account for the following purposes:
      </p>
      <ul>
        <li>
          <strong>Account Creation and Authentication:</strong> To create and
          manage Your user account on our platform and authenticate Your
          identity. In order to streamline the account creation process for our
          Users our application utilizes the login via Facebook or Google
          account method. When You login with Your Facebook or Google account,
          we will collect only Your email and name for identification purposes
          in order to create a unique account for You within our system.
        </li>
        <li>
          <strong>Communication:</strong> To send You service-related
          communications, such as booking confirmations, updates, and
          notifications. Your email will be used only for necessary
          notifications so that You can fully utilize the services provided by
          our application. Any notifications will only be sent for purposes
          requested or accepted by You as the User.
          <br />
          We will not send you promotional or unsolicited emails without your
          consent. You can opt-in or opt-out of such notifications at any time.
        </li>
      </ul>
      <h4>c. Data Sharing</h4>
      <p>
        We do not share the personal information obtained from Your email,
        Facebook, or Google account with third parties, except in the following
        circumstances:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may share Your information with
          third-party service providers who assist us in providing and managing
          our services, such as hosting, data analysis, payment processing, and
          customer service.
        </li>
        <li>
          <strong>Legal Obligations:</strong> We may disclose Your information
          if required to do so by law or in response to valid requests by public
          authorities (e.g., a court or a government agency).
        </li>
        <li>
          <strong>Protection of Rights:</strong> We may disclose Your
          information to protect the rights, property, or safety of our company,
          our users, or others.
        </li>
      </ul>
      <h4>d. Managing Your Information</h4>
      <p>
        You can manage the information shared with us through Your Facebook or
        Google account by adjusting the privacy settings of Your social media
        profiles. If You wish to disconnect Your Facebook or Google account from
        our services, You can do so by simply logging out on our website Profile
        page or mobile application.
      </p>
      <h4>e. Data Security</h4>
      <p>
        We take the security of Your personal information seriously and use a
        variety of security measures to protect Your data from unauthorized
        access, use, or disclosure. However, no method of transmission over the
        internet or method of electronic storage is 100% secure.
      </p>
      <h4>f. Your Rights</h4>
      <p>
        Depending on Your location, You may have the following rights regarding
        Your personal data:
      </p>
      <ul>
        <li>
          <strong>Access:</strong> You have the right to request a copy of the
          personal information we hold about You.
        </li>
        <li>
          <strong>Correction:</strong> You have the right to request correction
          of any inaccurate or incomplete data.
        </li>
        <li>
          <strong>Deletion:</strong> You have the right to request the deletion
          of Your personal information, subject to certain exceptions. For more
          info, lease see paragraph 11 below.
        </li>
        <li>
          <strong>Objection:</strong> You have the right to object to the
          processing of Your data for specific purposes.
        </li>
        <li>
          <strong>Portability:</strong> You have the right to request the
          transfer of Your data to another service provider.
        </li>
      </ul>
      <h3 id="data-deletion">11. Account Deletion (Delete Your Personal Data)</h3>
      <p>
        At <strong>Rufus Taxi Services</strong>, we respect Your right to
        privacy and understand that You may want to delete Your account with us.
        To request the deletion of Your account, please follow the procedure
        outlined below:
      </p>
      <h4>Request Submission</h4>
      <p>
        Users wishing to delete their account must contact our administration
        team. This request must be made using the email address that is
        registered with the account to be deleted. This way we will know that
        the User is the email account holder. Please send Your account deletion
        request to{" "}
        <a href="mailto:wawayltd@protonmail.com">wawayltd@protonmail.com</a>.
      </p>
      <h4>Information to Include</h4>
      <ul>
        <li> Please include the subject line "Data Deletion Request".</li>
        <li>Your full name </li>
        <li>The email address associated with Your account </li>
        <li>A detailed description of the data You wish to be deleted</li>
        <li>
          Any relevant account information or identification details to help us
          verify Your identity
        </li>
      </ul>
      <h4>Review Process</h4>
      <p>
        Upon receiving Your request, our administration team will review it
        within 24 hours.
      </p>
      <h4>Data Retention Obligations</h4>
      <p>
        If we have no legal obligations to retain Your data, we will proceed
        with the deletion of Your account from our system.
      </p>
      <h4>Confirmation</h4>
      <p>
        You will receive a confirmation email once Your account has been
        successfully deleted.
      </p>
      <p>
        Please note that if there are any legal reasons that require us to keep
        certain data, we will inform You of this during the review process. We
        are committed to handling all data deletion requests promptly and in
        compliance with applicable privacy laws and regulations.
      </p>
      <p>
        If You have any questions or need further assistance, please do not
        hesitate to contact our administration team at{" "}
        <a href="mailto:wawayltd@protonmail.com">wawayltd@protonmail.com</a>.
      </p>
      <h3>12. Changes to This Privacy Policy</h3>
      <p>
        We may update our Privacy and Cookies Policy from time to time. We will
        notify You of any changes by posting the new policy on this page and
        updating the "Effective Date" at the top of this policy. You are advised
        to review this policy periodically for any changes.
      </p>
      <h3>13. Contact Us</h3>
      <p>
        If You have any questions about this Privacy and Cookies Policy, please
        contact us at:
      </p>
      <p>
        <strong>Email:</strong> wawayltd@protonmail.com
      </p>
      <p>
        <strong>Address:</strong> <br />
        Waway LTD
        <br />
        2530 Aztec West (Ground Floor)
        <br />
        Almondsbury
        <br />
        BS32 4AW
        <br />
        Bristol
        <br />
        UK
      </p>
    </Card>
  );
};

export default PrivacyPolicy;
