import styled from "styled-components";

import { colors } from "../../../style-variables";

export const StyledTextArea = styled.textarea`
  background: ${colors.background};
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${colors.dark};
  font-size: 1.2rem;
  color: ${colors.dark};
  line-height: 1.5;
  padding: 8px 0;
  resize: none;
  transition: border-color 150ms ease;
  width: 100%;
`;
