import styled from "styled-components";

import { colors } from "../../../style-variables";

//taken from here
//https://medium.com/@christinaroise/how-to-create-a-reusable-custom-radiobutton-in-react-with-typescript-3ae7fc704e09

export const RadioWrapper = styled.div`
  display: flex;
  padding: 0.7rem;
  gap: 0.5rem;
  align-items: center;
`;

export const StyledRadioLabel = styled.label<{ disabled?: boolean }>`
  /* font-weight: 600; */
  font-size: 1.2rem;
  color: ${colors.dark};
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
    color: ${colors.lightTransparent};
    cursor: not-allowed;
    &::after {
      margin-left: 8px;
      width: 12px;
      height: 15px;
      display: inline-block;
      content: " ";
      -webkit-mask: url("/lock.svg") no-repeat 50% 50%;
      -webkit-mask-size: cover;
      background-color: ${colors.background};
    }
  `}
`;

export const StyledRadio = styled.input`
  appearance: none;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 2px solid ${colors.dark};
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 2px;
  }
  &:checked::after {
    background-color: ${colors.dark};
  }
  &:hover::after {
    background-color: ${colors.light};
  }
  &:focus {
    outline: 2px solid ${colors.primary};
  }
  &:disabled {
    cursor: not-allowed;
    border-color: ${colors.lightTransparent};
    background-color: ${colors.background};
    &::after {
      background-color: ${colors.background};
    }
    &:checked::after {
      background-color: ${colors.lightTransparent};
    }
  }
`;
