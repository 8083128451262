import { styled } from "styled-components";
import { colors } from "../../../style-variables";

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
  padding: 1rem;
  width: 90%;
  max-width: 40rem;
  /* min-width: 30rem; */
  background-color: ${colors.background};
`;

export const FileInput = styled.input`
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
`;

export const FileInputLabel = styled.label`
  width: 15rem;
  height: 3rem;
  padding: 0 2rem;
  font-size: 1rem;
  background-color: ${colors.primary};
  color: ${colors.background};
  text-transform: uppercase;
  font-family: inherit;
  font-weight: bolder;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover,
  &:focus {
    background-color: ${colors.background};
    color: ${colors.primary};
    border: 2px solid ${colors.primary};
  }
`;

export const ProgressBar = styled.div`
  progress {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 2rem;
    border-radius: 0.5rem;
    box-shadow: -2px 2px 5px #00000040 inset;
  }
  progress::-moz-progress-bar {
    background: ${colors.light};
    border-radius: 0.5rem;
  }
  progress::-webkit-progress-bar {
    background: ${colors.background};
    border-radius: 0.5rem;
    box-shadow: -2px 2px 5px #00000040 inset;
  }
  progress::-webkit-progress-value {
    background: ${colors.light};
    border-radius: 0.5rem;
  }
  label {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: ${colors.light};
  }
`;

export const ProgressInfo = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${colors.light};
`;
