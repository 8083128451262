import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from "firebase/auth";

import { auth } from "../../../firebase/config";
import { RootState } from "../../../redux/store";
import Spinner from "../../UI/spinner/spinner.component";
import Card from "../../UI/card/card.component";
import ErrorMessage from "../../UI/error-message/error-message.component";

type ProtectedRouteProps = {
  children: React.ReactElement;
};

const AdminRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const [user] = useAuthState(auth);
  const { userLoading, userError, currentUser } = useSelector(
    (state: RootState) => state.user
  );

  //just additional protection of Admin route, because why not :)
  useEffect(() => {
    const checkIfIsBoss = async (user: User) => {
      return (await user.getIdTokenResult()).claims.isBoss;
    };
    if (user) {
      checkIfIsBoss(user).then((isBoss) => {
        if (!isBoss || isBoss !== true) {
          <Navigate to="/404" replace />;
        }
      });
    } else {
      <Navigate to="/404" replace />;
    }
  }, [user]);

  if (userLoading) {
    return <Spinner />;
  }

  if (!userLoading && !currentUser) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" replace />;
  }

  if (!userLoading && currentUser && !currentUser.isBoss) {
    // logged in, but not the Boss so redirect to 404 page
    return <Navigate to="/404" replace />;
  }

  if (!userLoading && userError) {
    return (
      <Card>
        <ErrorMessage slice="user">{userError}</ErrorMessage>
      </Card>
    );
  }

  // authorized so return child components
  return children;
};

export default AdminRoute;
