import { Outlet } from "react-router-dom";

import Header from "../../components/navigation/header/header.component";
import Footer from "../../components/navigation/footer/footer.component";
import {
  NavigationLayoutContainer,
  OutletContainer,
} from "./navigation.styles";

const Navigation = () => {
  return (
    <NavigationLayoutContainer>
      <Header />
      <OutletContainer>
        <Outlet />
      </OutletContainer>
      <Footer />
    </NavigationLayoutContainer>
  );
};

export default Navigation;
