import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

import { colors } from "../../../style-variables";

type NavContainerProps = {
  $isMobileNavOpen: boolean;
};

export const NavContainer = styled.header<NavContainerProps>`
  width: 100%;
  height: ${(props) => (props.$isMobileNavOpen ? "100vh" : "5rem")};
  background: ${colors.dark};
  display: flex;
  flex-direction: column;

  @media (min-width: 48rem) {
    height: 5rem;
  }
`;

export const InnerNavContainer = styled.nav`
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoContainer = styled(Link)`
  height: 100%;
  padding: 1rem;
  margin-right: 5%;

  img {
    max-width: 10rem;
    height: auto;
  }
`;

export const NavLinks = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5%;
`;

export const NavigationLink = styled(NavLink)`
  padding: 10px 15px;
  margin-left: 1rem;
  color: ${colors.lightTextLow};
  font-size: x-large;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    color: ${colors.lightTextHigh};
  }
  &.active {
    color: ${colors.lightTextHigh};
    /* border: 2px solid white; */
    box-shadow: 6px 6px 12px ${colors.light};
    border-radius: 0.5rem;
  }

  @media (max-width: 48rem) {
    display: none;
  }
`;

export const BurgerMenuButton = styled.button`
  width: 4rem;
  height: 5rem;
  background: none;
  border: none;
  color: ${colors.lightTextHigh};
  font-size: 4rem;
  cursor: pointer;

  @media (min-width: 48rem) {
    display: none;
  }
`;

export const BurgerMenuButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  aligm-items: center;

  @media (min-width: 48rem) {
    display: none;
  }
`;

export const MobileNavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;

  @media (min-width: 48rem) {
    display: none;
  }
`;

export const MobileNavgationLink = styled(NavLink)`
  padding: 10px 15px;
  margin-bottom: 1.5rem;
  color: ${colors.lightTextLow};
  font-size: x-large;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${colors.lightTextHigh};
  }
  &.active {
    color: ${colors.lightTextHigh};
    /* border: 2px solid white; */
    box-shadow: 6px 6px 12px ${colors.light};
    border-radius: 0.5rem;
  }

  @media (min-width: 48rem) {
    display: none;
  }
`;
