import { takeLatest, all, call, put } from "typed-redux-saga/macro";
// import { PayloadAction } from "@reduxjs/toolkit";

// import {
//   addPHVehicle,
//   addPHVehicleDoc,
//   deletePHVehicle,
//   getUserPHVehicles,
//   selectPHVehicle,
// } from "../../firebase/vehicles";
import {
    getDriversToApproveFailed,
    getDriversToApproveSuccess,
} from "./userList.slice";
import { getDriversForApproval } from "../../../firebase/admin";
// import { AddPHVehicleDocType, ModifyVehicleType } from "./vehicles.types";

//generator functions
function* getUsersForDriverAproval() {
  try {
    const driversForApprovalList = yield* call(getDriversForApproval);
    if (driversForApprovalList) {       
      yield* put(getDriversToApproveSuccess(driversForApprovalList));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield* put(getDriversToApproveFailed(error.message));
    } else {
      yield* put(getDriversToApproveFailed("Error Fetching Vehicles..."));
    }
  }
}


//listeners
function* onGetDriversToApproveStart() {
  yield* takeLatest("userList/getDriversToApproveStart", getUsersForDriverAproval);
}

export function* userListSagas() {
  yield* all([
    call(onGetDriversToApproveStart),
  ]);
}
