import { takeLatest, all, call, put } from "typed-redux-saga/macro";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  addPHVehicle,
  addPHVehicleDoc,
  deletePHVehicle,
  selectPHVehicle,
} from "../../firebase/vehicles";
import {
  addPHVehicleDocumentFailed,
  addPHVehicleDocumentSuccess,
  addVehicleFailed,
  addVehicleSuccess,
  deleteVehicleFailed,
  deleteVehicleSuccess,
  selectVehicleFailed,
  selectVehicleSuccess,
} from "./vehicles.slice";
import { AddPHVehicleDocType, ModifyVehicleType } from "./vehicles.types";

function* addVehicle({
  payload: { userId, numberPlate },
}: PayloadAction<ModifyVehicleType>) {
  try {
    yield* call(addPHVehicle, userId, numberPlate);
    yield* put(addVehicleSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield* put(addVehicleFailed(error.message));
    } else {
      yield* put(addVehicleFailed("Registering New Vehicle Failed..."));
    }
  }
}

function* selectVehicle({
  payload: { userId, numberPlate },
}: PayloadAction<ModifyVehicleType>) {
  try {
    yield* call(selectPHVehicle, userId, numberPlate);
    yield* put(selectVehicleSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield* put(selectVehicleFailed(error.message));
    } else {
      yield* put(selectVehicleFailed("Selecting Vehicle Failed..."));
    }
  }
}

function* deleteVehicle({
  payload: { userId, numberPlate },
}: PayloadAction<ModifyVehicleType>) {
  try {
    yield* call(deletePHVehicle, userId, numberPlate);
    yield* put(deleteVehicleSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield* put(deleteVehicleFailed(error.message));
    } else {
      yield* put(deleteVehicleFailed("Deleting Vehicle Failed..."));
    }
  }
}

function* addPHVehicleDocument({
  payload: { userId, numberPlate, docType, downloadUrl },
}: PayloadAction<AddPHVehicleDocType>) {
  try {
    yield* call(addPHVehicleDoc, userId, numberPlate, docType, downloadUrl);
    yield* put(addPHVehicleDocumentSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield* put(addPHVehicleDocumentFailed(error.message));
    } else {
      yield* put(
        addPHVehicleDocumentFailed("Adding Vehicle Document Failed...")
      );
    }
  }
}

//listeners
// function* onGetUserVehiclesStart() {
//   yield* takeLatest("vehicles/getUserVehiclesStart", getUserVehicles);
// }

function* onAddVehicleStart() {
  yield* takeLatest("vehicles/addVehicleStart", addVehicle);
}

function* onSelectVehicleStart() {
  yield* takeLatest("vehicles/selectVehicleStart", selectVehicle);
}

function* onDeleteVehicleStart() {
  yield* takeLatest("vehicles/deleteVehicleStart", deleteVehicle);
}

function* onVehicleDocumentStart() {
  yield* takeLatest("vehicles/addPHVehicleDocumentStart", addPHVehicleDocument);
}

export function* vehiclesSagas() {
  yield* all([
    // call(onGetUserVehiclesStart),
    call(onAddVehicleStart),
    call(onSelectVehicleStart),
    call(onDeleteVehicleStart),
    call(onVehicleDocumentStart),
  ]);
}
