import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { BookingType } from "../bookings/bookings.types";

// Define a type for the slice state
interface JobOffersState {
  jobOffers: BookingType[] | null;
  jobsOffersLoading: boolean;
  jobsOffersError: string | null;
}

const INITIAL_STATE: JobOffersState = {
  jobOffers: null,
  jobsOffersLoading: true,
  jobsOffersError: null,
};

export const jobOffersSlice = createSlice({
  name: "jobOffers",
  initialState: INITIAL_STATE,
  reducers: {
    //update job offers real time
    updateJobOffersRealTime(state, action: PayloadAction<BookingType[]>) {
      state.jobsOffersLoading = false;
      state.jobOffers = action.payload;
    },
    errorJobOffersRealTime(state, action: PayloadAction<string>) {
      state.jobsOffersLoading = false;
      state.jobsOffersError = action.payload;
    },
    //------------------
    //reset job offers list
    clearJobOffers(state) {
      state.jobOffers = null;
      state.jobsOffersError = null;
      state.jobsOffersLoading = false;
    },
    //clear error. We don't export this as other slices have the same name
    //Components use createAction(`${slice}/clearError`) to fire it
    clearError(state) {
      state.jobsOffersError = null;
    },
  },
});

export const {
 updateJobOffersRealTime,
 errorJobOffersRealTime,
 clearJobOffers
} = jobOffersSlice.actions;

export const jobOffersReducer = jobOffersSlice.reducer;
