import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  ApproveVehicleDocType,
  ChangeVehicleStatusType,
  ModifyVehicleType,
  PHVehicleType,
  RejectVehicleDocType,
  setVehicleType,
} from "../../vehicles/vehicles.types";

// Define a type for the slice state
interface VehicleState {
  selectedVehicle: PHVehicleType | null;
  selectedVehicleLoading: boolean;
  selectedVehicleError: string | null;
}

const INITIAL_STATE: VehicleState = {
  selectedVehicle: null,
  selectedVehicleLoading: true,
  selectedVehicleError: null,
};

export const selectedVehicleSlice = createSlice({
  name: "selectedVehicle",
  initialState: INITIAL_STATE,
  reducers: {
    //Get Vehicle by numberPlate
    getVehicleByNumberPlateStart(
      state,
      action: PayloadAction<ModifyVehicleType>
    ) {
      state.selectedVehicleLoading = true;
    },
    getVehicleByNumberPlateSuccess(
      state,
      action: PayloadAction<PHVehicleType>
    ) {
      state.selectedVehicleLoading = false;
      state.selectedVehicleError = null;
      state.selectedVehicle = action.payload;
    },
    getVehicleByNumberPlateFailed(state, action: PayloadAction<string>) {
      state.selectedVehicleLoading = false;
      state.selectedVehicle = null;
      state.selectedVehicleError = action.payload;
    },
    //Approve vehicle paperwork
    approveVehiclePaperworkStart(
      state,
      action: PayloadAction<ApproveVehicleDocType>
    ) {
      state.selectedVehicleLoading = true;
    },
    approveVehiclePaperworkSuccess(
      state,
      action: PayloadAction<PHVehicleType>
    ) {
      state.selectedVehicleLoading = false;
      state.selectedVehicleError = null;
      state.selectedVehicle = action.payload;
    },
    approveVehiclePaperworkFailed(state, action: PayloadAction<string>) {
      state.selectedVehicleLoading = false;
      state.selectedVehicleError = action.payload;
    },
    //Reject Vehicle paperwork
    rejectVehiclePaperworkStart(
      state,
      action: PayloadAction<RejectVehicleDocType>
    ) {
      state.selectedVehicleLoading = true;
    },
    rejectVehiclePaperworkSuccess(state, action: PayloadAction<PHVehicleType>) {
      state.selectedVehicleLoading = false;
      state.selectedVehicleError = null;
      state.selectedVehicle = action.payload;
    },
    rejectVehiclePaperworkFailed(state, action: PayloadAction<string>) {
      state.selectedVehicleLoading = false;
      state.selectedVehicleError = action.payload;
    },
    //Set Vehicle make/model/color/plate/types
    setVehicleStart(state, action: PayloadAction<setVehicleType>) {
      state.selectedVehicleLoading = true;
    },
    setVehicleSuccess(state, action: PayloadAction<PHVehicleType>) {
      state.selectedVehicleLoading = false;
      state.selectedVehicleError = null;
      state.selectedVehicle = action.payload;
    },
    setVehicleFailed(state, action: PayloadAction<string>) {
      state.selectedVehicleLoading = false;
      state.selectedVehicleError = action.payload;
    },
    //Reset vehicle "waiting for approval" status
    resetVehicleApprovalWaitingStart(
      state,
      action: PayloadAction<ModifyVehicleType>
    ) {
      state.selectedVehicleLoading = true;
    },
    resetVehicleApprovalWaitingSuccess(
      state,
      action: PayloadAction<PHVehicleType>
    ) {
      state.selectedVehicleLoading = false;
      state.selectedVehicleError = null;
      state.selectedVehicle = action.payload;
    },
    resetVehicleApprovalWaitingFailed(state, action: PayloadAction<string>) {
      state.selectedVehicleLoading = false;
      state.selectedVehicleError = action.payload;
    },
    //Change vehicle status - Activate or Suspend
    changeVehicleStatusStart(
      state,
      action: PayloadAction<ChangeVehicleStatusType>
    ) {
      state.selectedVehicleLoading = true;
    },
    changeVehicleStatusSuccess(state, action: PayloadAction<PHVehicleType>) {
      state.selectedVehicleLoading = false;
      state.selectedVehicleError = null;
      state.selectedVehicle = action.payload;
    },
    changeVehicleStatusFailed(state, action: PayloadAction<string>) {
      state.selectedVehicleLoading = false;
      state.selectedVehicleError = action.payload;
    },
    clearSelectedVehicle(state) {
      state.selectedVehicle = null;
      state.selectedVehicleLoading = false;
      state.selectedVehicleError = null;
    },

    //clear error. We don't export this as other slices have the same name
    //Components use createAction(`${slice}/clearError`) to fire it
    clearError(state) {
      state.selectedVehicleError = null;
    },
  },
});

export const {
  getVehicleByNumberPlateFailed,
  getVehicleByNumberPlateStart,
  getVehicleByNumberPlateSuccess,
  approveVehiclePaperworkFailed,
  approveVehiclePaperworkStart,
  approveVehiclePaperworkSuccess,
  rejectVehiclePaperworkFailed,
  rejectVehiclePaperworkStart,
  rejectVehiclePaperworkSuccess,
  setVehicleFailed,
  setVehicleStart,
  setVehicleSuccess,
  resetVehicleApprovalWaitingFailed,
  resetVehicleApprovalWaitingStart,
  resetVehicleApprovalWaitingSuccess,
  changeVehicleStatusFailed,
  changeVehicleStatusStart,
  changeVehicleStatusSuccess,
  clearSelectedVehicle,
} = selectedVehicleSlice.actions;

export const selectedVehicleReducer = selectedVehicleSlice.reducer;
