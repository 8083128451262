import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaDog } from "react-icons/fa";

import {
  AmberHeader,
  BackButtonContainer,
  ClickableArea,
  GreenHeader,
  GreenP,
  RedHeader,
  SpaceAroundArea,
  TextDivider,
} from "../../../global.styles";
import { RootState } from "../../../redux/store";
import { isBookingType } from "../../../redux/bookings/bookings.types";
import Button from "../../UI/button/button.component";
import Addresses from "../../bookings/addresses.component";
import { defineVehicleType } from "../../../util-functions";
import Card from "../../UI/card/card.component";
import Spinner from "../../UI/spinner/spinner.component";
import BookingStatus from "../../bookings/booking-status/booking-status.component";

const AcceptedJobDetails = () => {
  const navigate = useNavigate();

  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  const { jobsLoading, jobsError, jobs } = useSelector(
    (state: RootState) => state.jobs
  );

  const [actionLoading, setActionLoading] = useState(false);
  const [actionError, setActionError] = useState("");
  const [jobCompleted, setJobCompleted] = useState(false);

  //get state that was passed to this route
  const { state } = useLocation();

  if (!state || !approvedDriver || !jobs) {
    return (
      <Card>
        <RedHeader>Wrong Parameters...</RedHeader>
        <Button onClick={() => navigate("/drivers/driver-panel")}>Try Again</Button>
      </Card>
    );
  }

  const jobId = state.jobId;
  const job = jobs.find((job) => job.id === jobId);

  if (!jobCompleted && !isBookingType(job)) {
    return (
      <Card>
        <RedHeader>Wrong Parameters...</RedHeader>
        <Button onClick={() => navigate("/drivers/driver-panel")}>Try Again</Button>
      </Card>
    );
  }

  const cancelActionHandler = () => {
    if (jobId) {
      setActionLoading(true);
      console.log("Cancel action...");
      setActionLoading(false);
    } else {
      navigate("/drivers/driver-panel");
    }
  };

  const forwardActionHandler = () => {
    if (jobId) {
      setActionLoading(true);
      console.log("NEXT ACTION!");
      setActionLoading(false);
      setJobCompleted(true);
    } else {
      navigate("/drivers/driver-panel");
    }
    if (false) {
      setActionError("NO ERROR YET");
    }
  };

  return (
    <Card>
      {(approvedDriverLoading || actionLoading || jobsLoading) && <Spinner />}
      {approvedDriverError && !approvedDriverLoading && (
        <RedHeader>{approvedDriverError}</RedHeader>
      )}
      {actionError && !actionLoading && <RedHeader>{actionError}</RedHeader>}
      {jobsError && !jobsLoading && <RedHeader>{jobsError}</RedHeader>}
      {jobCompleted && !actionLoading && (
        <>
          <GreenHeader>Job completed!</GreenHeader>
          <BackButtonContainer>
            <Button
              buttonType="green"
              onClick={() => navigate("/drivers/driver-panel")}
            >
              OK
            </Button>
          </BackButtonContainer>
        </>
      )}
      {!jobCompleted && !actionLoading && !!job && (
        <>
          <GreenP>Your job on {job.date} at</GreenP>
          <GreenHeader>{job.time}</GreenHeader>
          <TextDivider>
            <GreenP> STATUS </GreenP>
          </TextDivider>
          <SpaceAroundArea>
            <ClickableArea onClick={cancelActionHandler}>
              <AmberHeader>Cancel Job</AmberHeader>
            </ClickableArea>
            <BookingStatus booking={job} />
            <ClickableArea onClick={forwardActionHandler}>
              <GreenHeader>Arrived? &rarr;</GreenHeader>
            </ClickableArea>
          </SpaceAroundArea>
          <TextDivider>
            <GreenP> ADDRESSES </GreenP>
          </TextDivider>
          <Addresses booking={job} />
          <TextDivider>
            <GreenP> DETAILS </GreenP>
          </TextDivider>
          <GreenP>Passenger Name</GreenP>
          <p>{job.passengerName}</p>
          <br />
          <GreenP>Passenger Phone Number</GreenP>
          <p>{job.passengerPhone}</p>
          <br />
          <GreenP>Vehicle Type:</GreenP>
          <p>{defineVehicleType(job.vehicleType)}</p>
          {job.hasPet && (
            <>
              <br />
              <GreenP>
                <FaDog /> Booking includes pet.
              </GreenP>
            </>
          )}
          {job.driverNote && (
            <>
              <br />
              <GreenP>Note to the Driver</GreenP>
              <p>{job.driverNote}</p>
            </>
          )}
          <TextDivider>
            <GreenP> PRICE </GreenP>
          </TextDivider>
          <GreenHeader> £{job.quotedPrice}*</GreenHeader>
          <GreenP>
            * Amount to pay to the Driver. Any parking and/or toll fees and/or
            waitng time charges to be added. Please see{" "}
            <Link to="/terms">Terms and Conditions</Link>
          </GreenP>
        </>
      )}
    </Card>
  );
};

export default AcceptedJobDetails;
