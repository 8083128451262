import { styled } from "styled-components";
import { Link } from "react-router-dom";

import { colors } from "../../../style-variables";

export const FooterContainer = styled.footer`
  margin-top: auto;
  width: 100%;
  min-height: 6rem;
  padding: 2rem;
  background: ${colors.dark};
  display: flex;
  color: ${colors.lightTextLow};
  text-decoration: none;
  align-items: center;
  justify-content: center;

  p {
    padding: 0.5rem 1.5rem;
    color: ${colors.lightTextLow};
    font-size: medium;
  }

  @media (max-width: 48rem) {
    flex-direction: column;
  }
`;

export const FooterLink = styled(Link)`
  padding: 0.5rem 1.5rem;
  color: ${colors.lightTextLow};
  font-size: medium;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${colors.lightTextHigh};
  }
`;
