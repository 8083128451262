import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { FaUpload } from "react-icons/fa";

import FirebaseStorageService from "../../../firebase/storageService";
import Button from "../button/button.component";
import { BackButtonContainer, RedP } from "../../../global.styles";
import {
  FileInput,
  FileInputLabel,
  ProgressBar,
  ProgressInfo,
  UploadContainer,
} from "./file-upload-progress.styles";

type FileUploadProgressProps = {
  basePath: string;
  navigateAfterUpload: string;
  handleUploadFinish: (downloadUrl: string) => void;
  pending: boolean;
};

const FileUploadProgress: FC<FileUploadProgressProps> = ({
  basePath,
  navigateAfterUpload,
  handleUploadFinish,
  pending,
}) => {
  const navigate = useNavigate();

  const [uploadProgress, setUploadProgress] = useState(-1);
  const [fileUrl, setFileUrl] = useState("");
  const [uploadError, setuploadError] = useState("");

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (pending) {
      setuploadError("");
    }
  }, [pending]);

  const handleFileChanged = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length !== 0) {
      const file = files[0];

      //validate file before upload
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/webp",
        "application/pdf",
      ];
      const maxSizeInBytes = 10485760;
      if (!allowedFileTypes.includes(file.type) || file.size > maxSizeInBytes) {
        setuploadError("We accept only images and PDF files, not larger than 10Mb");
        return;
      }

      const generatedFileId = uuidv4();
      try {
        const downloadUrl = await FirebaseStorageService.uploadFile(
          file,
          `${basePath}/${generatedFileId}`,
          setUploadProgress
        );
        //send downloadUrl to database
        if (downloadUrl) {
          setFileUrl(downloadUrl);
          handleUploadFinish(downloadUrl);
        }
      } catch (error) {
        if (error instanceof Error) {
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setuploadError(
            "We accept only images and PDF files, not larger than 10Mb"
          );
          return;
        } else {
          setuploadError("File select failed. Please try again");
          return;
        }
      }
    } else {
      setuploadError("File select failed. Please try again");
      return;
    }
  };

  return (
    <UploadContainer>
      {uploadError && <RedP>{uploadError}</RedP>}
      <FileInput
        type="file"
        id="fileInput"
        accept="application/pdf,image/*"
        onChange={handleFileChanged}
        ref={fileInputRef}
        hidden={uploadProgress > -1 || !!fileUrl || pending}
      />
      {!fileUrl && uploadProgress < 0 && !pending && (
        <FileInputLabel htmlFor="fileInput">
          <div>
            <FaUpload size={24} />
          </div>
          <div>Select a File</div>
        </FileInputLabel>
      )}
      {!fileUrl && uploadProgress > -1 && (
        <ProgressBar>
          <label htmlFor="file">Upload Progress:</label>
          <br />
          <progress id="file" value={uploadProgress} max="100">
            {uploadProgress}%
          </progress>
          <ProgressInfo>{uploadProgress}%</ProgressInfo>
        </ProgressBar>
      )}
      {(fileUrl || pending) && (
        <div className="image-preview">
          <p>
            Your document has been uploaded successfully! It may take us up to
            72 hours to review it, but we'll do our best to be quicker than
            that.
          </p>
          <BackButtonContainer>
            <Button
              buttonType="green"
              onClick={() => navigate(navigateAfterUpload)}
            >
              Ok
            </Button>
          </BackButtonContainer>
        </div>
      )}
    </UploadContainer>
  );
};

export default FileUploadProgress;
