import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FaEdit, FaPhone } from "react-icons/fa";

import Card from "../UI/card/card.component";
import Button from "../UI/button/button.component";
// import { signOutUser } from "../../firebase/user";
import { RootState } from "../../redux/store";
import Spinner from "../UI/spinner/spinner.component";
import {
  SpreadButtonContainer,
  GreenHeader,
  RedHeader,
  ClickableArea,
} from "../../global.styles";
import UpdateNameForm from "./update-name/update-name.component";
import UpdatePhoneForm from "./update-phone/update-phone.cpmponent";
import Modal from "../UI/modal/modal.component";
import { signOutStart } from "../../redux/user/user.slice";

const UserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userLoading, currentUser, userError } = useSelector(
    (state: RootState) => state.user
  );

  //modal
  const [openModal, setOpenModal] = useState(false);

  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);

  //opening modal and firing action if confirmed
  const logoutHandler = () => {
    setOpenModal(true);
  };
  const logoutConfirm = () => {
    // signOutUser();
    dispatch(signOutStart());
    navigate("/login", { replace: true });
  };

  const showNameEditHandler = () => {
    setIsEditingName(true);
  };
  const cancelEditNameHandler = () => {
    setIsEditingName(false);
  };

  const showPhoneEditHandler = () => {
    setIsEditingPhone(true);
  };
  const cancelEditPhoneHandler = () => {
    setIsEditingPhone(false);
  };

  return (
    <Card>
      {openModal && (
        <Modal
          title="Log Out"
          body="Log out of this device? Are you sure?"
          confirmButtonText="Log Out"
          setOpenModal={setOpenModal}
          onModalConfirm={logoutConfirm}
        />
      )}

      {userLoading && <Spinner />}
      {userError && <RedHeader>{userError}</RedHeader>}

      <SpreadButtonContainer>
        <Link to="/">
          <h3>&larr; Home Page</h3>
        </Link>
        <Button buttonType="inverted" onClick={logoutHandler}>
          Log Out
        </Button>
      </SpreadButtonContainer>

      {currentUser && (!currentUser.name || isEditingName) && (
        <UpdateNameForm
          userId={currentUser.id}
          currentName={currentUser.name ? currentUser.name : ""}
          onCancelEdit={cancelEditNameHandler}
        />
      )}
      {currentUser && currentUser.name && (
        <>
          <ClickableArea onClick={showNameEditHandler}>
            <GreenHeader>
              {currentUser.name} <FaEdit />
            </GreenHeader>
          </ClickableArea>

          {isEditingPhone && (
            <UpdatePhoneForm
              userId={currentUser.id}
              currentPhoneNumber={
                currentUser.phoneNumber ? currentUser.phoneNumber : ""
              }
              onCancelEdit={cancelEditPhoneHandler}
            />
          )}
          <ClickableArea onClick={showPhoneEditHandler}>
            <GreenHeader>
              {currentUser.phoneNumber ? (
                <span>
                  {currentUser.phoneNumber} <FaEdit />
                </span>
              ) : (
                <span>
                  Add a phone number (optional) <FaPhone />
                </span>
              )}
            </GreenHeader>
          </ClickableArea>
        </>
      )}
    </Card>
  );
};

export default UserProfile;
