import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

import Card from "../../UI/card/card.component";
import InnerCard from "../../UI/inner-card/inner-card.component";
import {
  AmberP,
  BackButtonContainer,
  GreenHeader,
  GreenP,
  RedP,
} from "../../../global.styles";
import { RootState } from "../../../redux/store";
import Spinner from "../../UI/spinner/spinner.component";
import AddVehicleForm from "../add-vehicle-form/add-vehicle-form.components";
import { ButtonContainer, GreenButton } from "../../UI/button/button.styles";
import Button from "../../UI/button/button.component";
import ErrorMessage from "../../UI/error-message/error-message.component";
import {
  deleteVehicleStart,
  selectVehicleStart,
} from "../../../redux/vehicles/vehicles.slice";
import Modal from "../../UI/modal/modal.component";

const required = <RedP>Required</RedP>;
const optional = <AmberP>Optional</AmberP>;
const pending = <AmberP>Pending...</AmberP>;
const approved = <GreenP>Approved</GreenP>;
const expiresSoon = <AmberP>Expires soon</AmberP>;
// const updated = <GreenP>Updated</GreenP>;

const VehicleList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //states for 2 modals and selectedNumberPlate
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedNumberPlate, setSelectedNumberPlate] = useState("");

  const { vehiclesLoading, vehiclesError, vehicles } = useSelector(
    (state: RootState) => state.vehicles
  );

  const { currentUser } = useSelector((state: RootState) => state.user);
  const userId = currentUser!.id;

  //check if user still has option to add another vehicle
  const allowedToAddVehicle =
    !currentUser!.driversData ||
    (currentUser!.driversData && !currentUser!.driversData.amountOfVehicles) ||
    (currentUser!.driversData.amountOfVehicles &&
      currentUser!.driversData.amountOfVehicles < 10);

  //states to show/hide updating and uploading drivers data
  const [isAddingVehicle, setIsAddingVehicle] = useState(false);
  const showAddVehicleHandler = () => {
    setIsAddingVehicle(true);
  };
  const cancelAddVehicleHandler = () => {
    setIsAddingVehicle(false);
  };

  //if user has not selected licensing authority, redirect to drivers intro
  const authoritySelected = currentUser?.driversData?.licensingAuthority;
  useEffect(() => {
    if (!authoritySelected) {
      navigate("/drivers", { replace: true });
    }
  }, [authoritySelected, navigate]);

  //opening modals and firing action if confirmed
  const selectVehicleHandler = (numberPlate: string) => {
    setSelectedNumberPlate(numberPlate);
    setSelectModalOpen(true);
  };
  const selectConfirm = () => {
    dispatch(selectVehicleStart({ userId, numberPlate: selectedNumberPlate }));
  };
  const deleteVehicleHandler = (numberPlate: string) => {
    setSelectedNumberPlate(numberPlate);
    setDeleteModalOpen(true);
  };
  const deleteConfirm = () => {
    dispatch(deleteVehicleStart({ userId, numberPlate: selectedNumberPlate }));
  };

  return (
    <>
      {selectModalOpen && (
        <Modal
          title="Select Vehicle"
          body={`Select ${selectedNumberPlate} as your current vehicle?`}
          confirmButtonText="Select"
          setOpenModal={setSelectModalOpen}
          onModalConfirm={selectConfirm}
        />
      )}
      {deleteModalOpen && (
        <Modal
          title="Delete Vehicle"
          body={`Permanently delete vehicle ${selectedNumberPlate}? Are you sure?`}
          confirmButtonText="Delete"
          setOpenModal={setDeleteModalOpen}
          onModalConfirm={deleteConfirm}
        />
      )}

      {vehiclesLoading && <Spinner />}
      {vehiclesError && (
        <ErrorMessage slice="vehicles">{vehiclesError}</ErrorMessage>
      )}
      {vehicles &&
        vehicles.length !== 0 &&
        vehicles.map((vehicle) => (
          <Card key={vehicle.numberPlate}>
            <GreenHeader>{vehicle.numberPlate}</GreenHeader>
            <GreenP>
              {vehicle.color} {vehicle.make} {vehicle.model}
            </GreenP>
            <ButtonContainer>
              {vehicle.selected ? (
                <GreenHeader>
                  <FaCheck size={32} />
                  Selected
                </GreenHeader>
              ) : (
                <Button
                  onClick={selectVehicleHandler.bind(null, vehicle.numberPlate)}
                >
                  Select
                </Button>
              )}
              <Button
                buttonType="inverted"
                onClick={deleteVehicleHandler.bind(null, vehicle.numberPlate)}
              >
                Delete
              </Button>
            </ButtonContainer>
            <InnerCard
              onClick={() => {
                navigate(
                  `/drivers/upload-doc/PHVehicleLicence/${vehicle.numberPlate}`
                );
              }}
            >
              <h3>PH Vehicle licence</h3>
              <div>
                {vehicle!.PHVehicleLicence?.pendingUrl ? (
                  pending
                ) : vehicle!.PHVehicleLicence?.expiresSoon &&
                  vehicle!.PHVehicleLicence?.approved ? (
                  expiresSoon
                ) : vehicle!.PHVehicleLicence?.approved ? (
                  approved
                ) : vehicle.PHVehicleLicence?.reasonOfDecline ? (
                  <RedP>{vehicle.PHVehicleLicence.reasonOfDecline}</RedP>
                ) : (
                  required
                )}
              </div>
            </InnerCard>

            <InnerCard
              onClick={() => {
                navigate(`/drivers/upload-doc/logBook/${vehicle.numberPlate}`);
              }}
            >
              <h3>V5C Logbook</h3>
              {vehicle!.logBook?.pendingUrl ? (
                pending
              ) : vehicle!.logBook?.expiresSoon &&
                vehicle!.logBook?.approved ? (
                expiresSoon
              ) : vehicle!.logBook?.approved ? (
                approved
              ) : vehicle.logBook?.reasonOfDecline ? (
                <RedP>{vehicle.logBook.reasonOfDecline}</RedP>
              ) : (
                required
              )}
            </InnerCard>

            <InnerCard
              onClick={() => {
                navigate(
                  `/drivers/upload-doc/insuranceCertificate/${vehicle.numberPlate}`
                );
              }}
            >
              <h3>Insurance Certificate</h3>
              {vehicle!.insuranceCertificate?.pendingUrl ? (
                pending
              ) : vehicle!.insuranceCertificate?.expiresSoon &&
                vehicle!.insuranceCertificate?.approved ? (
                expiresSoon
              ) : vehicle!.insuranceCertificate?.approved ? (
                approved
              ) : vehicle.insuranceCertificate?.reasonOfDecline ? (
                <RedP>{vehicle.insuranceCertificate.reasonOfDecline}</RedP>
              ) : (
                required
              )}
            </InnerCard>

            <InnerCard
              onClick={() => {
                navigate(
                  `/drivers/upload-doc/MOTCertificate/${vehicle.numberPlate}`
                );
              }}
            >
              <div>
                <h3>MOT Certificate</h3>
                <GreenP>If the car is over 3 years old</GreenP>
              </div>
              {vehicle!.MOTCertificate?.pendingUrl ? (
                pending
              ) : vehicle!.MOTCertificate?.expiresSoon &&
                vehicle!.MOTCertificate?.approved ? (
                expiresSoon
              ) : vehicle!.MOTCertificate?.approved ? (
                approved
              ) : vehicle.MOTCertificate?.reasonOfDecline ? (
                <RedP>{vehicle.MOTCertificate.reasonOfDecline}</RedP>
              ) : (
                optional
              )}
            </InnerCard>

            <InnerCard
              onClick={() => {
                navigate(
                  `/drivers/upload-doc/hireContract/${vehicle.numberPlate}`
                );
              }}
            >
              <div>
                <h3>Hire Contract</h3>
                <GreenP>If the car is rented</GreenP>
              </div>
              {vehicle!.hireContract?.pendingUrl ? (
                pending
              ) : vehicle!.hireContract?.expiresSoon &&
                vehicle!.hireContract?.approved ? (
                expiresSoon
              ) : vehicle!.hireContract?.approved ? (
                approved
              ) : vehicle.hireContract?.reasonOfDecline ? (
                <RedP>{vehicle.hireContract.reasonOfDecline}</RedP>
              ) : (
                optional
              )}
            </InnerCard>
          </Card>
        ))}

      {allowedToAddVehicle && (
        <Card>
          {isAddingVehicle ? (
            <AddVehicleForm
              userId={currentUser!.id}
              onCancelEdit={cancelAddVehicleHandler}
            />
          ) : (
            <BackButtonContainer>
              <GreenButton onClick={showAddVehicleHandler}>
                Add a New Vehicle
              </GreenButton>
            </BackButtonContainer>
          )}
        </Card>
      )}
    </>
  );
};

export default VehicleList;
