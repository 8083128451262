import { FC } from "react";

import { BookingType } from "../../redux/bookings/bookings.types";
import {
  SpacerHorizontal,
  SpacerVertical,
} from "../UI/spacers/spacers.component";
import { GreenP } from "../../global.styles";

type AddressesProps = {
  booking: BookingType;
};

const Addresses: FC<AddressesProps> = ({ booking }) => {
  //set addresses
  //pick up address
  const pickup = (
    <SpacerHorizontal $paddingRem={0.8}>
      <SpacerVertical $paddingRem={0.8}>
        <GreenP>From:</GreenP>
        <p>
          <b>{booking.pickupAddress.userEntry}</b>
          <br />
          {booking.pickupAddress.googleAddress}
        </p>
      </SpacerVertical>
    </SpacerHorizontal>
  );
  //via points
  const viaPointsArray = booking.destinationAddresses.slice(0, -1);
  const viaPoints = viaPointsArray.map((viaPoint, index) => (
    <SpacerHorizontal key={index} $paddingRem={1.6}>
      <SpacerVertical $paddingRem={0.8}>
        <GreenP>Via:</GreenP>
        <p>
          <b>{viaPoint.userEntry}</b>
          <br />
          {viaPoint.googleAddress}
        </p>
      </SpacerVertical>
    </SpacerHorizontal>
  ));

  //final destination
  const finalDestination = (
    <SpacerHorizontal $paddingRem={0.8}>
      <SpacerVertical $paddingRem={0.8}>
        <GreenP>To:</GreenP>
        <p>
          <b>{booking.destinationAddresses.at(-1)?.userEntry}</b>
          <br />
          {booking.destinationAddresses.at(-1)?.googleAddress}
        </p>
      </SpacerVertical>
    </SpacerHorizontal>
  );
  return (
    <>
      {pickup}
      {viaPoints}
      {finalDestination}
    </>
  );
};

export default Addresses;
