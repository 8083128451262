import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  AddPHVehicleDocType,
  ModifyVehicleType,
  PHVehicleType,
} from "./vehicles.types";

// Define a type for the slice state
interface VehicleState {
  vehicles: PHVehicleType[] | null;
  vehiclesLoading: boolean;
  vehiclesError: string | null;
}

const INITIAL_STATE: VehicleState = {
  vehicles: null,
  vehiclesLoading: true,
  vehiclesError: null,
};

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState: INITIAL_STATE,
  reducers: {
    //update vehicles real time
    updateVehiclesRealTime(state, action: PayloadAction<PHVehicleType[]>) {
      state.vehiclesLoading = false;
      state.vehicles = action.payload;
    },
    errorVehiclesRealTime(state, action: PayloadAction<string>) {
      state.vehiclesLoading = false;
      state.vehiclesError = action.payload;
    },
    //------------------
    //Add PH Vehicle
    addVehicleStart(state, action: PayloadAction<ModifyVehicleType>) {
      state.vehiclesLoading = true;
    },
    addVehicleSuccess(state) {
      state.vehiclesLoading = false;
      state.vehiclesError = null;
    },
    addVehicleFailed(state, action: PayloadAction<string>) {
      state.vehiclesLoading = false;
      state.vehiclesError = action.payload;
    },
    //Select PH Vehicle
    selectVehicleStart(state, action: PayloadAction<ModifyVehicleType>) {
      state.vehiclesLoading = true;
    },
    selectVehicleSuccess(state) {
      state.vehiclesLoading = false;
      state.vehiclesError = null;
    },
    selectVehicleFailed(state, action: PayloadAction<string>) {
      state.vehiclesLoading = false;
      state.vehiclesError = action.payload;
    },
    //Delete PH Vehicle
    deleteVehicleStart(state, action: PayloadAction<ModifyVehicleType>) {
      state.vehiclesLoading = true;
    },
    deleteVehicleSuccess(state) {
      state.vehiclesLoading = false;
      state.vehiclesError = null;
    },
    deleteVehicleFailed(state, action: PayloadAction<string>) {
      state.vehiclesLoading = false;
      state.vehiclesError = action.payload;
    },
    //Vehicle Paperwork
    addPHVehicleDocumentStart(
      state,
      action: PayloadAction<AddPHVehicleDocType>
    ) {
      state.vehiclesLoading = true;
    },
    addPHVehicleDocumentSuccess(state) {
      state.vehiclesLoading = false;
      state.vehiclesError = null;
    },
    addPHVehicleDocumentFailed(state, action: PayloadAction<string>) {
      state.vehiclesLoading = false;
      state.vehiclesError = action.payload;
    },
    //reset vehicles list
    clearVehicles(state) {
      state.vehicles = null;
      state.vehiclesError = null;
      state.vehiclesLoading = false;
    },
    //clear error. We don't export this as other slices have the same name
    //Components use createAction(`${slice}/clearError`) to fire it
    clearError(state) {
      state.vehiclesError = null;
    },
  },
});

export const {
  updateVehiclesRealTime,
  errorVehiclesRealTime,
  addVehicleStart,
  addVehicleSuccess,
  addVehicleFailed,
  selectVehicleStart,
  selectVehicleSuccess,
  selectVehicleFailed,
  deleteVehicleStart,
  deleteVehicleSuccess,
  deleteVehicleFailed,
  addPHVehicleDocumentStart,
  addPHVehicleDocumentSuccess,
  addPHVehicleDocumentFailed,
  clearVehicles,
} = vehiclesSlice.actions;

export const vehiclesReducer = vehiclesSlice.reducer;
