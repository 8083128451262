import styled from "styled-components";

//using $ symbol to prevent console error. $ rules the world :)
type SpacerProps = {
  $paddingRem: number;
};

export const SpacerVertical = styled.div<SpacerProps>`
  padding-top: ${(props) => `${props.$paddingRem}rem;`};
`;

export const SpacerHorizontal = styled.div<SpacerProps>`
  padding-left: ${(props) => `${props.$paddingRem}rem;`};
`;
