import { useNavigate } from "react-router-dom";

import { IoDocumentsOutline, IoListSharp } from "react-icons/io5";
import { FaUsersGear } from "react-icons/fa6";
import Card from "../UI/card/card.component";
import InnerCard from "../UI/inner-card/inner-card.component";

const AdminPanel = () => {
  const navigate = useNavigate();

  return (
    <Card>
      <InnerCard
        onClick={() => {
          navigate("approve-paperwork");
        }}
      >
        <IoDocumentsOutline size={32} />
        <div>
          <h3>Approve Paperwork</h3>
        </div>
      </InnerCard>
      <InnerCard
        onClick={() => {
          navigate("manage-users");
        }}
      >
        <FaUsersGear size={32} />
        <div>
          <h3>Manage Users</h3>
        </div>
      </InnerCard>
      <InnerCard
        onClick={() => {
          navigate("manage-bookings");
        }}
      >
        <IoListSharp size={32} />
        <div>
          <h3>Manage Bookings</h3>
        </div>
      </InnerCard>
    </Card>
  );
};

export default AdminPanel;
