import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  AddDVLACodeType,
  AddDriverDocType,
  AddLicenseAuthorityType,
  CurrentUserType,
  UpdateNameType,
  UpdatePhoneType,
} from "./user.types";

// Define a type for the slice state
interface UserState {
  currentUser: CurrentUserType | null;
  userLoading: boolean;
  userError: string | null;
}

const INITIAL_STATE: UserState = {
  currentUser: null,
  userLoading: true,
  userError: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    //General User
    signOutStart(state) {
      state.userLoading = true;
    },
    signOutSuccess(state) {
      state.userLoading = false;
      state.userError = null;
      state.currentUser = null;
    },
    signOutFailed(state, action: PayloadAction<string>) {
      state.userLoading = false;
      state.userError = action.payload;
    },
    facebookSignInStart(state) {
      state.userLoading = true;
    },
    googleSignInStart(state) {
      state.userLoading = true;
    },
    checkUserSession(state) {
      state.userLoading = true;
    },
    signInSuccess(state, action: PayloadAction<CurrentUserType>) {
      state.userLoading = false;
      state.userError = null;
      state.currentUser = action.payload;
    },
    signInFailed(state, action: PayloadAction<string>) {
      state.userLoading = false;
      state.userError = action.payload;
    },
    //updateUserRealTime
    updateUserRealTime(state, action: PayloadAction<CurrentUserType>) {
      state.userLoading = false;
      state.currentUser = action.payload;
    },
    errorUserRealTime(state, action: PayloadAction<string>) {
      state.userLoading = false;
      state.userError = action.payload;
    },
    //------------------
    updateUserNameStart(state, action: PayloadAction<UpdateNameType>) {
      state.userLoading = true;
    },
    updateUserNameSuccess(state) {
      state.userLoading = false;
      state.userError = null;
    },
    updateUserNameFailed(state, action: PayloadAction<string>) {
      state.userLoading = false;
      state.userError = action.payload;
    },
    updateUserPhoneStart(state, action: PayloadAction<UpdatePhoneType>) {
      state.userLoading = true;
    },
    updateUserPhoneSuccess(state) {
      state.userLoading = false;
      state.userError = null;
    },
    updateUserPhoneFailed(state, action: PayloadAction<string>) {
      state.userLoading = false;
      state.userError = action.payload;
    },
    //Add Licensing Authority
    addLicensingAuthorityStart(
      state,
      action: PayloadAction<AddLicenseAuthorityType>
    ) {
      state.userLoading = true;
    },
    addLicensingAuthoritySuccess(state) {
      state.userLoading = false;
      state.userError = null;
    },
    addLicensingAuthorityFailed(state, action: PayloadAction<string>) {
      state.userLoading = false;
      state.userError = action.payload;
    },
    //Private Hire Driver Documents
    addDriverDocumentStart(state, action: PayloadAction<AddDriverDocType>) {
      state.userLoading = true;
    },
    addDriverDocumentSuccess(state) {
      state.userLoading = false;
      state.userError = null;
    },
    addDriverDocumentFailed(state, action: PayloadAction<string>) {
      state.userLoading = false;
      state.userError = action.payload;
    },
    //DVLA Check Code for Driver
    addDVLACodeStart(state, action: PayloadAction<AddDVLACodeType>) {
      state.userLoading = true;
    },
    addDVLACodeSuccess(state) {
      state.userLoading = false;
      state.userError = null;
    },
    addDVLACodeFailed(state, action: PayloadAction<string>) {
      state.userLoading = false;
      state.userError = action.payload;
    },
    //clear error. We don't export this as other slices have the same name
    //Components use createAction(`${slice}/clearError`) to fire it
    clearError(state) {
      state.userError = null;
    },
  },
});

export const {
  addDriverDocumentFailed,
  addDriverDocumentStart,
  addDriverDocumentSuccess,
  addDVLACodeFailed,
  addDVLACodeStart,
  addDVLACodeSuccess,
  addLicensingAuthorityFailed,
  addLicensingAuthorityStart,
  addLicensingAuthoritySuccess,
  checkUserSession,
  facebookSignInStart,
  googleSignInStart,
  signOutStart,
  signInSuccess,
  signOutSuccess,
  signInFailed,
  signOutFailed,
  updateUserRealTime,
  errorUserRealTime,
  updateUserNameStart,
  updateUserNameSuccess,
  updateUserNameFailed,
  updateUserPhoneStart,
  updateUserPhoneSuccess,
  updateUserPhoneFailed,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
