import { Navigate, Route, Routes } from "react-router-dom";

import AdminPanel from "../../components/admin/admin-panel.component";
import PaperworkListForReview from "../../components/admin/paperwork-list.component";
import UserDetails from "../../components/admin/user-details.component";
import SearchUsers from "../../components/admin/search-users.component";
import SearchBookings from "../../components/admin/search-bookings.component";
import VehicleDetails from "../../components/admin/vehicle-details.component";

const Admin = () => {
  return (
    <Routes>
      <Route index element={<AdminPanel />} />
      <Route path="approve-paperwork" element={<PaperworkListForReview />} />
      <Route path="manage-users" element={<SearchUsers />} />
      <Route path="user-details/:userId" element={<UserDetails />} />
      <Route path="vehicle-details/:userId/:numberPlate" element={<VehicleDetails />} />
      <Route path="manage-bookings" element={<SearchBookings />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default Admin;
