// To parse this data:
//
//   import { Convert, BookingType } from "./file";
//
//   const bookingType = Convert.toBookingsTypes(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.
// !!! It may not work as date is returned as string not as Date assumed by quicktype
// Will NOT WORK as we have included hasPet option after generating these types

// import { GeoPoint } from "firebase/firestore";
import { ApprovedDriverType } from "../user/user.types";
import { isVehicleType, VehicleType } from "../vehicles/vehicles.types";

export type QuoteRequestType = {
  userId: string;
  date: string;
  time: string;
  pickup: string;
  destinations: string[];
  vehicleType: VehicleType;
  hasPet: boolean;
};

export type GeoPoint = {
  latitude: number;
  longitude: number;
};

export type PublicSearchParamsType = {
  driversHash: string;
  area: string;
  date: string;
};

export type BookingType = {
  id?: string; //we don't get id while it's still quote
  vehicleType: VehicleType;
  hasPet: boolean;
  date: string;
  time: string;
  searchDate: string;
  pickupAddress: AddressType;
  pickupTimestamp: string
  dropoffTimestamp: string;
  pickupPostCode: string;
  destinationAddresses: AddressType[];
  distanceInMiles: string;
  durationTime: string;
  quotedPrice: string;
  userId: string;
  createdAt?: number;
  passengerName?: string;
  passengerPhone?: string;
  driverNumber?: string;
  driverNote?: string;
  pickupGeoPoint?: GeoPoint;
  pickupGeoHash?: string;
  pickupHashNeighbours3?: string[];
  pickupHashNeighbours4?: string[];
  pickupHashNeighbours5?: string[];
  note?: string;
  accepted?: boolean;
  acceptedAt?: number;
  acceptedBy?: ApprovedDriverType;
  onRoute?: boolean;
  arrivedToPickup?: boolean;
  arrivedToPickupAt?: number;
  pickedUp?: boolean;
  pickedUpAt?: number;
  completed?: boolean;
  completedAt?: number;
  cancelled?: boolean;
  notCovered?: boolean;
};
//type guard function for BookingType
export function isBookingType(obj: any): obj is BookingType {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "vehicleType" in obj &&
    isVehicleType(obj.vehicleType) && // Check if vehicleType is VehicleType
    "hasPet" in obj &&
    typeof obj.hasPet === "boolean" &&
    "date" in obj &&
    typeof obj.date === "string" &&
    "time" in obj &&
    typeof obj.time === "string" &&
    "searchDate" in obj &&
    typeof obj.searchDate === "string" &&
    "pickupAddress" in obj &&
    isAddressType(obj.pickupAddress) && // Use a helper function for AddressType
    "destinationAddresses" in obj &&
    Array.isArray(obj.destinationAddresses) &&
    obj.destinationAddresses.every(isAddressType) && // Check if destinationAddresses is an array of AddressType
    "distanceInMiles" in obj &&
    typeof obj.distanceInMiles === "string" &&
    "durationTime" in obj &&
    typeof obj.durationTime === "string" &&
    "quotedPrice" in obj &&
    typeof obj.quotedPrice === "string" &&
    "userId" in obj &&
    typeof obj.userId === "string"
  );
}

export type AddressType = {
  userEntry: string;
  googleAddress: string;
};
// Helper function to check if an object is of type AddressType
export function isAddressType(obj: any): obj is AddressType {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "userEntry" in obj &&
    typeof obj.userEntry === "string" &&
    "googleAddress" in obj &&
    typeof obj.googleAddress === "string"
  );
}

export type CreateBookingFromQuoteType = {
  passengerName: string;
  passengerPhone: string;
  driverNote: string;
  driverNumber: string;
};

// Functions generated by quicktype (may not work, see comment at the top)
// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toBookingsTypes(json: string): BookingType {
    return cast(JSON.parse(json), r("BookingsTypes"));
  }

  public static bookingsTypesToJson(value: BookingType): string {
    return JSON.stringify(uncast(value, r("BookingsTypes")), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ""): never {
  const prettyTyp = prettyTypeName(typ);
  const parentText = parent ? ` on ${parent}` : "";
  const keyText = key ? ` for key "${key}"` : "";
  throw Error(
    `Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(
      val
    )}`
  );
}

function prettyTypeName(typ: any): string {
  if (Array.isArray(typ)) {
    if (typ.length === 2 && typ[0] === undefined) {
      return `an optional ${prettyTypeName(typ[1])}`;
    } else {
      return `one of [${typ
        .map((a) => {
          return prettyTypeName(a);
        })
        .join(", ")}]`;
    }
  } else if (typeof typ === "object" && typ.literal !== undefined) {
    return typ.literal;
  } else {
    return typeof typ;
  }
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(
  val: any,
  typ: any,
  getProps: any,
  key: any = "",
  parent: any = ""
): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key, parent);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }
    return invalidValue(typs, val, key, parent);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(
      cases.map((a) => {
        return l(a);
      }),
      val,
      key,
      parent
    );
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue(l("Date"), val, key, parent);
    }
    return d;
  }

  function transformObject(
    props: { [k: string]: any },
    additional: any,
    val: any
  ): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue(l(ref || "object"), val, key, parent);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key)
        ? val[key]
        : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, key, ref);
    });
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key, ref);
      }
    });
    return result;
  }

  if (typ === "any") return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val, key, parent);
  }
  if (typ === false) return invalidValue(typ, val, key, parent);
  let ref: any = undefined;
  while (typeof typ === "object" && typ.ref !== undefined) {
    ref = typ.ref;
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers")
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems")
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty("props")
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val, key, parent);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
  return { literal: typ };
}

function a(typ: any) {
  return { arrayItems: typ };
}

// function u(...typs: any[]) {
//     return { unionMembers: typs };
// }

function o(props: any[], additional: any) {
  return { props, additional };
}

// function m(additional: any) {
//     return { props: [], additional };
// }

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  BookingsTypes: o(
    [
      { json: "vehicleType", js: "vehicleType", typ: "" },
      { json: "date", js: "date", typ: Date },
      { json: "time", js: "time", typ: "" },
      { json: "pickupAddress", js: "pickupAddress", typ: r("Address") },
      {
        json: "destinationAddresses",
        js: "destinationAddresses",
        typ: a(r("Address")),
      },
      { json: "distanceInMiles", js: "distanceInMiles", typ: "" },
      { json: "durationTime", js: "durationTime", typ: "" },
      { json: "quotedPrice", js: "quotedPrice", typ: "" },
      { json: "userId", js: "userId", typ: "" },
    ],
    false
  ),
  Address: o(
    [
      { json: "userEntry", js: "userEntry", typ: "" },
      { json: "googleAddress", js: "googleAddress", typ: "" },
    ],
    false
  ),
};
