// import { Timestamp } from "firebase/firestore";

export type PHVehicleType = {
  numberPlate: string;
  userId: string;
  createdAt: number;
  lastUpdate: number;
  approved?: boolean;
  waitingApproval?: boolean;
  selected?: boolean;
  licencePlate?: string;
  make?: string;
  model?: string;
  color?: string;
  vehicleTypes?: VehicleType[];
  PHVehicleLicence?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
  logBook?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
  MOTCertificate?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
  insuranceCertificate?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
  hireContract?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
};

export type PHVehicleDocTypes = {
  PHVehicleLicence?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
  logBook?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
  MOTCertificate?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
  insuranceCertificate?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
  hireContract?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
};

// export type BookedVehicleType =
//   | "4Seater"
//   | "4SeaterEstate"
//   | "6Seater"
//   | "minibus"
//   | "executive";

export const VehcileTypeArray = [
  "4Seater",
  "4SeaterEstate",
  "6Seater",
  "minibus",
  "executive",
] as const;

export type VehicleType = (typeof VehcileTypeArray)[number];

//type guard functions
export function isVehicleType(value: any): value is VehicleType {
  return VehcileTypeArray.includes(value);
}
export function validateVehicleSelection(selectedVehicleTypes: string[]): boolean {
  return selectedVehicleTypes.every(isVehicleType);
}


export type ModifyVehicleType = {
  userId: string;
  numberPlate: string;
};

export type AddPHVehicleDocType = {
  userId: string;
  numberPlate: string;
  docType: string;
  downloadUrl: string;
};

export type ApproveVehicleDocType = {
  userId: string;
  numberPlate: string;
  docType: string;
  expiryDate: number;
};

export type RejectVehicleDocType = {
  userId: string;
  numberPlate: string;
  docType: string;
  reasonOfDecline: string;
};

export type setVehicleType = {
  userId: string;
  numberPlate: string;
  make: string;
  model: string;
  color: string;
  licencePlate: string;
  vehicleTypes: VehicleType[];
};

export type ChangeVehicleStatusType = {
  userId: string;
  numberPlate: string;
  approved: boolean;
};
