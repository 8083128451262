import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BookingType, CreateBookingFromQuoteType } from "./bookings.types";

// Define a type for the slice state
interface BookingsState {
  bookings: BookingType[] | null;
  bookingCreated: boolean;
  bookingsLoading: boolean;
  bookingsError: string | null;
}

const INITIAL_STATE: BookingsState = {
  bookings: null,
  bookingCreated: false,
  bookingsLoading: false,
  bookingsError: null,
};

export const bookingsSlice = createSlice({
  name: "bookings",
  initialState: INITIAL_STATE,
  reducers: {
    //updateUserRealTime
    updateBookingsRealTime(state, action: PayloadAction<BookingType[]>) {
      state.bookingsLoading = false;
      state.bookings = action.payload;
    },
    errorBookingsRealTime(state, action: PayloadAction<string>) {
      state.bookingsLoading = false;
      state.bookingsError = action.payload;
    },
    //------------------
    //Create a new booking
    createBookingStart(
      state,
      action: PayloadAction<CreateBookingFromQuoteType>
    ) {
      state.bookingsLoading = true;
    },
    createBookingSuccess(state) {
      state.bookingsLoading = false;
      state.bookingsError = null;
      state.bookingCreated = true;
    },
    createBookingFailed(state, action: PayloadAction<string>) {
      state.bookingsLoading = false;
      state.bookingsError = action.payload;
    },
    //clear flag of new booking created
    clearNewBookingFlag(state) {
      state.bookingCreated = false;
    },
    //clear bookings data
    clearBookings(state) {
      state.bookingCreated = false;
      state.bookings = null;
      state.bookingsError = null;
      state.bookingsLoading = false;
    },
    //clear error. We don't export this as other slices have the same name
    //Components use createAction(`${slice}/clearError`) to fire it
    clearError(state) {
      state.bookingsError = null;
    },
  },
});

export const {
  updateBookingsRealTime,
  errorBookingsRealTime,
  createBookingFailed,
  createBookingStart,
  createBookingSuccess,
  clearNewBookingFlag,
  clearBookings,
} = bookingsSlice.actions;

export const bookingsReducer = bookingsSlice.reducer;
