import Card from "../UI/card/card.component";

const SearchUsers = () => {
  return (
    <Card>
      <h2>Search Users Form</h2>
    </Card>
  );
};

export default SearchUsers;
