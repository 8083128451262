import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { BookingType } from "../bookings/bookings.types";

// Define a type for the slice state
interface JobsState {
  jobs: BookingType[] | null;
  jobsLoading: boolean;
  jobsError: string | null;
}

const INITIAL_STATE: JobsState = {
  jobs: null,
  jobsLoading: true,
  jobsError: null,
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState: INITIAL_STATE,
  reducers: {
    //update jobs reak time
    updateJobsRealTime(state, action: PayloadAction<BookingType[]>) {
      state.jobsLoading = false;
      state.jobs = action.payload;
    },
    errorJobsRealTime(state, action: PayloadAction<string>) {
      state.jobsLoading = false;
      state.jobsError = action.payload;
    },
    //------------------
    //reset jobs list
    clearJobs(state) {
      state.jobs = null;
      state.jobsError = null;
      state.jobsLoading = false;
    },
    //clear error. We don't export this as other slices have the same name
    //Components use createAction(`${slice}/clearError`) to fire it
    clearError(state) {
      state.jobsError = null;
    },
  },
});

export const {
 updateJobsRealTime,
 errorJobsRealTime,
 clearJobs
} = jobsSlice.actions;

export const jobsReducer = jobsSlice.reducer;
