import styled from "styled-components";
import { colors } from "../../../style-variables";

export const BookingStatusContainer = styled.div`
  padding: 1 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const BookingStatusSign = styled.div`
  text-align: center;
  padding: 1rem;
  background-color: ${(props) => props.color};
  color: ${colors.lightTextHigh};
  font-weight: bold;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 2px ${colors.background}, 0 0 0 4px ${(props) => props.color}; 
`;
