import { FC } from "react";
import { useDispatch } from "react-redux";

import { ErrorContainer } from "./error-message.styles";
import { BackButtonContainer, RedHeader } from "../../../global.styles";
import Button from "../button/button.component";
import { createAction } from "@reduxjs/toolkit";

export const SLICE_TYPE_CONSTANTS = {
  user: "user",
  vehicles: "vehicles",
  userList: "userList",
  bookings: "bookings",
  bookingDetails: "bookingDetails",
  selectedUser: "selectedUser",
  vehicleList: "vehicleList",
  selectedVehicle: "selectedVehicle",
} as const;

//setting a type instead of using enum, more flexible
export type SliceTypes =
  (typeof SLICE_TYPE_CONSTANTS)[keyof typeof SLICE_TYPE_CONSTANTS];

type ErrorMessageProps = {
  slice: SliceTypes;
  children?: React.ReactNode;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ children, slice }) => {
  const dispatch = useDispatch();

  const errorClearHandler = () => {
    const clearErrorAction = createAction(`${slice}/clearError`);
    dispatch(clearErrorAction());
  };

  return (
    <ErrorContainer>
      <RedHeader>{children}</RedHeader>
      <BackButtonContainer>
        <Button buttonType="warning" onClick={errorClearHandler}>
          OK
        </Button>
      </BackButtonContainer>
    </ErrorContainer>
  );
};
export default ErrorMessage;
