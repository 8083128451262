import { takeLatest, all, call, put } from "typed-redux-saga/macro";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  changeDriverPreferences,
  pauseResumeNewRequests,
} from "../../firebase/user";
import {
  ChangeDriverPreferencesType,
  PauseOrResumeJobsType,
} from "../user/user.types";
import {
  pauseOrResumeNewJobsFailed,
  pauseOrResumeNewJobsSuccess,
  updateDrivingPreferencesFailed,
  updateDrivingPreferencesSuccess,
} from "./approved-driver.slice";

//functions
function* changePreferences({
  payload: { userId, selectedVehicleTypes, petFriendly },
}: PayloadAction<ChangeDriverPreferencesType>) {
  try {
    // const fetchedUser =
    yield* call(
      changeDriverPreferences,
      userId,
      selectedVehicleTypes,
      petFriendly
    );
    // if (fetchedUser) {
    // yield* put(updateUserNameSuccess(fetchedUser));
    yield* put(updateDrivingPreferencesSuccess());
    // }
  } catch (error) {
    if (error instanceof Error) {
      yield* put(updateDrivingPreferencesFailed(error.message));
    } else {
      yield* put(updateDrivingPreferencesFailed("Name Update Failed..."));
    }
  }
}
function* pauseResumeJobs({
  payload: { userId, pausedRequests },
}: PayloadAction<PauseOrResumeJobsType>) {
  try {
    yield* call(pauseResumeNewRequests, userId, pausedRequests);
    yield* put(pauseOrResumeNewJobsSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield* put(pauseOrResumeNewJobsFailed(error.message));
    } else {
      yield* put(pauseOrResumeNewJobsFailed("Name Update Failed..."));
    }
  }
}

//listeners
function* onChangePreferencesStart() {
  yield* takeLatest(
    "approvedDriver/updateDrivingPreferencesStart",
    changePreferences
  );
}
function* onPauseResumeJobsStart() {
  yield* takeLatest(
    "approvedDriver/pauseOrResumeNewJobsStart",
    pauseResumeJobs
  );
}

export function* approvedDriverSagas() {
  yield* all([call(onChangePreferencesStart), call(onPauseResumeJobsStart)]);
}
