import { FC } from "react";

import {
  SpacerHorizontal,
  SpacerVertical,
} from "../../UI/spacers/spacers.component";
import { GreenP, PushToLefttArea } from "../../../global.styles";
import { GiCheckMark, GiCrossMark } from "react-icons/gi";
import { colors } from "../../../style-variables";

type PetFriendlinessProps = {
  petFriendly: Boolean;
};

const PetFriendliness: FC<PetFriendlinessProps> = ({ petFriendly }) => {
  return (
    <SpacerVertical $paddingRem={1}>
      <SpacerHorizontal $paddingRem={2}>
        <PushToLefttArea>
          {petFriendly ? (
            <>
              <GiCheckMark size={24} color={colors.light} />
              <SpacerHorizontal $paddingRem={1}>
                <GreenP>Pets allowed</GreenP>
              </SpacerHorizontal>
            </>
          ) : (
            <>
              <GiCrossMark size={24} color={colors.warning} />
              <SpacerHorizontal $paddingRem={1}>
                <GreenP>Pets NOT allowed</GreenP>
              </SpacerHorizontal>
            </>
          )}
        </PushToLefttArea>
      </SpacerHorizontal>
    </SpacerVertical>
  );
};

export default PetFriendliness;
