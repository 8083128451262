import { ChangeEvent, FC, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";

import FormInput from "../../UI/form-input/form-input.component";
import { ButtonContainer } from "../../UI/button/button.styles";
import Button from "../../UI/button/button.component";
import { addVehicleStart } from "../../../redux/vehicles/vehicles.slice";

type AddVehicleProps = {
  userId: string;
  onCancelEdit: () => void;
};

const AddVehicleForm: FC<AddVehicleProps> = ({ userId, onCancelEdit }) => {
  const dispatch = useDispatch();

  const [numberPlate, setNumberPlate] = useState("");

  const formChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    //some browsers appear to be ignoring maxLength attribute
    const maxLength = 7;
    setNumberPlate(event.target.value.toUpperCase().replaceAll(" ","").slice(0, maxLength));
  };

  const handleAddVehicle = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(addVehicleStart({ userId, numberPlate }));
    onCancelEdit();
  };

  return (
    <div>
      <form onSubmit={handleAddVehicle}>
        <FormInput
          label=" DVLA Number Plate"
          onChange={formChangeHandler}
          id="numberPlate"
          name="numberPlate"
          type="text"
          value={numberPlate}
          maxLength={7}
          required
        />

        <ButtonContainer>
          <Button type="submit">Add New Vehicle</Button>
          <Button type="button" buttonType="inverted" onClick={onCancelEdit}>
            Cancel
          </Button>
        </ButtonContainer>
      </form>
    </div>
  );
};

export default AddVehicleForm;
