import styled from "styled-components";
import { colors } from "../../../style-variables";

export const ModalBackground = styled.div`
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.lightTransparent};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  width: 20rem;
  height: 20rem;
  border-radius: 0.5rem;
  background-color: ${colors.background};
  box-shadow: 0.25rem 0.25rem 0.5rem ${colors.dark};
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

export const Title = styled.div`
  display: inline-block;
  text-align: center;
  margin-top: 0.5rem;
`;

export const CloseButtonXContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

export const Body = styled.div`
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: center;
`;

export const Footer = styled.div`
  flex: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const ConfirmButton = styled.button`
  width: 9rem;
  height: 3rem;
  margin: 10px;
  border: none;
  background-color: ${colors.primary};
  color: ${colors.lightTextHigh};
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: bolder;
  font-size: 1rem;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${colors.background};
    color: ${colors.primary};
    border: 2px solid ${colors.primary};
  }
`;

export const CancelButton = styled(ConfirmButton)`
  background-color: ${colors.background};
  color: ${colors.primary};
  border: 2px solid ${colors.primary};
  &:hover,
  &:focus {
    background-color: ${colors.primary};
    color: ${colors.background};
    border: none;
  }
`;
