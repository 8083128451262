import { Link } from "react-router-dom";
import { BackButtonContainer } from "../../../global.styles";
import Card from "../../UI/card/card.component";

const JobsHistory = () => {
  return (
    <Card>
      <BackButtonContainer>
        <Link to="/drivers/driver-panel">
          <h3>&larr; Driver Menu</h3>
        </Link>
      </BackButtonContainer>
      Jobs History
    </Card>
  );
};

export default JobsHistory;
