import { takeLatest, all, call, put } from "typed-redux-saga/macro";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  approveDriversPaperworkFailed,
  approveDriversPaperworkSuccess,
  changeDriverStatusFailed,
  changeDriverStatusSuccess,
  getUserByIdFailed,
  getUserByIdSuccess,
  rejectDriversPaperworkFailed,
  rejectDriversPaperworkSuccess,
  resetDriverApprovalWaitingFailed,
  resetDriverApprovalWaitingSuccess,
  setLicenceNumbersFailed,
  setLicenceNumbersSuccess,
  verifyDvlaCodeFailed,
  verifyDvlaCodeSuccess,
} from "./selectedUser.slice";
import {
  approveUserDriverDoc,
  changeDriverStatus,
  getUserById,
  inputLicenceNumbers,
  rejectUserDriverDoc,
  removeDriverApprovalWaiting,
  verifyDvlaCheckCode,
} from "../../../firebase/admin";
import {
  ApproveDriverDocType,
  ChangeDriverStatusType,
  RejectDriverDocType,
  SetLicenceNumbersType,
  VerifyDvlaCodeType,
} from "../../user/user.types";

//generator functions
//get User by Id
function* fetchUserById({ payload: userId }: PayloadAction<string>) {
  try {
    const selectedUser = yield* call(getUserById, userId);
    yield* put(getUserByIdSuccess(selectedUser));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(getUserByIdFailed(error.message));
    } else {
      yield* put(getUserByIdFailed("Error Fetching User By ID"));
    }
  }
}
//Approve paperwork for User-Driver
function* approveDriversPaperwork({
  payload: { userId, docType, expiryDate },
}: PayloadAction<ApproveDriverDocType>) {
  try {
    const selectedUser = yield* call(
      approveUserDriverDoc,
      userId,
      docType,
      expiryDate
    );
    yield* put(approveDriversPaperworkSuccess(selectedUser));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(approveDriversPaperworkFailed(error.message));
    } else {
      yield* put(
        approveDriversPaperworkFailed(
          "Error approving paperwork for the Driver."
        )
      );
    }
  }
}
//Reject paperwork for User-Driver
function* rejectDriversPaperwork({
  payload: { userId, docType, reasonOfDecline },
}: PayloadAction<RejectDriverDocType>) {
  try {
    const selectedUser = yield* call(
      rejectUserDriverDoc,
      userId,
      docType,
      reasonOfDecline
    );
    yield* put(rejectDriversPaperworkSuccess(selectedUser));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(rejectDriversPaperworkFailed(error.message));
    } else {
      yield* put(
        rejectDriversPaperworkFailed(
          "Error approving paperwork for the Driver."
        )
      );
    }
  }
}
//Verify DVLA Check Code for User-Driver
function* verifyDvlaCode({
  payload: { userId, isVerified },
}: PayloadAction<VerifyDvlaCodeType>) {
  try {
    const selectedUser = yield* call(verifyDvlaCheckCode, userId, isVerified);
    yield* put(verifyDvlaCodeSuccess(selectedUser));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(verifyDvlaCodeFailed(error.message));
    } else {
      yield* put(
        verifyDvlaCodeFailed("Error approving DVLA CHeck Code for the Driver.")
      );
    }
  }
}
//Set licence numbers for User-Driver (DVLA and Badge)
function* setLicenceNumbers({
  payload: { userId, dvlaLicenceNumber, badgeNumber },
}: PayloadAction<SetLicenceNumbersType>) {
  try {
    const selectedUser = yield* call(
      inputLicenceNumbers,
      userId,
      dvlaLicenceNumber,
      badgeNumber
    );
    yield* put(setLicenceNumbersSuccess(selectedUser));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(setLicenceNumbersFailed(error.message));
    } else {
      yield* put(
        setLicenceNumbersFailed("Error setting licence numbers for the Driver.")
      );
    }
  }
}
//reset waiting for Driver approval status
function* resetDriverApprovalWaiting({
  payload: userId,
}: PayloadAction<string>) {
  try {
    const updatedUser = yield* call(removeDriverApprovalWaiting, userId);
    yield* put(resetDriverApprovalWaitingSuccess(updatedUser));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(resetDriverApprovalWaitingFailed(error.message));
    } else {
      yield* put(
        resetDriverApprovalWaitingFailed(
          "Error changing waiting for approval status for the Driver."
        )
      );
    }
  }
}
//activate or suspend a Driver
function* activateOrSuspendDriver({
  payload: { userId, approvedDriver },
}: PayloadAction<ChangeDriverStatusType>) {
  try {
    const selectedDriver = yield* call(
      changeDriverStatus,
      userId,
      approvedDriver
    );
    yield* put(changeDriverStatusSuccess(selectedDriver));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(changeDriverStatusFailed(error.message));
    } else {
      yield* put(changeDriverStatusFailed("Error changing Driver status."));
    }
  }
}

//listeners
function* onGetUserByIdStart() {
  yield* takeLatest("selectedUser/getUserByIdStart", fetchUserById);
}
function* onApproveDriversPaperworkStart() {
  yield* takeLatest(
    "selectedUser/approveDriversPaperworkStart",
    approveDriversPaperwork
  );
}
function* onRejectDriversPaperworkStart() {
  yield* takeLatest(
    "selectedUser/rejectDriversPaperworkStart",
    rejectDriversPaperwork
  );
}
function* onVerifyDvlaCodeStart() {
  yield* takeLatest("selectedUser/verifyDvlaCodeStart", verifyDvlaCode);
}
function* onSetLicenceNumbersStart() {
  yield* takeLatest("selectedUser/setLicenceNumbersStart", setLicenceNumbers);
}
function* onResetDriverApprovalWaitingStart() {
  yield* takeLatest(
    "selectedUser/resetDriverApprovalWaitingStart",
    resetDriverApprovalWaiting
  );
}
function* onChangeDriverStatusStart() {
  yield* takeLatest(
    "selectedUser/changeDriverStatusStart",
    activateOrSuspendDriver
  );
}

export function* selectedUserSagas() {
  yield* all([
    call(onGetUserByIdStart),
    call(onApproveDriversPaperworkStart),
    call(onRejectDriversPaperworkStart),
    call(onVerifyDvlaCodeStart),
    call(onSetLicenceNumbersStart),
    call(onResetDriverApprovalWaitingStart),
    call(onChangeDriverStatusStart),
  ]);
}
