import { FC } from "react";

import { CardContainer } from "./card.styles";

type CardProps = {
  children?: React.ReactNode;
};

const Card: FC<CardProps> = ({ children }) => {
  return <CardContainer>{children}</CardContainer>;
};

export default Card;
