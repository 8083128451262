import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  ApprovedDriverType,
  ChangeDriverPreferencesType,
  PauseOrResumeJobsType,
} from "../user/user.types";

// Define a type for the slice state
interface ApprovedDriverState {
  approvedDriver: ApprovedDriverType | null;
  approvedDriverLoading: boolean;
  approvedDriverError: string | null;
}

const INITIAL_STATE: ApprovedDriverState = {
  approvedDriver: null,
  approvedDriverLoading: false,
  approvedDriverError: null,
};

export const approvedDriverSlice = createSlice({
  name: "approvedDriver",
  initialState: INITIAL_STATE,
  reducers: {
    //updateUserRealTime
    updateApprovedDriverRealTime(
      state,
      action: PayloadAction<ApprovedDriverType>
    ) {
      state.approvedDriverLoading = false;
      state.approvedDriver = action.payload;
    },
    errorApprovedDriverRealTime(state, action: PayloadAction<string>) {
      state.approvedDriverLoading = false;
      state.approvedDriverError = action.payload;
    },
    //-------------------
    //update driving preferences
    updateDrivingPreferencesStart(
      state,
      action: PayloadAction<ChangeDriverPreferencesType>
    ) {
      state.approvedDriverLoading = true;
    },
    updateDrivingPreferencesSuccess(state) {
      state.approvedDriverLoading = false;
      state.approvedDriverError = null;
    },
    updateDrivingPreferencesFailed(state, action: PayloadAction<string>) {
      state.approvedDriverLoading = false;
      state.approvedDriverError = action.payload;
    },
    //pause or resume acceptance of new requests
    pauseOrResumeNewJobsStart(
      state,
      action: PayloadAction<PauseOrResumeJobsType>
    ) {
      state.approvedDriverLoading = true;
    },
    pauseOrResumeNewJobsSuccess(state) {
      state.approvedDriverLoading = false;
      state.approvedDriverError = null;
    },
    pauseOrResumeNewJobsFailed(state, action: PayloadAction<string>) {
      state.approvedDriverLoading = false;
      state.approvedDriverError = action.payload;
    },
    //reset approvedDriver state in redux store
    clearApprovedDriver(state) {
      state.approvedDriver = null;
      state.approvedDriverError = null;
      state.approvedDriverLoading = false;
    },
    //clear error. We don't export this as other slices have the same name
    //Components use createAction(`${slice}/clearError`) to fire it
    clearError(state) {
      state.approvedDriverError = null;
    },
  },
});

export const {
  errorApprovedDriverRealTime,
  updateApprovedDriverRealTime,
  updateDrivingPreferencesFailed,
  updateDrivingPreferencesStart,
  updateDrivingPreferencesSuccess,
  pauseOrResumeNewJobsFailed,
  pauseOrResumeNewJobsStart,
  pauseOrResumeNewJobsSuccess,
  clearApprovedDriver,
} = approvedDriverSlice.actions;

export const approvedDriverReducer = approvedDriverSlice.reducer;
