import axios, { AxiosError } from "axios";

import { GetPriceQuoteType } from "../redux/user/user.types";
import { CreateBookingFromQuoteType } from "../redux/bookings/bookings.types";
import { auth } from "./config";

export const getPriceQuote = async (quoteRequest: GetPriceQuoteType) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const quoteUri = "http://localhost:5001/rufus-taxi/europe-west2/requestQuote" //local tesing
      const quoteUri = "https://requestquote-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(quoteUri, quoteRequest, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error processing the quote. Please, try again later");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};

//Do it in a cloud!
export const createBookingFromRecentQuote = async (
  newBookingRequest: CreateBookingFromQuoteType
) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const quoteUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/createBooking"; //local tesing
      const quoteUri = "https://createbooking-tvhiteyzsa-nw.a.run.app";
      await axios.post(quoteUri, newBookingRequest, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error creating new booking! Please, try again later.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};

//Request GepoPoint in a cloud
export const requestGeoPoint = async (address: string) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const geoPointUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/getGeoPoint"; //local tesing
      const geoPointUri = "https://getgeopoint-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(
        geoPointUri,
        { address },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error requesting GeoPoint! Please, try again later.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};