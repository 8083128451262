import { styled } from "styled-components";

import { colors } from "../../../style-variables";
import { GreenHeader } from "../../../global.styles";

export const DriverNumberContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DriverNumber = styled(GreenHeader)`
  border: 5px solid ${colors.light};
  border-radius: 12px;
  font-size: 2.5rem;
`;
