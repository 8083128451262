import { FC } from "react";
import { GiCheckMark } from "react-icons/gi";

import {
  SpacerHorizontal,
  SpacerVertical,
} from "../../UI/spacers/spacers.component";
import { GreenP, PushToLefttArea } from "../../../global.styles";
import { colors } from "../../../style-variables";
import { VehicleType } from "../../../redux/vehicles/vehicles.types";
import { defineVehicleType } from "../../../util-functions";

type SelectedVehicleTypeProps = {
  vehicleType: VehicleType;
};

const SelectedVehicleType: FC<SelectedVehicleTypeProps> = ({ vehicleType }) => {
  return (
    <SpacerVertical $paddingRem={1}>
      <SpacerHorizontal $paddingRem={2}>
        <PushToLefttArea>
          <GiCheckMark size={24} color={colors.light} />
          <SpacerHorizontal $paddingRem={1}>
            <GreenP>{defineVehicleType(vehicleType)}</GreenP>
          </SpacerHorizontal>
        </PushToLefttArea>
      </SpacerHorizontal>
    </SpacerVertical>
  );
};

export default SelectedVehicleType;
