import Card from "../../components/UI/card/card.component";
import { GreenHeader } from "../../global.styles";

const Contact = () => {
  return (
    <Card>
      <GreenHeader>Contact Us</GreenHeader>
      <p>Some parts of this web applications are still under development. So is the Contact Form</p>
      <p>
        However, if You have any questions or propositions, please do not hesitate to
        contact our administration team at:
        <br />
        <a href="mailto:wawayltd@protonmail.com">wawayltd@protonmail.com</a>.
      </p>
    </Card>
  );
};

export default Contact;
