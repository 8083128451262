import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaDog } from "react-icons/fa";

import {
  BackButtonContainer,
  GreenHeader,
  GreenP,
  RedHeader,
  SpreadButtonContainer,
  TextDivider,
} from "../../../global.styles";
import { RootState } from "../../../redux/store";
import { isBookingType } from "../../../redux/bookings/bookings.types";
import Button from "../../UI/button/button.component";
import Addresses from "../../bookings/addresses.component";
import { defineVehicleType } from "../../../util-functions";
import Card from "../../UI/card/card.component";
import Spinner from "../../UI/spinner/spinner.component";
import { acceptJobRequest } from "../../../firebase/user";

const JobOfferDetails = () => {
  const navigate = useNavigate();

  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  const { jobsOffersLoading, jobsOffersError, jobOffers } = useSelector(
    (state: RootState) => state.jobOffers
  );

  const [acceptJobLoading, setAcceptJobLoading] = useState(false);
  const [acceptJobError, setAcceptJobError] = useState("");
  const [jobAccepted, setJobAccepted] = useState(false);

  //get state that was passed to this route
  const { state } = useLocation();

  if (!state || !approvedDriver || !jobOffers) {
    return (
      <Card>
        <RedHeader>Wrong Parameters...</RedHeader>
        <Button onClick={() => navigate("/drivers/driver-panel")}>Try Again</Button>
      </Card>
    );
  }

  const jobId = state.jobId;
  const job = jobOffers.find((job) => job.id === jobId);

  if (!jobAccepted && !isBookingType(job)) {
    return (
      <Card>
        <RedHeader>Wrong Parameters...</RedHeader>
        <Button onClick={() => navigate("/drivers/driver-panel")}>Try Again</Button>
      </Card>
    );
  }

  const acceptJobHandler = () => {
    if (jobId) {
      setAcceptJobLoading(true);
      acceptJobRequest(jobId)
        .then((accepted) => {
          setJobAccepted(accepted);
          setAcceptJobLoading(false);
        })
        .catch((error) => {
          if (error instanceof Error) {
            setAcceptJobError(error.message);
          } else {
            setAcceptJobError(
              "Problem accepting this job... Please make sure all parameters match. If problem still exists, please let us know"
            );
          }
          setAcceptJobLoading(false);
        });
    } else {
      navigate("/drivers/driver-panel");
    }
  };

  return (
    <Card>
      {(approvedDriverLoading || acceptJobLoading || jobsOffersLoading) && (
        <Spinner />
      )}
      {approvedDriverError && !approvedDriverLoading && (
        <RedHeader>{approvedDriverError}</RedHeader>
      )}
      {jobsOffersError && !jobsOffersLoading && (
        <RedHeader>{jobsOffersError}</RedHeader>
      )}
      {acceptJobError && !acceptJobLoading && (
        <RedHeader>{acceptJobError}</RedHeader>
      )}
      {jobAccepted && !acceptJobLoading && (
        <>
          <GreenHeader>Job successfully accepted!</GreenHeader>
          <BackButtonContainer>
            <Button
              buttonType="green"
              onClick={() => navigate("/drivers/accepted-jobs")}
            >
              OK
            </Button>
          </BackButtonContainer>
        </>
      )}
      {!jobAccepted && !acceptJobLoading && !!job && (
        <>
          <GreenHeader>
            Personal request for
            <br />
            {job.date}
            <br />
            at {job.time}
          </GreenHeader>
          <TextDivider>
            <GreenP> ADDRESSES </GreenP>
          </TextDivider>
          <Addresses booking={job} />
          <TextDivider>
            <GreenP> DETAILS </GreenP>
          </TextDivider>
          <GreenP>Passenger Name</GreenP>
          <p>{job.passengerName}</p>
          <br />
          <GreenP>Passenger Phone Number</GreenP>
          <p>{job.passengerPhone}</p>
          <br />
          <GreenP>Vehicle Type:</GreenP>
          <p>{defineVehicleType(job.vehicleType)}</p>
          {job.hasPet && (
            <>
              <br />
              <GreenP>
                <FaDog /> Booking includes pet.
              </GreenP>
            </>
          )}
          {job.driverNote && (
            <>
              <br />
              <GreenP>Note to the Driver</GreenP>
              <p>{job.driverNote}</p>
            </>
          )}
          <TextDivider>
            <GreenP> PRICE </GreenP>
          </TextDivider>
          <GreenHeader> £{job.quotedPrice}*</GreenHeader>
          <GreenP>
            * Amount to pay to the Driver. Any parking and/or toll fees and/or
            waitng time charges to be added. Please see{" "}
            <Link to="/terms">Terms and Conditions</Link>
          </GreenP>
          <TextDivider>
            <GreenP> ACTIONS </GreenP>
          </TextDivider>
          <SpreadButtonContainer>
            <Button
              buttonType="inverted"
              onClick={() => {
                console.log("TODO: Reject ACTION");
                navigate("/drivers/my-job-offers");
              }}
            >
              Reject
            </Button>
            <Button onClick={acceptJobHandler}>Accept The Job</Button>
          </SpreadButtonContainer>
        </>
      )}
    </Card>
  );
};

export default JobOfferDetails;
