import { takeLatest, all, call, put } from "typed-redux-saga/macro";
import { PayloadAction } from "@reduxjs/toolkit";

// import {
//   addPHVehicle,
//   addPHVehicleDoc,
//   deletePHVehicle,
//   getUserPHVehicles,
//   selectPHVehicle,
// } from "../../firebase/vehicles";
import { getUserVehiclesAdmin } from "../../../firebase/admin";
import {
  getUserVehiclesForAdminFailed,
  getUserVehiclesForAdminSuccess,
} from "./vehicleList.slice";
// import { AddPHVehicleDocType, ModifyVehicleType } from "./vehicles.types";

//generator functions
function* getUserVehicles({
  payload: userId,
}: PayloadAction<string>) {
  try {
    const vehiclesForApprovalList = yield* call(getUserVehiclesAdmin, userId);
    if (vehiclesForApprovalList) {
      yield* put(getUserVehiclesForAdminSuccess(vehiclesForApprovalList));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield* put(getUserVehiclesForAdminFailed(error.message));
    } else {
      yield* put(getUserVehiclesForAdminFailed("Error Fetching Vehicles..."));
    }
  }
}

//listeners
function* onGetUserVehiclesForAdminStart() {
  yield* takeLatest(
    "vehicleList/getUserVehiclesForAdminStart",
    getUserVehicles
  );
}

export function* vehicleListSagas() {
  yield* all([call(onGetUserVehiclesForAdminStart)]);
}
