import { ChangeEvent, FC, useState } from "react";

import { ButtonColumnContainer, RedP } from "../../../global.styles";
import FormInput from "../../UI/form-input/form-input.component";
import Button from "../../UI/button/button.component";
import { useDispatch } from "react-redux";
import { setLicenceNumbersStart } from "../../../redux/admin/selected-user/selectedUser.slice";

type LicenceNumbersProps = {
  userId: string;
  onCancelSet: () => void;
};

const SetLicenceNumbersForm: FC<LicenceNumbersProps> = ({
  userId,
  onCancelSet,
}) => {
  const dispatch = useDispatch();

  const [dvlaLicenceNumber, setDvlaLicenceNumber] = useState("");
  const [badgeNumber, setBadgeNumber] = useState("");
  const [error, setError] = useState("");

  //input change handlers
  const dvlaLicenceChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setDvlaLicenceNumber(event.target.value);
  };
  const badgeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setBadgeNumber(event.target.value);
  };

  //submit handler
  const submitHandler = () => {
    if (dvlaLicenceNumber === "" || badgeNumber === "") {
      setError("Please fill out all fields!");
      return;
    }
    dispatch(
      setLicenceNumbersStart({
        userId,
        dvlaLicenceNumber,
        badgeNumber,
      })
    );
    onCancelSet();
  };

  return (
    <ButtonColumnContainer>
      <FormInput
        label="DVLA Licence Number"
        onChange={dvlaLicenceChangeHandler}
        required
      />
      <FormInput
        label="PH Badge Numer"
        onChange={badgeChangeHandler}
        required
      />
      {error && <RedP>{error}</RedP>}
      <Button type="submit" onClick={submitHandler}>
        Submit
      </Button>
    </ButtonColumnContainer>
  );
};

export default SetLicenceNumbersForm;
