import { takeLatest, all, call, put } from "typed-redux-saga/macro";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  ApproveVehicleDocType,
  ChangeVehicleStatusType,
  ModifyVehicleType,
  RejectVehicleDocType,
  setVehicleType,
} from "../../vehicles/vehicles.types";
import {
  approveVehiclePaperworkFailed,
  approveVehiclePaperworkSuccess,
  changeVehicleStatusFailed,
  changeVehicleStatusSuccess,
  getVehicleByNumberPlateFailed,
  getVehicleByNumberPlateSuccess,
  rejectVehiclePaperworkFailed,
  rejectVehiclePaperworkSuccess,
  resetVehicleApprovalWaitingFailed,
  resetVehicleApprovalWaitingSuccess,
  setVehicleFailed,
  setVehicleSuccess,
} from "./selectedVehicle.slice";
import {
  approveUserVehicleDoc,
  changeVehicleStatus,
  getVehicleByNumberPlate,
  rejectUserVehicleDoc,
  removeVehicleApprovalWaiting,
  setMakeAndModel,
} from "../../../firebase/admin";

//generator functions
function* fetchVehicleByNumerplate({
  payload: { userId, numberPlate },
}: PayloadAction<ModifyVehicleType>) {
  try {
    const selectedVehicle = yield* call(
      getVehicleByNumberPlate,
      userId,
      numberPlate
    );
    yield* put(getVehicleByNumberPlateSuccess(selectedVehicle));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(getVehicleByNumberPlateFailed(error.message));
    } else {
      yield* put(
        getVehicleByNumberPlateFailed("Error Fetching vehicle by number plate")
      );
    }
  }
}
//Approve paperwork for PH vehicle
function* approveVehiclePaperwork({
  payload: { userId, numberPlate, docType, expiryDate },
}: PayloadAction<ApproveVehicleDocType>) {
  try {
    const selectedVehicle = yield* call(
      approveUserVehicleDoc,
      userId,
      numberPlate,
      docType,
      expiryDate
    );
    yield* put(approveVehiclePaperworkSuccess(selectedVehicle));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(approveVehiclePaperworkFailed(error.message));
    } else {
      yield* put(
        approveVehiclePaperworkFailed(
          "Error approving paperwork for the vehicle."
        )
      );
    }
  }
}
//Reject paperwork for vehicle
function* rejectVehiclePaperwork({
  payload: { userId, numberPlate, docType, reasonOfDecline },
}: PayloadAction<RejectVehicleDocType>) {
  try {
    const selectedVehicle = yield* call(
      rejectUserVehicleDoc,
      userId,
      numberPlate,
      docType,
      reasonOfDecline
    );
    yield* put(rejectVehiclePaperworkSuccess(selectedVehicle));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(rejectVehiclePaperworkFailed(error.message));
    } else {
      yield* put(
        rejectVehiclePaperworkFailed(
          "Error rejecting paperwork for the vehicle."
        )
      );
    }
  }
}
//set PH vehicle details (color, make, model, licence plate)
function* setVehicleDetails({
  payload: { userId, numberPlate, make, model, color, licencePlate, vehicleTypes },
}: PayloadAction<setVehicleType>) {
  try {
    const selectedVehicle = yield* call(
      setMakeAndModel,
      userId,
      numberPlate,
      make,
      model,
      color,
      licencePlate,
      vehicleTypes
    );
    yield* put(setVehicleSuccess(selectedVehicle));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(setVehicleFailed(error.message));
    } else {
      yield* put(setVehicleFailed("Error setting details for the vehicle."));
    }
  }
}
//reset waiting for approval status
function* resetVehicleApprovalWaiting({
  payload: { userId, numberPlate },
}: PayloadAction<ModifyVehicleType>) {
  try {
    const selectedVehicle = yield* call(
      removeVehicleApprovalWaiting,
      userId,
      numberPlate,
    );
    yield* put(resetVehicleApprovalWaitingSuccess(selectedVehicle));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(resetVehicleApprovalWaitingFailed(error.message));
    } else {
      yield* put(
        resetVehicleApprovalWaitingFailed("Error changing status for the vehicle.")
      );
    }
  }
}
//activate or suspend a vehicle
function* activateOrSuspendVehicle({
  payload: { userId, numberPlate, approved },
}: PayloadAction<ChangeVehicleStatusType>) {
  try {
    const selectedVehicle = yield* call(
      changeVehicleStatus,
      userId,
      numberPlate,
      approved
    );
    yield* put(changeVehicleStatusSuccess(selectedVehicle));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(changeVehicleStatusFailed(error.message));
    } else {
      yield* put(
        changeVehicleStatusFailed("Error changing status for the vehicle.")
      );
    }
  }
}

//listeners
function* onGetVehicleByNumberPlateStart() {
  yield* takeLatest(
    "selectedVehicle/getVehicleByNumberPlateStart",
    fetchVehicleByNumerplate
  );
}
function* onApproveVehiclePaperworkStart() {
  yield* takeLatest(
    "selectedVehicle/approveVehiclePaperworkStart",
    approveVehiclePaperwork
  );
}
function* onRejectVehiclePaperworkStart() {
  yield* takeLatest(
    "selectedVehicle/rejectVehiclePaperworkStart",
    rejectVehiclePaperwork
  );
}
function* onSetVehicleStart() {
  yield* takeLatest("selectedVehicle/setVehicleStart", setVehicleDetails);
}
function* onResetVehicleApprovalWaitingStart() {
  yield* takeLatest(
    "selectedVehicle/resetVehicleApprovalWaitingStart",
    resetVehicleApprovalWaiting
  );
}
function* onChangeVehicleStatusStart() {
  yield* takeLatest(
    "selectedVehicle/changeVehicleStatusStart",
    activateOrSuspendVehicle
  );
}
export function* selectedVehicleSagas() {
  yield* all([
    call(onGetVehicleByNumberPlateStart),
    call(onApproveVehiclePaperworkStart),
    call(onRejectVehiclePaperworkStart),
    call(onSetVehicleStart),
    call(onResetVehicleApprovalWaitingStart),
    call(onChangeVehicleStatusStart),
  ]);
}
