import { ChangeEvent, FC, FormEvent, useState } from "react";

import FormInput from "../../UI/form-input/form-input.component";
import Button from "../../UI/button/button.component";
import { useDispatch } from "react-redux";
import { updateUserNameStart } from "../../../redux/user/user.slice";
import { ButtonContainer } from "../../UI/button/button.styles";

type UpdateNameProps = {
  userId: string;
  currentName: string;
  onCancelEdit: () => void;
};

const UpdateNameForm: FC<UpdateNameProps> = ({
  userId,
  currentName,
  onCancelEdit,
}) => {
  const dispatch = useDispatch();

  const [name, setName] = useState(currentName);

  const nameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleUpdateUserName = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //dispatch name update
    const id = userId;
    //only dispatch an action if name has changed
    if (currentName !== name) {
      dispatch(updateUserNameStart({ id, name }));
    }
    onCancelEdit();
  };

  let updateNameText;
  let updatingExisting = false;

  if (currentName === "") {
    updateNameText = (
      <>
        <h1>Hello and welcome aboard!</h1>
        <br />
        <p>To complete your registration, please provide Your Name:</p>
      </>
    );
  } else {
    updateNameText = <h1>Update Name</h1>;
    updatingExisting = true;
  }

  return (
    <div>
      {updateNameText}
      <form onSubmit={handleUpdateUserName}>
        <FormInput
          label="Your Name"
          onChange={nameChangeHandler}
          id="name"
          name="name"
          type="text"
          value={name}
          required
        />
        {updatingExisting ? (
          <ButtonContainer>
            <Button type="submit">Update Name</Button>
            <Button type="button" buttonType="inverted" onClick={onCancelEdit}>
              Cancel
            </Button>
          </ButtonContainer>
        ) : (
          <Button type="submit">Add Name</Button>
        )}
      </form>
    </div>
  );
};

export default UpdateNameForm;
