import styled from "styled-components";

import { colors } from "../../../style-variables";

//based on this:
//https://blog.logrocket.com/building-custom-checkbox-react/

export const CheckboxWrapper = styled.div`
  display: flex;
  padding: 0.7rem;
  gap: 0.5rem;
  align-items: center;
`;

export const StyledCheckbox = styled.input`
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.15rem;
  margin-right: 0.5rem;
  border: 2px solid ${colors.dark};
  outline: none;
  cursor: pointer;
  &:checked {
    background-color: ${colors.dark};
    position: relative;
  }
  &:checked::before {
    content: "\u2714";
    font-size: 1.2rem;
    color: ${colors.background};
    position: absolute;
    /* right: 1px; */
    top: -5px;
  }
  &:disabled {
    border-color: #aaaaaa;
    background-color: #aaaaaa;
  }
`;

export const StyledCheckboxLabel = styled.label<{ disabled?: boolean }>`
  /* font-weight: 600; */
  font-size: 1.2rem;
  color: ${colors.dark};
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
    color: ${colors.lightTransparent};
    cursor: not-allowed;
    &::after {
      margin-left: 8px;
      width: 12px;
      height: 15px;
      display: inline-block;
      content: " ";
      -webkit-mask: url("/lock.svg") no-repeat 50% 50%;
      -webkit-mask-size: cover;
      background-color: ${colors.background};
    }
  `}
`;
