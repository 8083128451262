import { FC } from "react";

import { BookingType } from "../../../redux/bookings/bookings.types";
import {
  BookingStatusContainer,
  BookingStatusSign,
} from "./booking-status.styles";
import { defineBookingStatus } from "../../../util-functions";

type BookingStatusProps = {
  booking: BookingType;
};

const BookingStatus: FC<BookingStatusProps> = ({ booking }) => {
  const statusMessage = defineBookingStatus(booking).message;
  const statusColor = defineBookingStatus(booking).color;
  return (
    <BookingStatusContainer>
      <BookingStatusSign color={statusColor}>{statusMessage}</BookingStatusSign>
    </BookingStatusContainer>
  );
};

export default BookingStatus;
