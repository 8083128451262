import { styled } from "styled-components";
import { colors } from "../../../style-variables";

export const ErrorContainer = styled.div`
  margin: 1rem;
  padding: 1rem;
  width: 90%;
  max-width: 40rem;
  /* min-width: 30rem; */
  background-color: ${colors.background};
  border-radius: 0.5rem;
  box-shadow: 0.25rem 0.25rem 0.5rem ${colors.warning};
  border: 1px solid ${colors.warning};
`;
