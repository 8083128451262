import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import Card from "../UI/card/card.component";
import Spinner from "../UI/spinner/spinner.component";
import ErrorMessage from "../UI/error-message/error-message.component";
import { getUserByIdStart } from "../../redux/admin/selected-user/selectedUser.slice";
import {
  changeVehicleStatusStart,
  getVehicleByNumberPlateStart,
  resetVehicleApprovalWaitingStart,
} from "../../redux/admin/selected-vehicle/selectedVehicle.slice";
import {
  AmberP,
  ButtonColumnContainer,
  ButtonContainer,
  GreenHeader,
  GreenP,
  RedHeader,
  RedP,
  SpreadApartArea,
  TextDivider,
} from "../../global.styles";
import InnerCard from "../UI/inner-card/inner-card.component";
import ApproveVehiclePaperwork from "./forms/approve-vehicle-paperwork.component";
import SetMakeModelForm from "./forms/set-make-model.component";
import Button from "../UI/button/button.component";
import Modal from "../UI/modal/modal.component";

const required = <RedP>Not Provided</RedP>;
const pending = <AmberP>Waitng for approval...</AmberP>;
const optional = <AmberP>Optional</AmberP>;
const approved = <GreenP>Approved</GreenP>;

const VehicleDetails = () => {
  const { userId, numberPlate } = useParams();
  const dispatch = useDispatch();

  const { selectedUserLoading, selectedUserError, selectedUser } = useSelector(
    (state: RootState) => state.selectedUser
  );
  const { selectedVehicleLoading, selectedVehicleError, selectedVehicle } =
    useSelector((state: RootState) => state.selectedVehicle);

  //modal to confirm vehicle activation or suspension (approve or ban)
  const [openModal, setOpenModal] = useState(false);
  const [approve, setApprove] = useState<boolean | null>(null);
  //modal to reset waiting for approval status
  const [openResetWaitModal, setOpenResetWaitModal] = useState(false);

  //show color/make/model setting form
  const [showCarDetailsForm, setShowCarDetailsForm] = useState(false);

  //show paperwork approval forms
  const [showPHVehicleLicenceForm, setShowPHVehicleLicenceForm] =
    useState(false);
  const [showLogBookForm, setShowLogBookForm] = useState(false);
  const [showMOTCertificateForm, setShowMOTCertificateForm] = useState(false);
  const [showInsuranceCertificateForm, setShowInsuranceCertificateForm] =
    useState(false);
  const [showHireContractForm, setShowHireContractForm] = useState(false);

  useEffect(() => {
    if (userId && numberPlate) {
      dispatch(getUserByIdStart(userId));
      dispatch(getVehicleByNumberPlateStart({ userId, numberPlate }));
    }
  }, [dispatch, numberPlate, userId]);

  //reset "waitng for approval" boolean
  const resetWaitingHandler = () => {
    setOpenResetWaitModal(true);
  };
  const resetWaitingConfirm = () => {
    if (userId && numberPlate) {
      dispatch(resetVehicleApprovalWaitingStart({ userId, numberPlate }));
    }
  };
  //activate of suspend the vehicle
  const activationHandler = (activate: boolean) => {
    setApprove(activate);
    setOpenModal(true);
  };
  const activationConfirm = () => {
    if (userId && numberPlate && approve !== null) {
      dispatch(
        changeVehicleStatusStart({ userId, numberPlate, approved: approve })
      );
    }
  };

  return (
    <>
      {selectedUserLoading && <Spinner />}
      {selectedUserError && (
        <ErrorMessage slice="selectedUser">{selectedUserError}</ErrorMessage>
      )}
      {selectedUser && selectedUser.driversData && (
        <Card>
          {openModal && approve !== null && (
            <Modal
              title={approve ? "Activate" : "Suspend"}
              body={`${
                approve ? "Activate" : "Suspend"
              } this vehicle? Are you sure?`}
              confirmButtonText={approve ? "Activate" : "Suspend"}
              setOpenModal={setOpenModal}
              onModalConfirm={activationConfirm}
            />
          )}
          {openResetWaitModal && (
            <Modal
              title="Reset Waiting Status"
              body="Is all paperwork and data for this vehicle reviewed? Are you sure?"
              confirmButtonText="Reset"
              setOpenModal={setOpenResetWaitModal}
              onModalConfirm={resetWaitingConfirm}
            />
          )}
          <TextDivider>
            <GreenP>DRIVERS DATA</GreenP>
          </TextDivider>

          <SpreadApartArea>
            <GreenP>Name:</GreenP>
            <p>
              <strong>{selectedUser.name}</strong>
            </p>
          </SpreadApartArea>
          <SpreadApartArea>
            <GreenP>Licence:</GreenP>
            <p>
              <strong>{selectedUser.driversData.licensingAuthority}</strong>
            </p>
          </SpreadApartArea>
          <TextDivider>
            <GreenP>VEHICLE - {numberPlate}</GreenP>
          </TextDivider>
          {selectedVehicleLoading && <Spinner />}
          {selectedVehicleError && (
            <ErrorMessage slice="selectedVehicle">
              {selectedVehicleError}
            </ErrorMessage>
          )}
          {selectedVehicle && (
            <>
              <InnerCard
                onClick={() => {
                  setShowCarDetailsForm(!showCarDetailsForm);
                }}
              >
                <h3>Colour/Make/Model</h3>
                {selectedVehicle.make &&
                selectedVehicle.model &&
                selectedVehicle.color &&
                selectedVehicle.licencePlate ? (
                  <div>
                    <GreenP>{selectedVehicle.color}</GreenP>
                    <GreenP>{selectedVehicle.make}</GreenP>
                    <GreenP>{selectedVehicle.model}</GreenP>
                    <GreenP>{selectedVehicle.licencePlate}</GreenP>
                  </div>
                ) : (
                  required
                )}
              </InnerCard>
              {showCarDetailsForm && userId && (
                <SetMakeModelForm
                  userId={userId}
                  numberPlate={selectedVehicle.numberPlate}
                  onCancelSet={() => setShowCarDetailsForm(false)}
                />
              )}
              <InnerCard
                onClick={() =>
                  setShowPHVehicleLicenceForm(!showPHVehicleLicenceForm)
                }
              >
                <h3>Vehicle Licence</h3>
                {selectedVehicle.PHVehicleLicence &&
                selectedVehicle.PHVehicleLicence.pendingUrl ? (
                  pending
                ) : selectedVehicle.PHVehicleLicence &&
                  selectedVehicle.PHVehicleLicence.approved ? (
                  approved
                ) : selectedVehicle.PHVehicleLicence &&
                  selectedVehicle.PHVehicleLicence.reasonOfDecline ? (
                  <RedP>
                    {selectedVehicle.PHVehicleLicence.reasonOfDecline}
                  </RedP>
                ) : (
                  required
                )}
              </InnerCard>
              {showPHVehicleLicenceForm &&
                (selectedVehicle.PHVehicleLicence?.pendingUrl ||
                  selectedVehicle.PHVehicleLicence?.downloadUrl ||
                  selectedVehicle.PHVehicleLicence?.expiringUrl) && (
                  <ApproveVehiclePaperwork
                    docTitle="PHVehicleLicence"
                    onDocumentReviewed={() =>
                      setShowPHVehicleLicenceForm(false)
                    }
                  />
                )}

              <InnerCard
                onClick={() => {
                  setShowLogBookForm(!showLogBookForm);
                }}
              >
                <h3>Log Book</h3>
                {selectedVehicle.logBook &&
                selectedVehicle.logBook.pendingUrl ? (
                  pending
                ) : selectedVehicle.logBook &&
                  selectedVehicle.logBook.approved ? (
                  approved
                ) : selectedVehicle.logBook &&
                  selectedVehicle.logBook.reasonOfDecline ? (
                  <RedP>{selectedVehicle.logBook.reasonOfDecline}</RedP>
                ) : (
                  required
                )}
              </InnerCard>
              {showLogBookForm &&
                (selectedVehicle.logBook?.pendingUrl ||
                  selectedVehicle.logBook?.downloadUrl ||
                  selectedVehicle.logBook?.expiringUrl) && (
                  <ApproveVehiclePaperwork
                    docTitle="logBook"
                    onDocumentReviewed={() => setShowLogBookForm(false)}
                  />
                )}

              <InnerCard
                onClick={() => {
                  setShowInsuranceCertificateForm(
                    !showInsuranceCertificateForm
                  );
                }}
              >
                <h3>Insurance Certificate</h3>
                {selectedVehicle.insuranceCertificate &&
                selectedVehicle.insuranceCertificate.pendingUrl ? (
                  pending
                ) : selectedVehicle.insuranceCertificate &&
                  selectedVehicle.insuranceCertificate.approved ? (
                  approved
                ) : selectedVehicle.insuranceCertificate &&
                  selectedVehicle.insuranceCertificate.reasonOfDecline ? (
                  <RedP>
                    {selectedVehicle.insuranceCertificate.reasonOfDecline}
                  </RedP>
                ) : (
                  required
                )}
              </InnerCard>
              {showInsuranceCertificateForm &&
                (selectedVehicle.insuranceCertificate?.pendingUrl ||
                  selectedVehicle.insuranceCertificate?.downloadUrl ||
                  selectedVehicle.insuranceCertificate?.expiringUrl) && (
                  <ApproveVehiclePaperwork
                    docTitle="insuranceCertificate"
                    onDocumentReviewed={() =>
                      setShowInsuranceCertificateForm(false)
                    }
                  />
                )}

              <InnerCard
                onClick={() => {
                  setShowMOTCertificateForm(!showMOTCertificateForm);
                }}
              >
                <div>
                  <h3>MOT Certificate</h3>
                  <GreenP>If the car is over 3 years old</GreenP>
                </div>
                {selectedVehicle.MOTCertificate &&
                selectedVehicle.MOTCertificate.pendingUrl ? (
                  pending
                ) : selectedVehicle.MOTCertificate &&
                  selectedVehicle.MOTCertificate.approved ? (
                  approved
                ) : selectedVehicle.MOTCertificate &&
                  selectedVehicle.MOTCertificate.reasonOfDecline ? (
                  <RedP>{selectedVehicle.MOTCertificate.reasonOfDecline}</RedP>
                ) : (
                  optional
                )}
              </InnerCard>
              {showMOTCertificateForm &&
                (selectedVehicle.MOTCertificate?.pendingUrl ||
                  selectedVehicle.MOTCertificate?.downloadUrl ||
                  selectedVehicle.MOTCertificate?.expiringUrl) && (
                  <ApproveVehiclePaperwork
                    docTitle="MOTCertificate"
                    onDocumentReviewed={() => setShowMOTCertificateForm(false)}
                  />
                )}

              <InnerCard
                onClick={() => {
                  setShowHireContractForm(!showHireContractForm);
                }}
              >
                <div>
                  <h3>Hire Contract</h3>
                  <GreenP>If the car is rented</GreenP>
                </div>
                {selectedVehicle.hireContract &&
                selectedVehicle.hireContract.pendingUrl ? (
                  pending
                ) : selectedVehicle.hireContract &&
                  selectedVehicle.hireContract.approved ? (
                  approved
                ) : selectedVehicle.hireContract &&
                  selectedVehicle.hireContract.reasonOfDecline ? (
                  <RedP>{selectedVehicle.hireContract.reasonOfDecline}</RedP>
                ) : (
                  optional
                )}
              </InnerCard>
              {showHireContractForm &&
                (selectedVehicle.hireContract?.pendingUrl ||
                  selectedVehicle.hireContract?.downloadUrl ||
                  selectedVehicle.hireContract?.expiringUrl) && (
                  <ApproveVehiclePaperwork
                    docTitle="hireContract"
                    onDocumentReviewed={() => setShowHireContractForm(false)}
                  />
                )}
            </>
          )}
          {selectedVehicle?.waitingApproval && (
            <>
              <TextDivider>
                <GreenP>WAITING FOR APPROVAL</GreenP>
              </TextDivider>
              <ButtonColumnContainer>
                <Button onClick={resetWaitingHandler}>
                  Remove From Waiting...
                </Button>
              </ButtonColumnContainer>
            </>
          )}
          <TextDivider>
            <GreenP>STATUS</GreenP>
          </TextDivider>
          <ButtonColumnContainer>
            {selectedVehicle?.approved ? (
              <GreenHeader>Active</GreenHeader>
            ) : (
              <RedHeader>NOT Active</RedHeader>
            )}
          </ButtonColumnContainer>
          <ButtonContainer>
            <Button
              buttonType="warning"
              onClick={activationHandler.bind(null, false)}
            >
              Suspend
            </Button>
            <Button
              buttonType="green"
              onClick={activationHandler.bind(null, true)}
            >
              Activate
            </Button>
          </ButtonContainer>
        </Card>
      )}
    </>
  );
};

export default VehicleDetails;
