import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { CurrentUserType } from "../../user/user.types";

// import {
//   AddPHVehicleDocType,
//   ModifyVehicleType,
//   PHVehicleType,
// } from "./vehicles.types";

// Define a type for the slice state
interface UserListState {
  userList: CurrentUserType[] | null;
  userListLoading: boolean;
  userListError: string | null;
}

const INITIAL_STATE: UserListState = {
  userList: null,
  userListLoading: true,
  userListError: null,
};

export const userListSlice = createSlice({
  name: "userList",
  initialState: INITIAL_STATE,
  reducers: {
    //get list of Users waiting to be approved as a Driver
    getDriversToApproveStart(state) {
      state.userListLoading = true;
    },
    getDriversToApproveSuccess(
      state,
      action: PayloadAction<CurrentUserType[]>
    ) {
      state.userListLoading = false;
      state.userListError = null;
      state.userList = action.payload;
    },
    getDriversToApproveFailed(state, action: PayloadAction<string>) {
      state.userListLoading = false;
      state.userListError = action.payload;
    },
    clearUserList(state) {
      state.userList = null;
      state.userListLoading = false;
      state.userListError = null;
    },

    //clear error. We don't export this as other slices have the same name
    //Components use createAction(`${slice}/clearError`) to fire it
    clearError(state) {
      state.userListError = null;
    },
  },
});

export const {
  clearUserList,
  getDriversToApproveFailed,
  getDriversToApproveStart,
  getDriversToApproveSuccess,
} = userListSlice.actions;

export const userListReducer = userListSlice.reducer;
