import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { RootState } from "../../../redux/store";
import Card from "../../UI/card/card.component";
import Spinner from "../../UI/spinner/spinner.component";
import { BackButtonContainer, GreenHeader } from "../../../global.styles";
import Button from "../../UI/button/button.component";
import ErrorMessage from "../../UI/error-message/error-message.component";
import { addLicensingAuthorityStart } from "../../../redux/user/user.slice";

const authorityOptions = [
  { value: "bristol", label: "Bristol City Council" },
  { value: "southGlos", label: "South Gloucestershire Council" },
];

const DriversIntro = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userLoading, currentUser, userError } = useSelector(
    (state: RootState) => state.user
  );

  const [licensingAuthority, setLicensingAuthority] = useState<{
    value: string;
    label: string;
  } | null>(null);

  //we do need a user name
  useEffect(() => {
    if (currentUser && !currentUser.name) {
      navigate("/profile");
    }
  }, [currentUser, navigate]);

  //once we know licensing authority navigate to application/docs area
  useEffect(() => {
    if (
      currentUser &&
      currentUser.driversData &&
      currentUser.driversData.licensingAuthority
    ) {
      navigate("driver-panel");
    }
  }, [currentUser, navigate]);

  const navigateToLoginHandler = () => {
    navigate("/login");
  };

  const startApplicationHandler = () => {
    //update user, add licensingAuthority, the useEffect above will redirect user to application
    if (licensingAuthority && currentUser && currentUser.id) {
      dispatch(
        addLicensingAuthorityStart({
          id: currentUser.id,
          licensingAuthority: licensingAuthority.label,
        })
      );
    }
  };

  return (
    <>
      <Card>
        <GreenHeader>
          To become one of Rufus Drivers you need to hold a Private Hire
          Licence.
        </GreenHeader>
        <br />
        <p>
          Please check the list of UK Councils whose licences we currently
          accept:
        </p>
        <br />
        <ul>
          <li>Bristol City Council</li>
          <li>South Gloucestershire Council</li>
        </ul>
      </Card>
      <Card>
        {userLoading && <Spinner />}
        {userError && <ErrorMessage slice="user">{userError}</ErrorMessage>}

        {!userLoading && !currentUser && (
          <>
            <GreenHeader>
              To proceed with Driver's application, please
            </GreenHeader>
            <br />
            <BackButtonContainer>
              <Button onClick={navigateToLoginHandler}>Log In</Button>
            </BackButtonContainer>
          </>
        )}

        {!userLoading && currentUser && currentUser.name && (
          <>
            <GreenHeader>
              Thanks for Your interest, {currentUser.name}!
            </GreenHeader>
            <p>
              If you are a Private Hire Licence holder, and it has been issued
              by one of the Councils you see on our list, please select your
              Licensing Authority:
            </p>
            <br />
            <Select
              placeholder="Select Your Licensing Authority..."
              defaultValue={licensingAuthority}
              onChange={setLicensingAuthority}
              options={authorityOptions}
            />
            <br />
            {licensingAuthority && (
              <>
                <p>
                  You have selected <strong>{licensingAuthority.label}</strong>{" "}
                  as your Licensing Authority
                </p>
                <p>If that is correct, please:</p>
                <BackButtonContainer>
                  <Button onClick={startApplicationHandler}>
                    Procced With Application
                  </Button>
                </BackButtonContainer>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default DriversIntro;
