import { FC, useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import Card from "../UI/card/card.component";
import {
  BackButtonContainer,
  GreenHeader,
  GreenP,
  RedP,
  SpreadApartArea,
  TextDivider,
} from "../../global.styles";
import {
  BookingType,
  QuoteRequestType,
} from "../../redux/bookings/bookings.types";
import QuoteDetails from "./quote-details";
import Button from "../UI/button/button.component";
import ConfirmBookingForm from "./confirm-booking-form.component";
import { clearNewBookingFlag } from "../../redux/bookings/bookings.slice";

// type BookingFormProps = {
//   guoteRequest: QuoteRequestType;
//   quote: BookingType;
// };

const BookingForm: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [quoteConfirmed, setQuoteConfirmed] = useState(false);

  const { bookingCreated } = useSelector((state: RootState) => state.bookings);

  useEffect(() => {
    if (bookingCreated) {
      setQuoteConfirmed(false);
    }
  }, [bookingCreated]);

  if (
    location.state === null ||
    !location.state.quoteRequest ||
    !location.state.quote
  )
    return <Navigate to="/bookings/quote-form" replace />;

  const quoteRequest = location.state.quoteRequest as QuoteRequestType;
  const quote = location.state.quote as BookingType;

  const clearNewBookingNotification = () => {
    dispatch(clearNewBookingFlag());
    navigate("/bookings");
  };

  return (
    <Card>
      {!quoteConfirmed && !bookingCreated ? (
        <>
          <BackButtonContainer>
            <Link to="/bookings/quote-form" state={{ quoteRequest }}>
              <h3>Edit Booking</h3>
            </Link>
          </BackButtonContainer>
          <QuoteDetails quote={quote} />
          <TextDivider>
            <GreenP> CONFIRM </GreenP>
          </TextDivider>
          <GreenHeader>Are details above correct?</GreenHeader>
          <SpreadApartArea>
            <Link to="/bookings/quote-form" state={{ quoteRequest }}>
              <Button buttonType="inverted">No, Edit Booking</Button>
            </Link>
            <Button onClick={() => setQuoteConfirmed(true)}>
              Yes, Continue
            </Button>
          </SpreadApartArea>
        </>
      ) : quoteConfirmed && !bookingCreated ? (
        <>
          <BackButtonContainer>
            <h3 onClick={() => setQuoteConfirmed(false)}>Go Back</h3>
          </BackButtonContainer>
          <ConfirmBookingForm />
        </>
      ) : (
        <>
          <GreenHeader>Booking created! *</GreenHeader>
          <RedP>
            * This website is still under construction. You can play, but still
            can't book a taxi. Stay tuned - it will be ready soon!
          </RedP>
          <p>As soon as it gets accepted by Driver, we will let you know.</p>
          <BackButtonContainer>
            <Button buttonType="green" onClick={clearNewBookingNotification}>
              Ok
            </Button>
          </BackButtonContainer>
        </>
      )}
    </Card>
  );
};

export default BookingForm;
