import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import { BackButtonContainer, GreenHeader } from "../../global.styles";
import Card from "../UI/card/card.component";
import Spinner from "../UI/spinner/spinner.component";
import InnerCard from "../UI/inner-card/inner-card.component";
import BookingStatus from "./booking-status/booking-status.component";

const BookingsList = () => {
  const navigate = useNavigate();
  const { bookingsLoading, bookings } = useSelector(
    (state: RootState) => state.bookings
  );
  return (
    <Card>
      <BackButtonContainer>
        <Link to="/">
          <h3>Go Back</h3>
        </Link>
      </BackButtonContainer>
      {bookingsLoading && <Spinner />}
      {!bookings || bookings.length === 0 ? (
        <GreenHeader>There are no active trips booked by You...</GreenHeader>
      ) : (
        bookings.map((booking) => {
          //server stores dates in UTC, it doesn't care about winter/summer time
          //if you use server timestamp to interract with client timestamps convert it like this:
          // const serverPickupTime = new Date(booking.pickupTimestamp!);
          // const clientPickupTimestamp = serverPickupTime.setTime(
          //   serverPickupTime.getTime() +
          //     serverPickupTime.getTimezoneOffset() * 60 * 1000
          // );
          return (
            <InnerCard
              key={booking.id}
              onClick={() => {
                navigate(`booking-details/${booking.id}`);
              }}
            >
              <div>
                <p>{booking.date}</p>
                <p>
                  <strong>{booking.time}</strong>
                </p>
              </div>
              <BookingStatus booking={booking} />
            </InnerCard>
          );
        })
      )}
    </Card>
  );
};

export default BookingsList;
