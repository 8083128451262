import { FooterContainer, FooterLink } from "./footer.styles";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLink to="/terms">Terms and Conditions</FooterLink>
      <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
      <FooterLink to="/contact">Contact</FooterLink>
      <p>Waway LTD &copy; 2022 - {new Date().getFullYear()}</p>
    </FooterContainer>
  );
};

export default Footer;
