import styled from "styled-components";
import { colors } from "../../style-variables";

export const EmailLoginButton = styled.button`
  min-width: 10rem;
  width: 100%;
  height: 3rem;
  padding: 0 2rem;
  font-size: 1rem;
  background-color: ${colors.primary};
  color: ${colors.background};
  text-transform: uppercase;
  font-family: inherit;
  font-weight: bolder;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover,
  &:focus {
    background-color: ${colors.background};
    color: ${colors.primary};
    border: 2px solid ${colors.primary};
  }
`;
