import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import { getDriversToApproveStart } from "../../redux/admin/user-list/userList.slice";
import Card from "../UI/card/card.component";
import Spinner from "../UI/spinner/spinner.component";
import ErrorMessage from "../UI/error-message/error-message.component";
import { GreenHeader, GreenP } from "../../global.styles";
import InnerCard from "../UI/inner-card/inner-card.component";

const PaperworkListForReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userListLoading, userListError, userList } = useSelector(
    (state: RootState) => state.userList
  );

  useEffect(() => {
    dispatch(getDriversToApproveStart());
  }, [dispatch]);

  const navigateToDetailsHandler = (userId: string) => {
    navigate(`/boss/user-details/${userId}`);
  };

  return (
    <Card>
      {userListLoading && <Spinner />}
      {userListError && (
        <ErrorMessage slice="userList">{userListError}</ErrorMessage>
      )}
      <GreenHeader>
        List of Users with the Paperwork to be approved:
      </GreenHeader>
      {userList && userList.length === 0 && (
        <GreenP>No users waiting to be approved</GreenP>
      )}
      {userList &&
        userList.length > 0 &&
        userList.map((user) => (
          <InnerCard
            key={user.id}
            onClick={navigateToDetailsHandler.bind(null, user.id)}
          >
            <h3>{user.name}</h3>
            <p>{user.driversData?.licensingAuthority}</p>
          </InnerCard>
        ))}
    </Card>
  );
};

export default PaperworkListForReview;
