import { createSelector } from "reselect";

import { RootState } from "../store";

const selectVehiclesReducer = (state: RootState) => state.vehicles;

export const selectVehiclesIsLoading = createSelector(
  [selectVehiclesReducer],
  (vehiclesSlice) => vehiclesSlice.vehiclesLoading
);

export const selectVehicles = createSelector(
  [selectVehiclesReducer],
  (vehiclesSlice) => vehiclesSlice.vehicles
);

export const selectSelectedVehicle = createSelector(
  [selectVehicles],
  (vehicles) => {
    if (vehicles && vehicles?.length > 0) {
      const selectedVehicle = vehicles.find((vehicle) => vehicle.selected);
      return selectedVehicle;
    } else {
      return null;
    }
  }
);
