import { FC } from "react";

import { InnerCardContainer } from "./inner-card.styles";

type InnerCardProps = {
  children?: React.ReactNode;
  onClick?: () => void;
};

const InnerCard: FC<InnerCardProps> = ({ children, onClick }) => {
  return <InnerCardContainer onClick={onClick}>{children}</InnerCardContainer>;
};

export default InnerCard;
