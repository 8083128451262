import Card from "../../components/UI/card/card.component";
import { BackButtonContainer, GreenHeader, GreenP } from "../../global.styles";

const Terms = () => {
  return (
    <Card>
      <GreenHeader>Terms & Conditions</GreenHeader>
      <BackButtonContainer>
        <GreenP>
          Effective Date: <strong>25th of May 2024</strong>
        </GreenP>
      </BackButtonContainer>

      <h3>1. Introduction</h3>
      <p>
        Welcome to Rufus Taxi Service of Waway LTD (further referred to as
        "Company", "we", "our", "us"). By using our taxi booking website and
        services, You agree to comply with and be bound by the following terms
        and conditions ("Terms"). Please review the following terms carefully.
        If You do not agree to these terms, You should not use our services.
      </p>

      <h3>2. Nature of Service</h3>
      <p>
        Rufus Taxi Service acts as a "trip organizing agency", facilitating the
        booking of taxi services between Passengers ("Passenger", "You") and
        independent Drivers ("Driver"). All payments for the trips are made
        directly to the Driver.
      </p>

      <h3>3. Payments</h3>
      <p>
        Drivers are required to have card readers to accept cashless payments.
        The quoted price provided at the time of booking is the amount to be
        paid directly to the Driver. Any additional charges for tolls or parking
        encountered during the trip will be added to the quoted price.
      </p>

      <h3>4. Waiting Time Charges</h3>
      <p>
        For airport pick-up bookings, any waiting time over 30 minutes will be
        charged at a rate of £15 per half hour. For all other bookings, any
        waiting time over 10 minutes will be charged at the same rate.
      </p>

      <h3>5. Special Driver Requests</h3>
      <p>
        If a Passenger requests a particular Driver, the Passenger's phone
        number will be shared with the requested Driver, and the Driver's phone
        number will be shared with the Passenger to facilitate communication.
      </p>

      <h3>6. General Booking Requests</h3>
      <p>
        Passengers have the option to post a booking request without specifying
        a particular Driver. In such cases, the request will be posted on the
        Drivers' "wall," and the Passenger's phone number will be shared with
        all approved Drivers. This allows Drivers to contact the Passenger for
        any additional information about the booking request before accepting
        it.
      </p>

      <h3>7. Licensing and Compliance</h3>
      <p>
        Our company will do its best to ensure that the Drivers and their
        vehicles are licensed, insured, and comply with all relevant legislation
        and trade standards.
      </p>

      <h3>8. User Conduct</h3>
      <p>By using our services, you agree to:</p>
      <ul>
        <li>
          The way we deal with Your data disclosed in our{" "}
          <a href="https://rufus.taxi/privacy-policy">
            <b>Privacy Policy</b>
          </a>
        </li>
        <li>Provide accurate and complete information at all times.</li>
        <li>Comply with all applicable laws and regulations.</li>
        <li>Respect the privacy and personal information of other users.</li>
        <li>
          Not engage in any conduct that is harmful, unlawful, or disruptive to
          our services or other users.
        </li>
      </ul>

      <h3>9. Limitation of Liability</h3>
      <p>
        To the maximum extent permitted by law, Waway LTD shall not be liable
        for any indirect, incidental, special, consequential, or punitive
        damages, or any loss of profits or revenues, whether incurred directly
        or indirectly, or any loss of data, use, goodwill, or other intangible
        losses, resulting from:
      </p>
      <ul>
        <li>Your use of or inability to use our services.</li>
        <li>
          Any unauthorized access to or use of our servers and/or any personal
          information stored therein.
        </li>
        <li>
          Any interruption or cessation of transmission to or from our services.
        </li>
        <li>
          Any bugs, viruses, trojan horses, or the like that may be transmitted
          to or through our services by any third party.
        </li>
        <li>
          Any errors or omissions in any content or for any loss or damage
          incurred as a result of the use of any content posted, emailed,
          transmitted, or otherwise made available through our services.
        </li>
      </ul>

      <h3>10. Modifications to Terms</h3>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, we will provide at least
        30 days' notice prior to any new terms taking effect. What constitutes a
        material change will be determined at our sole discretion. By continuing
        to access or use our services after any revisions become effective, you
        agree to be bound by the revised terms.
      </p>

      <h3>11. Contact Us</h3>
      <p>If you have any questions about these Terms, please contact us at:</p>
      <p>
        <strong>Email:</strong> wawayltd@protonmail.com
      </p>
      <p>
        <strong>Address:</strong> <br />
        Waway LTD
        <br />
        2530 Aztec West (Ground Floor)
        <br />
        Almondsbury
        <br />
        BS32 4AW
        <br />
        Bristol
        <br />
        UK
      </p>
    </Card>
  );
};

export default Terms;
