import { all, call } from "typed-redux-saga/macro";

import { userSagas } from "./user/user.saga";
import { vehiclesSagas } from "./vehicles/vehicles.saga";
import { userListSagas } from "./admin/user-list/userList.saga";
import { selectedUserSagas } from "./admin/selected-user/selectedUser.saga";
import { vehicleListSagas } from "./admin/vehicle-list/vehicleList.saga";
import { selectedVehicleSagas } from "./admin/selected-vehicle/selectedVehicle.saga";
import { bookingsSagas } from "./bookings/bookings.saga";
// import { bookingDetailsSagas } from "./booking-details/bookingDetails.saga";
import { approvedDriverSagas } from "./approvedDRiver/approved-driver.saga";

export function* rootSaga() {
  yield* all([
    call(userSagas),
    call(vehiclesSagas),
    call(bookingsSagas),
    // call(bookingDetailsSagas),
    call(approvedDriverSagas),
    call(userListSagas),
    call(selectedUserSagas),
    call(vehicleListSagas),
    call(selectedVehicleSagas),
  ]);
}
