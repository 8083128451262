import { User } from "firebase/auth";
import { takeLatest, all, call, put } from "typed-redux-saga/macro";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  addDvlaCheckCode,
  addLicensingAuthority,
  addUserDriverDoc,
  createUserDocumentFromAuth,
  getCurrentUser,
  signInWithFacebookPopup,
  signInWithGooglePopup,
  signOutUser,
  updateUserName,
  updateUserPhone,
} from "../../firebase/user";
import {
  addDVLACodeFailed,
  addDVLACodeSuccess,
  addDriverDocumentFailed,
  addDriverDocumentSuccess,
  addLicensingAuthorityFailed,
  addLicensingAuthoritySuccess,
  signInFailed,
  signInSuccess,
  signOutFailed,
  signOutSuccess,
  updateUserNameFailed,
  updateUserNameSuccess,
  updateUserPhoneFailed,
  updateUserPhoneSuccess,
} from "./user.slice";
import {
  AddDVLACodeType,
  AddDriverDocType,
  AddLicenseAuthorityType,
  UpdateNameType,
  UpdatePhoneType,
} from "./user.types";
import { clearUserList } from "../admin/user-list/userList.slice";
import { clearSelectedUser } from "../admin/selected-user/selectedUser.slice";
import { clearVehiclesList } from "../admin/vehicle-list/vehicleList.slice";
import { clearSelectedVehicle } from "../admin/selected-vehicle/selectedVehicle.slice";
import { clearBookingDetails } from "../booking-details/bookingDetails.slice";

//functions
function* getSnapshotFromUserAuth(userAuth: User) {
  try {
    const fetchedUser = yield* call(createUserDocumentFromAuth, userAuth);
    if (fetchedUser) {
      yield* put(signInSuccess(fetchedUser));
    }
  } catch (error) {
    if (error instanceof Error) {
      if (
        error.message ===
        "Firebase: Error (auth/account-exists-with-different-credential)."
      ) {
        yield* put(
          signInFailed(
            "Account with this email already exists with different credentials"
          )
        );
      } else {
        yield* put(signInFailed(error.message));
      }
    } else {
      yield* put(signInFailed("Error fetching user data"));
    }
  }
}

function* isUserAuthenticated() {
  try {
    const userAuth = yield* call(getCurrentUser);
    if (!userAuth) {
      yield* put(signOutSuccess());
      return;
    }
    yield* call(getSnapshotFromUserAuth, userAuth);
  } catch (error) {
    if (error instanceof Error) {
      yield* put(signInFailed(error.message));
    } else {
      yield* put(signInFailed("Problem Signing In!"));
    }
  }
}

export function* signInWithFacebook() {
  try {
    const { user } = yield* call(signInWithFacebookPopup);
    yield* call(getSnapshotFromUserAuth, user);
  } catch (error) {
    if (error instanceof Error) {
      yield* put(signInFailed(error.message));
    } else {
      yield* put(signInFailed("Error Signing In with FACEBOOK"));
    }
  }
}

export function* signInWithGoogle() {
  try {
    const { user } = yield* call(signInWithGooglePopup);
    yield* call(getSnapshotFromUserAuth, user);
  } catch (error) {
    if (error instanceof Error) {
      yield* put(signInFailed(error.message));
    } else {
      yield* put(signInFailed("Error Signing In with GOOGLE"));
    }
  }
}

function* signOut() {
  try {
    yield all([
      //user
      put(clearBookingDetails()),//currently not used
      //admin
      put(clearUserList()),
      put(clearSelectedUser()),
      put(clearVehiclesList()),
      put(clearSelectedVehicle()),
      put(signOutSuccess()),
    ]);
    yield* call(signOutUser);
  } catch (error) {
    if (error instanceof Error) {
      yield* put(signOutFailed(error.message));
    } else {
      yield* put(signOutFailed("Sign Out Failed..."));
    }
  }
}

function* updateName({ payload: { id, name } }: PayloadAction<UpdateNameType>) {
  try {
    // const fetchedUser =
    yield* call(updateUserName, id, name);
    // if (fetchedUser) {
    // yield* put(updateUserNameSuccess(fetchedUser));
    yield* put(updateUserNameSuccess());
    // }
  } catch (error) {
    if (error instanceof Error) {
      yield* put(updateUserNameFailed(error.message));
    } else {
      yield* put(updateUserNameFailed("Name Update Failed..."));
    }
  }
}

function* updatePhone({
  payload: { id, phoneNumber },
}: PayloadAction<UpdatePhoneType>) {
  try {
    yield* call(updateUserPhone, id, phoneNumber);
    yield* put(updateUserPhoneSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield* put(updateUserPhoneFailed(error.message));
    } else {
      yield* put(updateUserPhoneFailed("Phone Number Update Failed..."));
    }
  }
}

function* addAuthority({
  payload: { id, licensingAuthority },
}: PayloadAction<AddLicenseAuthorityType>) {
  try {
    yield* call(addLicensingAuthority, id, licensingAuthority);
    yield* put(addLicensingAuthoritySuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield* put(addLicensingAuthorityFailed(error.message));
    } else {
      yield* put(
        addLicensingAuthorityFailed("Adding Licensing Authority Failed...")
      );
    }
  }
}

function* addDriverDocument({
  payload: { id, docType, downloadUrl },
}: PayloadAction<AddDriverDocType>) {
  try {
    yield* call(addUserDriverDoc, id, docType, downloadUrl);
    yield* put(addDriverDocumentSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield* put(addDriverDocumentFailed(error.message));
    } else {
      yield* put(addDriverDocumentFailed("Adding New Document Failed..."));
    }
  }
}

function* addDVLACode({
  payload: { id, checkCode },
}: PayloadAction<AddDVLACodeType>) {
  try {
    yield* call(addDvlaCheckCode, id, checkCode);
    yield* put(addDVLACodeSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield* put(addDVLACodeFailed(error.message));
    } else {
      yield* put(addDVLACodeFailed("Registering New Vehicle Failed..."));
    }
  }
}

//listeners
function* onCheckUserSession() {
  yield* takeLatest("user/checkUserSession", isUserAuthenticated);
}

function* onSignOutStart() {
  yield* takeLatest("user/signOutStart", signOut);
}

export function* onFacebookSignInStart() {
  yield* takeLatest("user/facebookSignInStart", signInWithFacebook);
}

export function* onGoogleSignInStart() {
  yield* takeLatest("user/googleSignInStart", signInWithGoogle);
}

function* onUpdateNameStart() {
  yield* takeLatest("user/updateUserNameStart", updateName);
}

function* onUpdatePhoneStart() {
  yield* takeLatest("user/updateUserPhoneStart", updatePhone);
}

function* onAddLicensingAuthorityStart() {
  yield* takeLatest("user/addLicensingAuthorityStart", addAuthority);
}

function* onAddDriverDocumentStart() {
  yield* takeLatest("user/addDriverDocumentStart", addDriverDocument);
}

function* onAddDVLACodeStart() {
  yield* takeLatest("user/addDVLACodeStart", addDVLACode);
}

export function* userSagas() {
  yield* all([
    call(onAddDriverDocumentStart),
    call(onAddDVLACodeStart),
    call(onAddLicensingAuthorityStart),
    call(onCheckUserSession),
    call(onFacebookSignInStart),
    call(onGoogleSignInStart),
    call(onSignOutStart),
    call(onUpdateNameStart),
    call(onUpdatePhoneStart),
  ]);
}
