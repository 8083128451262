import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { FaDog } from "react-icons/fa";

import Card from "../../UI/card/card.component";
import { RootState } from "../../../redux/store";
import Spinner from "../../UI/spinner/spinner.component";
import {
  AmberP,
  BackButtonContainer,
  GreenHeader,
  GreenP,
  PushToLeftColumn,
  PushToRightColumn,
  RedHeader,
  SpaceAroundArea,
} from "../../../global.styles";
import Button from "../../UI/button/button.component";
import FormInput from "../../UI/form-input/form-input.component";
import RadioButton from "../../UI/radio-buttons/radio-button";
import {
  defineVehicleType,
  getTodaysDateTimestamp,
  transformTimestampDateOnly,
} from "../../../util-functions";
import { requestGeoPoint } from "../../../firebase/bookings";
import { firestore } from "../../../firebase/config";
import { BookingType } from "../../../redux/bookings/bookings.types";
import InnerCard from "../../UI/inner-card/inner-card.component";

const PublicJobList = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const driversHash = searchParams.get("driversHash");
  const area = searchParams.get("area");
  const date = searchParams.get("date");

  const { userLoading, userError, currentUser } = useSelector(
    (state: RootState) => state.user
  );
  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  const [searchError, setSearchError] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchComplete, setSearchComplete] = useState(false);
  const [searchAddress, setSearchAddress] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [searchArea, setSearchArea] = useState("5");
  const [searchResult, setSearchResult] = useState<BookingType[]>([]);

  const timeStampNow = new Date().getTime();

  useEffect(() => {
    //this boolean is used for cleanup function
    //explanation - https://devtrium.com/posts/async-functions-useeffect
    //or - https://blog.logrocket.com/understanding-react-useeffect-cleanup-function/
    let isSubscribed = true;
    //check if we have searching parameters
    if (approvedDriver && driversHash && area && date) {
      //set drivers GeoHash short based on the selected area
      const driversGeoHashShort = driversHash.substring(0, Number(area));
      //function that fetches the results
      const searchJobs = async () => {
        setSearchLoading(true);
        const jobsColRef = collection(firestore, "bookings");
        let jobsQuery = query(
          jobsColRef,
          where("driverNumber", "==", ""),
          where("searchDate", "==", date),
          where(
            `pickupHashNeighbours${area}`,
            "array-contains",
            driversGeoHashShort
          ),
          where("vehicleType", "in", approvedDriver.selectedVehicleTypes),
          where("notCovered", "==", false),
          orderBy("pickupTimestamp"),
          limit(32)
        );
        if (!approvedDriver.petFriendly) {
          jobsQuery = query(jobsQuery, where("hasPet", "==", false));
        }
        const jobsSnapshot = await getDocs(jobsQuery);
        const fetchedJobs = jobsSnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
            createdAt: doc.data().createdAt.toMillis(),
          } as BookingType;
        });
        if (isSubscribed) {
          setSearchLoading(false);
          setSearchError("");
          setSearchComplete(true);
          setSearchResult(fetchedJobs);
        }
      };
      searchJobs().catch((error) => {
        setSearchError("Error with a jobs request");
        // console.log("====================================");
        // console.log(error);
        // console.log("====================================");
        setSearchLoading(false);
        setSearchComplete(true);
      });
    }
    return () => {
      isSubscribed = false;
    };
  }, [approvedDriver, area, date, driversHash, searchDate]);

  const searchAddressChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchAddress(event.target.value);
  };
  const searchDateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchDate(event.target.value);
  };
  const searchAreaChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchArea(event.target.value);
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!approvedDriver) return;
    if (new Date(searchDate).getTime() < getTodaysDateTimestamp()) {
      setSearchError(
        "Can't search for a job in past! Time travel is not invented yet :)"
      );
      return;
    }
    if (searchArea !== "3" && searchArea !== "4" && searchArea !== "5") {
      setSearchError("Trouble with search area radius...");
      return;
    }
    if (searchAddress === "" || typeof searchAddress !== "string") {
      setSearchError("Please enter the address of the search area");
      return;
    }
    if (searchDate === "" || typeof searchDate !== "string") {
      setSearchError("Please enter a date");
      return;
    }
    setSearchLoading(true);
    //request drivers GeoHash
    let driversGeoHash: string;
    try {
      const driversGeoData = await requestGeoPoint(searchAddress);
      driversGeoHash = driversGeoData.geoHash;
    } catch (error) {
      setSearchError(
        "Problem with the search address. Please be more specific."
      );
      return;
    }
    //send to same url with query params
    navigate(
      `/drivers/public-job-list?driversHash=${driversGeoHash}&area=${searchArea}&date=${searchDate}`
    );
  };

  if (userLoading || approvedDriverLoading || searchLoading) return <Spinner />;

  return (
    <Card>
      <BackButtonContainer>
        <Link to="/drivers/driver-panel">
          <h3>&larr; Driver Menu</h3>
        </Link>
      </BackButtonContainer>
      {userError && <RedHeader>{userError}</RedHeader>}
      {approvedDriverError && <RedHeader>{approvedDriverError}</RedHeader>}
      {currentUser && (!currentUser.readyToDrive || !approvedDriver) && (
        <>
          <GreenHeader>Public Job List</GreenHeader>
          <AmberP>
            This section is available only to Drivers with approved paperwork
          </AmberP>
          <BackButtonContainer>
            <Button onClick={() => navigate("/drivers/application")}>
              Check Driver Profile
            </Button>
          </BackButtonContainer>
        </>
      )}
      {currentUser &&
        currentUser.readyToDrive &&
        approvedDriver &&
        !searchComplete && (
          <>
            <GreenHeader>Search for unassigned jobs</GreenHeader>

            {searchError && <RedHeader>{searchError}</RedHeader>}
            <form onSubmit={submitHandler}>
              <GreenP>
                Please enter the postcode or the address of the area you are
                looking for a job
              </GreenP>
              <FormInput
                label="Search location address"
                id="job_search_address"
                type="text"
                onChange={searchAddressChangeHandler}
                value={searchAddress}
                required
              />
              <SpaceAroundArea>
                <GreenP>Select date:</GreenP>
                <FormInput
                  label=""
                  id="search-date"
                  type="date"
                  onChange={searchDateChangeHandler}
                  value={searchDate}
                  required
                />
              </SpaceAroundArea>
              <GreenP>Select search radius:</GreenP>
              <RadioButton
                label="4 miles"
                id="4miles"
                name="searchArea"
                value="5"
                defaultChecked
                onChange={searchAreaChangeHandler}
              />
              <RadioButton
                label="20 miles"
                id="20miles"
                name="searchArea"
                value="4"
                onChange={searchAreaChangeHandler}
              />
              <RadioButton
                label="140 miles"
                id="140miles"
                name="searchArea"
                value="3"
                onChange={searchAreaChangeHandler}
              />

              {searchError && <RedHeader>{searchError}</RedHeader>}
              <BackButtonContainer>
                <Button type="submit">Search Jobs</Button>
              </BackButtonContainer>
            </form>
          </>
        )}
      {currentUser &&
        currentUser.readyToDrive &&
        approvedDriver &&
        searchComplete && (
          <>
            {searchResult.length === 0 && !searchError ? (
              <>
                <GreenHeader>
                  No results for{" "}
                  {transformTimestampDateOnly(new Date(searchDate).getTime())}{" "}
                  in the area you are looking for...
                </GreenHeader>
                <GreenP>
                  Please try another date, or search in a different different
                  area
                </GreenP>
              </>
            ) : searchResult.length > 0 && !searchError ? (
              <>
                <GreenHeader>Results for {searchResult[0].date}</GreenHeader>
                {searchResult.map((job) => (
                  <InnerCard
                    key={job.id}
                    onClick={() =>
                      navigate("/drivers/public-job-details", {
                        state: {
                          job,
                          searchParams: {
                            driversHash,
                            area,
                            date,
                          },
                        },
                      })
                    }
                  >
                    <PushToLeftColumn>
                      <h3>{job.pickupPostCode}</h3>
                      <p>{job.distanceInMiles} miles trip</p>
                      {timeStampNow > Number(job.pickupTimestamp) && (
                        <AmberP>RUNNING LATE!</AmberP>
                      )}
                    </PushToLeftColumn>
                    <PushToRightColumn>
                      <h3>£{job.quotedPrice}</h3>
                      <p>{defineVehicleType(job.vehicleType)}</p>
                      {job.hasPet && (
                        <GreenP>
                          <FaDog /> Pet
                        </GreenP>
                      )}
                    </PushToRightColumn>
                  </InnerCard>
                ))}
              </>
            ) : (
              searchError && <RedHeader>{searchError}</RedHeader>
            )}
            <BackButtonContainer>
              <Button
                onClick={() => {
                  setSearchComplete(false);
                  setSearchAddress("");
                  setSearchArea("5");
                  setSearchDate("");
                  navigate("/drivers/public-job-list");
                }}
              >
                Search Again
              </Button>
            </BackButtonContainer>
          </>
        )}
    </Card>
  );
};

export default PublicJobList;
