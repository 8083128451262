import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  ApproveDriverDocType,
  ChangeDriverStatusType,
  CurrentUserType,
  RejectDriverDocType,
  SetLicenceNumbersType,
  VerifyDvlaCodeType,
} from "../../user/user.types";

// Define a type for the slice state
interface UserState {
  selectedUser: CurrentUserType | null;
  selectedUserLoading: boolean;
  selectedUserError: string | null;
}

const INITIAL_STATE: UserState = {
  selectedUser: null,
  selectedUserLoading: true,
  selectedUserError: null,
};

export const selectedUserSlice = createSlice({
  name: "selectedUser",
  initialState: INITIAL_STATE,
  reducers: {
    //Get User by ID
    getUserByIdStart(state, action: PayloadAction<string>) {
      state.selectedUserLoading = true;
    },
    getUserByIdSuccess(state, action: PayloadAction<CurrentUserType>) {
      state.selectedUserLoading = false;
      state.selectedUserError = null;
      state.selectedUser = action.payload;
    },
    getUserByIdFailed(state, action: PayloadAction<string>) {
      state.selectedUserLoading = false;
      state.selectedUser = null;
      state.selectedUserError = action.payload;
    },
    //Drivers
    //Approve Drivers paperwork
    approveDriversPaperworkStart(
      state,
      action: PayloadAction<ApproveDriverDocType>
    ) {
      state.selectedUserLoading = true;
    },
    approveDriversPaperworkSuccess(
      state,
      action: PayloadAction<CurrentUserType>
    ) {
      state.selectedUserLoading = false;
      state.selectedUserError = null;
      state.selectedUser = action.payload;
    },
    approveDriversPaperworkFailed(state, action: PayloadAction<string>) {
      state.selectedUserLoading = false;
      state.selectedUserError = action.payload;
    },
    //Reject Drivers paperwork
    rejectDriversPaperworkStart(
      state,
      action: PayloadAction<RejectDriverDocType>
    ) {
      state.selectedUserLoading = true;
    },
    rejectDriversPaperworkSuccess(
      state,
      action: PayloadAction<CurrentUserType>
    ) {
      state.selectedUserLoading = false;
      state.selectedUserError = null;
      state.selectedUser = action.payload;
    },
    rejectDriversPaperworkFailed(state, action: PayloadAction<string>) {
      state.selectedUserLoading = false;
      state.selectedUserError = action.payload;
    },
    //Verify DVLA Check Code
    verifyDvlaCodeStart(state, action: PayloadAction<VerifyDvlaCodeType>) {
      state.selectedUserLoading = true;
    },
    verifyDvlaCodeSuccess(state, action: PayloadAction<CurrentUserType>) {
      state.selectedUserLoading = false;
      state.selectedUserError = null;
      state.selectedUser = action.payload;
    },
    verifyDvlaCodeFailed(state, action: PayloadAction<string>) {
      state.selectedUserLoading = false;
      state.selectedUserError = action.payload;
    },
    //Verify DVLA Check Code
    setLicenceNumbersStart(
      state,
      action: PayloadAction<SetLicenceNumbersType>
    ) {
      state.selectedUserLoading = true;
    },
    setLicenceNumbersSuccess(state, action: PayloadAction<CurrentUserType>) {
      state.selectedUserLoading = false;
      state.selectedUserError = null;
      state.selectedUser = action.payload;
    },
    setLicenceNumbersFailed(state, action: PayloadAction<string>) {
      state.selectedUserLoading = false;
      state.selectedUserError = action.payload;
    },
    //Reset vehicle "waiting for approval" status
    resetDriverApprovalWaitingStart(state, action: PayloadAction<string>) {
      state.selectedUserLoading = true;
    },
    resetDriverApprovalWaitingSuccess(
      state,
      action: PayloadAction<CurrentUserType>
    ) {
      state.selectedUserLoading = false;
      state.selectedUserError = null;
      state.selectedUser = action.payload;
    },
    resetDriverApprovalWaitingFailed(state, action: PayloadAction<string>) {
      state.selectedUserLoading = false;
      state.selectedUserError = action.payload;
    },
    //Change Driver status - Activate or Suspend
    changeDriverStatusStart(
      state,
      action: PayloadAction<ChangeDriverStatusType>
    ) {
      state.selectedUserLoading = true;
    },
    changeDriverStatusSuccess(state, action: PayloadAction<CurrentUserType>) {
      state.selectedUserLoading = false;
      state.selectedUserError = null;
      state.selectedUser = action.payload;
    },
    changeDriverStatusFailed(state, action: PayloadAction<string>) {
      state.selectedUserLoading = false;
      state.selectedUserError = action.payload;
    },
    clearSelectedUser(state) {
      state.selectedUser = null;
      state.selectedUserLoading = false;
      state.selectedUserError = null;
    },

    //clear error. We don't export this as other slices have the same name
    //Components use createAction(`${slice}/clearError`) to fire it
    clearError(state) {
      state.selectedUserError = null;
    },
  },
});

export const {
  approveDriversPaperworkFailed,
  approveDriversPaperworkStart,
  approveDriversPaperworkSuccess,
  getUserByIdFailed,
  getUserByIdStart,
  getUserByIdSuccess,
  rejectDriversPaperworkFailed,
  rejectDriversPaperworkStart,
  rejectDriversPaperworkSuccess,
  setLicenceNumbersFailed,
  setLicenceNumbersStart,
  setLicenceNumbersSuccess,
  verifyDvlaCodeFailed,
  verifyDvlaCodeStart,
  verifyDvlaCodeSuccess,
  resetDriverApprovalWaitingFailed,
  resetDriverApprovalWaitingStart,
  resetDriverApprovalWaitingSuccess,
  changeDriverStatusFailed,
  changeDriverStatusStart,
  changeDriverStatusSuccess,
  clearSelectedUser,
} = selectedUserSlice.actions;

export const selectedUserReducer = selectedUserSlice.reducer;
