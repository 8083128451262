import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import FormInput from "../UI/form-input/form-input.component";
import Button from "../UI/button/button.component";
import {
  ButtonColumnContainer,
  GreenHeader,
  GreenP,
  RedHeader,
} from "../../global.styles";
import useAutosizeTextArea from "../UI/textarea/useAutosizedTextArea";
import { StyledTextArea } from "../UI/textarea/textarea.component";
import { SpacerVertical } from "../UI/spacers/spacers.component";
import { createBookingStart } from "../../redux/bookings/bookings.slice";
import Spinner from "../UI/spinner/spinner.component";

const ConfirmBookingForm = () => {
  const dispatch = useDispatch();

  const { bookingsError, bookingsLoading, bookingCreated } = useSelector(
    (state: RootState) => state.bookings
  );

  const [passengerName, setPassengerName] = useState("");
  const [passengerPhone, setPassengerPhone] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [driverNote, setDriverNote] = useState("");

  const [submitError, setSubmitError] = useState("");

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(textAreaRef.current, driverNote);

  useEffect(() => {
    if (bookingsError) {
      setSubmitError(bookingsError);
    }
    if (bookingCreated) {
      setPassengerName("");
      setPassengerPhone("");
      setDriverNumber("");
      setDriverNote("");
    }
  }, [bookingCreated, bookingsError]);

  const passengerNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPassengerName(event.target.value);
  };
  const passengerPhoneChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setPassengerPhone(event.target.value);
  };
  const driverNumberChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const maxLength = 11;
    setDriverNumber(
      event.target.value.toUpperCase().replaceAll(" ", "").slice(0, maxLength)
    );
  };
  const driverNoteChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDriverNote(event.target.value);
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //check is passengers' name and phone number are provided
    if (!passengerName || !passengerPhone) {
      setSubmitError("Passengers phone or name is missing.");
      return;
    }
    const bookingInfo = {
      passengerName,
      passengerPhone,
      driverNote,
      driverNumber,
    };
    dispatch(createBookingStart(bookingInfo));
  };

  return (
    <>
      {bookingsLoading ? (
        <Spinner />
      ) : (
        <form onSubmit={submitHandler}>
          {submitError ? (
            <RedHeader>{submitError}</RedHeader>
          ) : (
            <GreenHeader>Almost done:</GreenHeader>
          )}
          <GreenP>
            If You wish to <b>request a particular Driver</b>, please enter
            Driver Number bellow (NOT the phone number!).
          </GreenP>
          <GreenP>
            Otherwise leave it empty, and Your request will be available to all
            of our Drivers.
          </GreenP>
          <FormInput
            label="Driver Number (optional)"
            id="driverNumber"
            type="text"
            onChange={driverNumberChangeHandler}
            value={driverNumber}
            inputMode="numeric"
          />
          <SpacerVertical $paddingRem={2.5}>
            <GreenP>
              Below, You can enter any additional notes or requests to Your
              Driver (optional):
            </GreenP>
            <br />
            <StyledTextArea
              id="review-text"
              onChange={driverNoteChangeHandler}
              placeholder="Note to Driver..."
              ref={textAreaRef}
              rows={1}
              value={driverNote}
            />
          </SpacerVertical>
          <br />
          <br />
          <GreenP>
            Please provide the <b>name</b> and contact <b>phone number</b> for
            the Passenger. By doing so, you accept that it will be{" "}
            <b>shared with the Driver</b>.
          </GreenP>
          <FormInput
            label="Passenger's Name"
            id="passengerName"
            type="text"
            onChange={passengerNameChangeHandler}
            value={passengerName}
            inputMode="tel"
            required
          />
          <FormInput
            label="Passenger's Phone Number"
            id="passengerPhone"
            type="text"
            onChange={passengerPhoneChangeHandler}
            value={passengerPhone}
            inputMode="tel"
            required
          />
          {submitError && <RedHeader>{submitError}</RedHeader>}
          <ButtonColumnContainer>
            <Button type="submit">Submit Booking</Button>
          </ButtonColumnContainer>
        </form>
      )}
    </>
  );
};

export default ConfirmBookingForm;
