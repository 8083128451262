import { styled } from "styled-components";
import { colors } from "../../../style-variables";

export const CardContainer = styled.div`
  margin: 1rem;
  padding: 2rem;
  width: 90%;
  max-width: 40rem;
  /* min-width: 30rem; */
  background-color: ${colors.background};
  border-radius: 0.5rem;
  box-shadow: 0.25rem 0.25rem 0.5rem ${colors.dark};
  /* border: 1px solid black; */
`;
