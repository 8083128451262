import { ChangeEvent, FC, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";

import FormInput from "../../UI/form-input/form-input.component";
import { ButtonContainer } from "../../UI/button/button.styles";
import Button from "../../UI/button/button.component";
import { updateUserPhoneStart } from "../../../redux/user/user.slice";

type UpdatePhoneProps = {
  userId: string;
  currentPhoneNumber: string;
  onCancelEdit: () => void;
};

const UpdatePhoneForm: FC<UpdatePhoneProps> = ({
  userId,
  currentPhoneNumber,
  onCancelEdit,
}) => {
  const dispatch = useDispatch();

  const [phoneNumber, setPhoneNumber] = useState(currentPhoneNumber);

  const formChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setPhoneNumber(event.target.value);

  const handleUpdateUserPhone = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //dispatch phone update
    const id = userId;
    //only dispatch an action if phoneNumber has changed
    if (currentPhoneNumber !== phoneNumber) {
      dispatch(updateUserPhoneStart({ id, phoneNumber }));
    }
    onCancelEdit();
  };

  return (
    <div>
      <form onSubmit={handleUpdateUserPhone}>
        <FormInput
          label="Phone Number"
          onChange={formChangeHandler}
          id="phoneNumber"
          name="phoneNumber"
          type="text"
          value={phoneNumber}
          required
        />

        <ButtonContainer>
          <Button type="submit">Update</Button>
          <Button type="button" buttonType="inverted" onClick={onCancelEdit}>
            Cancel
          </Button>
        </ButtonContainer>
      </form>
    </div>
  );
};

export default UpdatePhoneForm;
