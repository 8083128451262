import styled, { css } from "styled-components";

import { colors } from "../../../style-variables";

const shrinkLabelStyles = css`
  top: -1rem;
  font-size: 0.9rem;
  color: ${colors.dark};
`;

export const Group = styled.div`
  position: relative;
  margin: 1rem 0;

  input[type="password"] {
    letter-spacing: 0.3rem;
  }
`;

type FormInputLabelProps = {
  $shrink?: boolean;
};

export const FormInputLabel = styled.label<FormInputLabelProps>`
  color: ${colors.light};
  font-size: 1.2rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0.2rem;
  top: 0.6rem;
  transition: 300ms ease all;
  ${({ $shrink }) => $shrink && shrinkLabelStyles}
`;

export const Input = styled.input`
  background: none;
  /* background-color: white; */
  color: ${colors.light};
  font-size: 1.2rem;
  padding: 0.6rem;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${colors.light};
  margin: 2rem 0;

  &:focus {
    outline: none;
  }

  &:focus + ${FormInputLabel} {
    ${shrinkLabelStyles};
  }
`;
