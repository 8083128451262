import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../redux/store";
import {
  ButtonContainer,
  GreenHeader,
  RedHeader,
} from "../../../global.styles";
import Button from "../../UI/button/button.component";
import Card from "../../UI/card/card.component";
import Checkbox from "../../UI/checkbox/checkbox.component";
import RadioButton from "../../UI/radio-buttons/radio-button";
import Spinner from "../../UI/spinner/spinner.component";
import {
  compareArraysOfStrings,
  defineVehicleType,
} from "../../../util-functions";
import { VehicleType } from "../../../redux/vehicles/vehicles.types";
import { updateDrivingPreferencesStart } from "../../../redux/approvedDRiver/approved-driver.slice";

const ChangePreferencesFrom = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUser } = useSelector((state: RootState) => state.user);
  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  //local error state
  const [changeError, setChangeError] = useState("");

  //form states
  const initialVehiclesState = approvedDriver?.selectedVehicleTypes
    ? [...approvedDriver?.selectedVehicleTypes]
    : [];
  const initialPetState = approvedDriver?.petFriendly
    ? approvedDriver?.petFriendly
    : false;
  const [selectedVehicleTypes, setSelectedVehicleTypes] =
    useState<VehicleType[]>(initialVehiclesState);
  const [petFriendly, setPetFriendly] = useState(initialPetState);

  if (
    !currentUser ||
    (currentUser && !currentUser.readyToDrive) ||
    !approvedDriver
  ) {
    navigate("/drivers/application");
  }

  const selecteVehicleTypeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedVehicleTypes([
        ...selectedVehicleTypes,
        event.target.value as VehicleType,
      ]);
    } else {
      const index = selectedVehicleTypes.indexOf(
        event.target.value as VehicleType
      );
      selectedVehicleTypes.splice(index, 1);
      setSelectedVehicleTypes(selectedVehicleTypes);
    }
  };

  const petFriendlinessHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "yes") {
      setPetFriendly(true);
    } else if (event.target.value === "no") {
      setPetFriendly(false);
    }
  };

  const submitChangeHandler = async () => {
    //check that selectedVehicleTypes are not empty
    if (selectedVehicleTypes.length === 0) {
      setChangeError("Please select at least one vehicle category!");
      return;
    }
    //submit only if there were any changes
    if (
      compareArraysOfStrings(
        approvedDriver!.selectedVehicleTypes,
        selectedVehicleTypes
      ) &&
      approvedDriver!.petFriendly === petFriendly
    ) {
      navigate("/drivers/settings");
    } else {
      //send request
      dispatch(
        updateDrivingPreferencesStart({
          userId: currentUser!.id,
          selectedVehicleTypes,
          petFriendly,
        })
      );
    }
    //navigate with success
    if (!approvedDriverLoading && !approvedDriverError) {
      navigate("/drivers/settings");
    }
  };

  return (
    <Card>
      {approvedDriverLoading && <Spinner />}
      {approvedDriver && approvedDriver !== null && (
        <>
          <GreenHeader>Change Driving Prefercnces</GreenHeader>
          <br />
          <p>
            <strong>
              Please select types of jobs You would like to accept:
            </strong>
          </p>
          {approvedDriver.availableVehicleTypes.map((availableType) => (
            <Checkbox
              key={availableType}
              label={defineVehicleType(availableType)}
              id={availableType}
              name={availableType}
              value={availableType}
              defaultChecked={selectedVehicleTypes.includes(availableType)}
              onChange={selecteVehicleTypeHandler}
            />
          ))}
          <br />
          <p>
            <strong>
              Would You accept pets for extra £8 in Your vehicle?:
            </strong>
          </p>
          <RadioButton
            label="Yes! Pets are lovely. So is extra money!"
            id="yesPets"
            name="petFriendly"
            value="yes"
            defaultChecked={approvedDriver.petFriendly}
            onChange={petFriendlinessHandler}
          />
          <RadioButton
            label="No-no! No pets in my precious car!"
            id="noPets"
            name="petFriendly"
            value="no"
            defaultChecked={!approvedDriver.petFriendly}
            onChange={petFriendlinessHandler}
          />
          {approvedDriverError && <RedHeader>{approvedDriverError}</RedHeader>}
          {changeError && <RedHeader>{changeError}</RedHeader>}
          <ButtonContainer>
            <Button
              buttonType="inverted"
              onClick={() => navigate("/drivers/settings")}
            >
              Cancel
            </Button>
            <Button onClick={submitChangeHandler}>Save Changes</Button>
          </ButtonContainer>
        </>
      )}
    </Card>
  );
};

export default ChangePreferencesFrom;
