import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { RootState } from "../../../redux/store";
import Spinner from "../../UI/spinner/spinner.component";
import Card from "../../UI/card/card.component";
import ErrorMessage from "../../UI/error-message/error-message.component";

type ProtectedRouteProps = {
  children: React.ReactElement;
};

const LoggedInRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const { userLoading, userError, currentUser } = useSelector(
    (state: RootState) => state.user
  );

  if (userLoading) {
    return <Spinner />;
  }

  if (!userLoading && !currentUser) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" replace />;
  }

  if (!userLoading && userError) {
    return (
      <Card>
        <ErrorMessage slice="user">{userError}</ErrorMessage>
      </Card>
    );
  }
  
  // authorized so return child components
  return children;
};

export default LoggedInRoute;
