import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Card from "../../UI/card/card.component";
import { RootState } from "../../../redux/store";
import Spinner from "../../UI/spinner/spinner.component";
import Button from "../../UI/button/button.component";
import {
  AmberP,
  BackButtonContainer,
  GreenHeader,
  GreenP,
  RedHeader,
  TextDivider,
} from "../../../global.styles";
import SelectedVehicleType from "./selected-vehicle-type";
import PetFriendliness from "./pet-friendliness";
import { pauseOrResumeNewJobsStart } from "../../../redux/approvedDRiver/approved-driver.slice";
import Modal from "../../UI/modal/modal.component";

const DriverSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //states for 2 modals - pause and resume requests
  const [pauseResumeModalOpen, setPauseResumeModalOpen] = useState(false);

  const { userLoading, userError, currentUser } = useSelector(
    (state: RootState) => state.user
  );
  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  //handling pause or resume requests
  const pauseResumeHandler = () => {
    setPauseResumeModalOpen(true);
  };
  const pauseResumeConfirm = () => {
    if (approvedDriver) {
      dispatch(
        pauseOrResumeNewJobsStart({
          userId: approvedDriver.id,
          pausedRequests: !approvedDriver.pausedRequests,
        })
      );
    }
  };

  if (userLoading || approvedDriverLoading) return <Spinner />;

  return (
    <>
      {pauseResumeModalOpen && (
        <Modal
          title={
            approvedDriver?.pausedRequests
              ? "Resume New Requests"
              : "Stop New Requests"
          }
          body={
            approvedDriver?.pausedRequests
              ? "Do You want new job requests to be ENABLED?"
              : "Do You want to STOP new job requests?"
          }
          confirmButtonText="Yes"
          setOpenModal={setPauseResumeModalOpen}
          onModalConfirm={pauseResumeConfirm}
        />
      )}
      <Card>
        <BackButtonContainer>
          <Link to="/drivers/driver-panel">
            <h3>&larr; Driver Menu</h3>
          </Link>
        </BackButtonContainer>
        {userError && <RedHeader>{userError}</RedHeader>}
        {approvedDriverError && <RedHeader>{approvedDriverError}</RedHeader>}
        {currentUser && !currentUser.readyToDrive && (
          <>
            <GreenHeader>Driver Settings</GreenHeader>
            <AmberP>
              Driver Settings are available only for Drivers with the Active
              Status!
            </AmberP>
            <BackButtonContainer>
              <Button onClick={() => navigate("/drivers/application")}>
                Check Driver Profile
              </Button>
            </BackButtonContainer>
          </>
        )}
        {currentUser && currentUser.readyToDrive && approvedDriver && (
          <>
            <GreenHeader>Driver Settings</GreenHeader>
            <TextDivider>
              <GreenP>JOB PREFERENCES</GreenP>
            </TextDivider>
            <p>
              <strong>
                Currently, you are accepting jobs that are booked as:
              </strong>
            </p>
            {approvedDriver.selectedVehicleTypes.map((vehicleType) => (
              <SelectedVehicleType
                key={vehicleType}
                vehicleType={vehicleType}
              />
            ))}
            <br />
            <p>
              <strong>Pets:</strong>
            </p>
            <PetFriendliness petFriendly={approvedDriver.petFriendly} />

            <BackButtonContainer>
              <Button
                buttonType="green"
                onClick={() => navigate("/drivers/change-preferences-form")}
              >
                Change Preferences
              </Button>
            </BackButtonContainer>
            <TextDivider>
              <GreenP>STOP / RESUME</GreenP>
            </TextDivider>
            {approvedDriver.pausedRequests ? (
              <AmberP>
                <strong>New job requests are PAUSED...</strong>
              </AmberP>
            ) : (
              <GreenP>
                <strong>You ARE accepting new jobs...</strong>
              </GreenP>
            )}
            <BackButtonContainer>
              <Button
                buttonType={approvedDriver.pausedRequests ? "primary" : "green"}
                onClick={pauseResumeHandler}
              >
                {approvedDriver.pausedRequests
                  ? "Resume New Requests"
                  : "Stop New Requests"}
              </Button>
            </BackButtonContainer>
          </>
        )}
      </Card>
    </>
  );
};

export default DriverSettings;
