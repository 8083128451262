import { Navigate, Route, Routes } from "react-router-dom";

import BookingsList from "../../components/bookings/bookings-list.component";
import QuoteForm from "../../components/bookings/quote-form.component";
import BookingDetails from "../../components/bookings/booking-details.component";
import BookingForm from "../../components/bookings/booking-form";

const Bookings = () => {
  return (
    <Routes>
      <Route index element={<BookingsList />} />
      <Route path="quote-form" element={<QuoteForm />} />
      <Route path="booking-form" element={<BookingForm />} />
      <Route path="booking-details/:bookingId" element={<BookingDetails />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default Bookings;
